import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    EditButton,
    ShowButton,
    TextField,
    DeleteButton,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space, Typography, Alert, Divider } from "antd";

import { IRepository } from "interfaces";
import { useAuth0 } from '@auth0/auth0-react';
const { Title, Text } = Typography;


export const RepositoryMgmtList: React.FC<IResourceComponentsProps> = () => {

    const {
        user,
      } = useAuth0();
      
      const { tableProps, tableQueryResult } = useTable<IRepository>({
        resource: "repositories"
    });

    return (

        <List >
            <Title level={5}>Id</Title>
            <Text></Text>    
            {
                tableQueryResult?.data?.summary.summary.time_needed_in_seconds ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                    message={"Time taken to load the results: " + tableQueryResult?.data?.summary.summary.time_needed_in_seconds + " seconds"}
                    type="success"

                    />
                </Space> :
                ""
            }      
            <Divider />  
            <Table {...tableProps} rowKey="name"
            pagination={{
                defaultPageSize:500,
                showSizeChanger:true,
            }}
            >
            <Table.Column<IRepository>
                    title="Repository category" 
                    dataIndex="id" 
                    render={(_: any, record: { category: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.category
                                }` }
                            />
                        );
                    }}
                />
            <Table.Column<IRepository>
                    title="Path" 
                    dataIndex="path" 
                    render={(_: any, record: { repository_folder_path: any; file_name: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.repository_folder_path + "/" + record?.file_name
                                }` }
                            />
                        );
                    }}
                />
            <Table.Column<IRepository>
                    title="Name" 
                    dataIndex="name" 
                    render={(_: any, record: { name: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.name
                                }` }
                            />
                        );
                    }}
                />
            <Table.Column<IRepository>
                    title="Deployed in CodeCommit" 
                    dataIndex="id" 
                    render={(_: any, record: { deployed_in_code_commit: any; }) => {
                        return (
                            <TagField
                                color={record?.deployed_in_code_commit == true ? "green" : "red"}
                                value={`${
                                    record?.deployed_in_code_commit
                                }` 
                            }
                            />
                        );
                    }}
                />
            </Table>
        </List>
    );
};

