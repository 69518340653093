import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    EditButton,
    ShowButton,
    TextField,
    DeleteButton,
    CreateButton,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space, Typography, Tooltip, Divider, Button } from "antd";

import { IWorkItem } from "interfaces";
import { useAuth0 } from '@auth0/auth0-react';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
const { Title, Text } = Typography;

export const WorkItemsList: React.FC<IResourceComponentsProps> = () => {
    const CheckboxGroup = Checkbox.Group;
    const [searchAddress, setSearchAddress] = useState<string>("0x1f9090aaE28b8a3dCeaDf281B0F12828e676c326");
    const [finalSearchAddress, setFinalSearchAddress] = useState<string>("0x1f9090aaE28b8a3dCeaDf281B0F12828e676c326");
    const [isInvalidSearchAddress, setIsInvalidSearchAddress] = useState<boolean>(false);

    const all_possible_regions = [
        {
            "label": "us-west-2",
            "value": "us-west-2"
        },
        {
            "label": "us-east-1",
            "value": "us-east-1"
        },
        {
            "label": "eu-central-1",
            "value": "eu-central-1"
        },
        {
            "label": "eu-west-1",
            "value": "eu-west-1"
        },
    ]

    const plainOptions = all_possible_regions
    const defaultCheckedList = ["us-west-2"] // all_possible_regions.map(a => a.value);

    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList);
    const [searchCheckedList, setSearchCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList);

    const checkAll = plainOptions.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;
    
    const onChange = (list: CheckboxValueType[]) => {
        setCheckedList(list);
    };
    
    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        setCheckedList(e.target.checked ? plainOptions.map(a => a.value) : []);
    };
    
    const {
        user,
        } = useAuth0();
        
        const { tableProps, tableQueryResult } = useTable<IWorkItem>({
        resource: "work-items",
        meta: { 
            "regions": searchCheckedList,
        },
    });

    const refreshTableResults = () => {     
        setSearchCheckedList(checkedList)   
        tableQueryResult.refetch()
    };

    return (

        <List headerButtons={({ createButtonProps }) => (
            <>
              {createButtonProps && <CreateButton {...createButtonProps} meta={{ foo: "bar" }} />}
            </>
          )} >
            <Title level={5}>Search regions for work items:</Title>
            <Divider />
            <Space.Compact size="large">
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                Check all
            </Checkbox>
                <Text>
                {
                    <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
                }    
                </Text> 
            </Space.Compact>
            <Divider />
            <Space.Compact style={{ width: '100%' }}>
            <Button type="primary" onClick={refreshTableResults} disabled={tableQueryResult?.isFetching }>Submit</Button>
            </Space.Compact>
            <Divider />
            <Table {...tableProps} rowKey="arn" dataSource={tableQueryResult?.data?.data }
                        pagination={{
                            defaultPageSize:500,
                            showSizeChanger:true,
                        }}
            >
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.lock_value - b.lock_value}
                    title="Id" 
                    dataIndex="lock_value" 
                    render={(_: any, record: { lock_value: any; }) => {
                        return (
                            <Tooltip title={record?.lock_value}>
                            <TagField
                                value={`${
                                    record?.lock_value.substring(0,2)
                                }` }
                            />
                            </Tooltip>
                        );
                    }}
                />
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.table - b.table}
                    filters={tableQueryResult?.data?.data.map((
                        { table }) => ({ "text": table, "value": table})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { table: string; }) => record.table.startsWith(value)}
                    title="Table" 
                    dataIndex="table" 
                    render={(_: any, record: { table: any; }) => {
                        let colors = {
                            "pre-processed": "lightblue",
                            "processed": "blue",
                            "post-processed": "darkblue",
                        }
                        return (
                            <TagField
                                color={colors[record?.table.split('process-transactions-mgmt-').pop().split('-transactions-')[0] as keyof typeof colors ]}
                                value={`${
                                    record?.table.split('process-transactions-mgmt-').pop().split('-transactions-')[0]
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.chain - b.chain}
                    filters={tableQueryResult?.data?.data.map((
                        { chain }) => ({ "text": chain, "value": chain})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { chain: string; }) => record.chain.startsWith(value)}
                    title="Chain" 
                    dataIndex="chain" 
                    render={(_: any, record: { chain: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.chain
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.address - b.address}
                    filters={tableQueryResult?.data?.data.map((
                        { address }) => ({ "text": address, "value": address})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { address: string; }) => record.address.startsWith(value)}
                    title="Address" 
                    dataIndex="address" 
                    render={(_: any, record: { address: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.address
                                }` }
                            />
                        );
                    }}
                />    
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.hash - b.hash}
                    filters={tableQueryResult?.data?.data.map((
                        { hash }) => ({ "text": hash, "value": hash})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { hash: string; }) => record.hash.startsWith(value)}
                    title="Hash" 
                    dataIndex="hash" 
                    render={(_: any, record: { hash: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.hash
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.region - b.region}
                    filters={tableQueryResult?.data?.data.map((
                        { region }) => ({ "text": region, "value": region})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { region: string; }) => record.region.startsWith(value)}
                    title="Region" 
                    dataIndex="region" 
                    render={(_: any, record: { region: string; }) => {
                        let colors = {
                            "us-west-2": "green",
                            "us-east-1": "lightgreen",
                            "eu-central-1": "blue",
                            "eu-west-1": "lightblue"
                        }
                        return (
                            <TagField
                                color={colors[record.region as keyof typeof colors ]}
                                value={`${
                                    record?.region
                                }` }
                            />
                        );
                    }}
                />      
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.create_in_lock_table_timestamp - b.create_in_lock_table_timestamp}
                    filters={tableQueryResult?.data?.data.map((
                        { create_in_lock_table_timestamp }) => ({ "text": create_in_lock_table_timestamp, "value": create_in_lock_table_timestamp})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: {create_in_lock_table_timestamp: number; }) =>  String(record.create_in_lock_table_timestamp).startsWith(value)}
                    title="Created in lock table" 
                    dataIndex="create_in_lock_table_timestamp" 
                    render={(_: any, record: { create_in_lock_table_timestamp: any; }) => {
                        return (
                            <DateField value={dayjs.unix(Number(record?.create_in_lock_table_timestamp/1000) || 1)} format="LLL"></DateField>
                        );
                    }}
                /> 
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.status - b.status}
                    filters={tableQueryResult?.data?.data.map((
                        { status }) => ({ "text": status, "value": status})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { status: string; }) => record.status.startsWith(value)}
                    title="Status" 
                    dataIndex="status" 
                    render={(_: any, record: { status: any; }) => {
                        let colors = {
                            "to_be_deleted": "red",
                            "ready_to_be_processed": "blue",
                            "in_progress": "green",
                        }

                        return (
                            <TagField
                                color={colors[record.status as keyof typeof colors ]}
                                value={`${
                                    record?.status
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.last_updated_action_reason - b.last_updated_action_reason}
                    filters={tableQueryResult?.data?.data.map((
                        { last_updated_action_reason }) => ({ "text": last_updated_action_reason, "value": last_updated_action_reason})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { status: string; }) => record.status.startsWith(value)}
                    title="Last updated reason" 
                    dataIndex="last_updated_action_reason" 
                    render={(_: any, record: { last_updated_action_reason: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.last_updated_action_reason
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.retries - b.retries}
                    filters={tableQueryResult?.data?.data.map((
                        { retries }) => ({ "text": retries, "value": retries})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { retries: string; }) => record.retries.startsWith(value)}
                    title="Retries" 
                    dataIndex="retries" 
                    render={(_: any, record: { retries: any; }) => {
                        return (
                            <TagField
                                color={record?.retries > 3 ? "red" : record?.retries > 0 ? "yellow" : ""}
                                value={`${
                                    record?.retries
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.message_id - b.message_id}
                    filters={tableQueryResult?.data?.data.map((
                        { message_id }) => ({ "text": message_id, "value": message_id})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { message_id: string; }) => record.message_id.startsWith(value)}
                    title="Message id" 
                    dataIndex="message_id" 
                    render={(_: any, record: { message_id: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.message_id
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<IWorkItem>
                    title="Actions"
                    dataIndex="arn"
                    render={(_: any, record: {
                        table: string; lock_value: string; 
}) => (
                        <Space>
                            <ShowButton
                                size="small"
                                recordItemId= {record.table + "_" + record.lock_value }
                            />
                            <EditButton
                                size="small"
                                recordItemId= {record.table + "_" + record.lock_value}
                            />
                        </Space>
                    )}
                />        
            </Table>
        </List>
    );
};

