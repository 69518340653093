import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";

import { Create, useForm } from "@refinedev/antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { Alert, Button, Checkbox, Divider, Form, Input, Select, Space, Tooltip } from "antd";

import { ITrigger } from "interfaces";
import Title from "antd/es/typography/Title";
import { isAddress } from 'viem'

export const TriggerCreate: React.FC<IResourceComponentsProps> = () => {
        
    const { formLoading ,formProps, saveButtonProps, onFinish, mutationResult } = useForm<ITrigger>(
        {
            resource: "triggers",
            action: "create",
            redirect: "show",
        }
    );

    const { Option } = Select;


    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      };
      
      const formItemLayoutWithOutLabel = {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 },
        },
      };

    
    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps} resource="triggers">
            <Form {...formProps} layout="vertical" >
                <Form.Item
                    label="Environment"
                    name="environment"  
                    initialValue={process.env.REACT_APP_ENV == "dev" ? "sandbox" : process.env.REACT_APP_ENV}                 
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Continent"
                    name="region"  
                    initialValue={"us-west-2"}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select a continent">
                        <Option value="us-west-2">US (us-west-2/us-east-1)</Option>
                        <Option value="eu-central-1">EU (eu-central-1/eu-west-1)</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Trigger Status"
                    name="status"  
                    initialValue={"active"}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select the trigger status">
                        <Option value="active">Active</Option>
                        <Option value="inactive">Inactive</Option>
                    </Select>
                </Form.Item>                
                <Form.Item
                    label="Type"
                    name="trigger_type"  
                    initialValue={"default"}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select a trigger type">
                        <Option value="default">Default</Option>
                        <Option value="custom">Custom</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Subtype"
                    name="trigger_sub_type"  
                    initialValue={"integration_trigger"}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select a trigger subtype">
                        <Option value="integration_trigger">Integration</Option>
                        <Option value="action_trigger">Action</Option>
                        <Option value="trigger">Trigger</Option>
                    </Select>
                </Form.Item>                
                <Form.Item
                    label="Stages"
                    name="trigger_stages"  
                    initialValue={"pre-process"}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Select 
                        mode="multiple"
                        placeholder="Please select a stages">
                        <Option value="pre-process">pre-process</Option>
                        <Option value="process">process</Option>
                        <Option value="post-process">post-process</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Name"
                    name="name"                    
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                    return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input placeholder="test name"/>
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="Description here"/>
                </Form.Item>
                <Form.Item
                    label="Lambda arn"
                    name="lambda_arn"                    
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input placeholder="Trigger Lambda arn here"/>
                </Form.Item>                
                <Form.List
                    name="trigger_on_chain_id_addresses"  
                    initialValue={[""]}
                    rules={[
                    {
                        validator: async (_, names) => {
                            // if (names.includes("-")) {
                            //     return Promise.reject(new Error('Can not contain "-"'));
                            // }
                        },
                    },
                    ]}
                >
                    {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                        <Form.Item
                            {...(index === 0 ? formItemLayout : formItemLayout)}
                            label={index === 0 ? 'Trigger on chain_id address' : ''}
                            required={true}
                            key={field.key}
                        >
                            <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                                {
                                required: true,
                                whitespace: true,
                                message: "Please Trigger on chain_id address.",
                                },
                                {
                                    validator: async (_, names) => {
                                        if (names.includes("-")) {
                                            return Promise.reject(new Error('Can not contain "-"'));
                                        }
                                        if (!names.includes("_")) {
                                            return Promise.reject(new Error('Should contain "_"'));
                                        }
                                        if (!isAddress(names.split("_")[1])) {
                                            return Promise.reject(new Error('Not a valid address ' + names.split("_")[1]));
                                        }
                                    },
                                },
                            ]}
                            noStyle
                            >
                            <Input placeholder="0x1_0x78Cf1D91C94667a4a6615829e394C9CCe58fEc9E" style={{ width: '90%' }} />
                            </Form.Item>
                            {fields.length > 1 ? (
                            <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                            />
                            ) : null}
                        </Form.Item>
                        ))}
                        <Form.Item>
                        <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{ width: '60%' }}
                            icon={<PlusOutlined />}
                        >
                            Add field trigger_on_chain_id_addresses
                        </Button>
                        <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                    )}
                </Form.List>
            </Form>

        </Create>
    );
};
