import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";

import { Edit, useForm } from "@refinedev/antd";

import { Checkbox, Form, Input, Select } from "antd";

import { IRole } from "interfaces";
export const ExternalRoleEdit: React.FC<IResourceComponentsProps> = () => {
        
    const { formProps, saveButtonProps, queryResult } = useForm<IRole>();

    const postData = queryResult?.data?.data;

    const { Option } = Select;


    return (
        <Edit saveButtonProps={{ ...saveButtonProps }}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Id"
                    name="id"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Type"
                    name="type"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Name"
                    name="name"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Status"
                    name="status"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select a status">
                        <Option value="Enabled">Enabled</Option>
                        <Option value="Disabled">Disabled</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Scopes"
                    name="scopes"   
                    initialValue={postData?.scopes}                      
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                   {
                        <Checkbox.Group options={postData?.all_possible_permissions.map(function(elm: { [name: string]: any; }) {
                            return { "label": elm.name, "value": elm.name, "description": elm.description};
                         })} defaultValue={postData?.scopes}></Checkbox.Group>
                    }
                </Form.Item>                
            </Form>
        </Edit>
    );
};
