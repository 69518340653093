import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    EditButton,
    ShowButton,
    TextField,
    DeleteButton,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space } from "antd";

import { IUser } from "interfaces";


export const InternalUsersList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, sorter } = useTable<IUser>({
        resource: "users"
    });

    const datetime = dayjs.unix(1653134400)


    return (
        <List>
            <Table {...tableProps} rowKey="id">
                <Table.Column<IUser>
                    title="Id" 
                    dataIndex="id" 
                    render={(_: any, record: { id: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.id
                                }` }
                            />
                        );
                    }}
                />
                <Table.Column<IUser>
                    title="Name" 
                    dataIndex="name" 
                    render={(_: any, record: { name: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.name
                                }` }
                            />
                        );
                    }}
                />    
                <Table.Column<IUser>
                    title="Email" 
                    dataIndex="email" 
                    render={(_: any, record: { email: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.email
                                }` }
                            />
                        );
                    }}
                />   
                <Table.Column<IUser>
                    title="Status" 
                    dataIndex="status" 
                    render={(_: any, record: { status: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.status
                                }` }
                            />
                        );
                    }}
                />     
                <Table.Column<IUser>
                    title="Roles" 
                    dataIndex="roles" 
                    render={(_: any, record: { roles: Array<string>; }) => {
                        return (
                            record.roles.map((role: any) => <TagField id={role} key={role} value={role}  style={{ marginTop: 5, marginBottom: 5 }}></TagField>)
                        );
                    }}
                />      
                <Table.Column<IUser>
                    title="Created At" 
                    dataIndex="created_at" 
                    render={(_: any, record: { created_at: string; }) => {
                        return (
                            <DateField value={dayjs.unix(Number(record?.created_at) || 1)} format="LLL"></DateField>
                        );
                    }}
                />    
                <Table.Column<IUser>
                    title="Actions"
                    dataIndex="id"
                    render={(_: any, record: { id: any; }) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId= {record.id}
                            />
                            <DeleteButton
                                size="small"
                                hideText
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />        
            </Table>
        </List>
    );
};

