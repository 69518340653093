import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useShow, IResourceComponentsProps, useOne } from "@refinedev/core";

import { TagField, Show, EditButton, ShowButton, DateField } from "@refinedev/antd";

import { Button, Checkbox, Form, Space, Table, Tag, Typography } from "antd";

import { IUser } from "interfaces";

const { Title, Text } = Typography;
import dayjs from "dayjs";


export const ExternalUserShow: React.FC<IResourceComponentsProps> = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const { queryResult } = useShow<IUser>();
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const { data: categoryData, isLoading: categoryIsLoading } =
        useOne<IUser>({
            resource: "users",
            id: record?.id || "",
            queryOptions: {
                enabled: !!record,
            },
            meta: {"id": record?.id}
        });



    return (
        <Show isLoading={isLoading}>
            <Title level={5}>Id</Title>
            <Text>{record?.id}</Text>
            <Title level={5}>Org id</Title>
            <Text>{record?.org_id}</Text>
            <Title level={5}>Tenant Id</Title>
            <Text>{record?.tenant_id}</Text>
            <Title level={5}>Name</Title>
            <Text>{record?.name}</Text>
            <Title level={5}>Email</Title>
            <Text>{record?.email}</Text>
            <Title level={5}>Status</Title>
            <Text>{record?.status}</Text>
            <Title level={5}>Created At</Title>
            <Text>
                <DateField value={dayjs.unix(Number(record?.created_at) || 1)} format="LLL"></DateField>
            </Text>
            
            <Title level={5}>Roles</Title>
            <Text>
                {record?.roles.map((role: any) => <TagField id={role} key={role} value={role}  style={{ marginTop: 5, marginBottom: 5 }}></TagField>)}
            </Text>
        </Show>
    );
};
