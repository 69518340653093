import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps } from "@refinedev/core";
import { Alert, Divider, List, Space, Tag, Typography } from 'antd';
import { Show } from '@refinedev/antd';
import { useAuth0 } from '@auth0/auth0-react';

const { Title, Text } = Typography;


export const SettingsShow: React.FC<IResourceComponentsProps> = () => {

    const {
        user,
      } = useAuth0();

    return (
        <Show isLoading={false}>
            <Title level={3}>User settings</Title>
            <Title level={4}>Blue/Green settings</Title>
            <Title level={5}>Environment</Title>
            <Tag color={user?.[process.env.REACT_APP_BASE_URL + '/qa_config'].environment == "blue" ? "blue" : "green"} >{user?.[process.env.REACT_APP_BASE_URL + '/qa_config'].environment}</Tag>
            <Title level={5}>Environment config (if any)</Title>
            <pre>{JSON.stringify(user?.[process.env.REACT_APP_BASE_URL + '/qa_config'].config)}</pre>
            <Divider></Divider>
            <Title level={3}>All settings</Title>
            <pre>{JSON.stringify(user, null, 2)}</pre>
        </Show>
    );
};

