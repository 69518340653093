import React from "react";
import { IResourceComponentsProps, useNotification } from "@refinedev/core";

import { Edit, ListButton, RefreshButton, SaveButton, TagField, useForm } from "@refinedev/antd";

import { Alert, Button, Checkbox, Divider, Form, Input, Select, Space, Spin, Tooltip, Typography } from "antd";
import axios from 'axios';
const { Title, Text } = Typography;

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { isAddress } from 'viem'

import { ITrigger } from "interfaces";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
export const TriggerEdit: React.FC<IResourceComponentsProps> = () => {
      
    const navigate = useNavigate();
    const params= useParams()
    
    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      };
      
      const formItemLayoutWithOutLabel = {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 },
        },
      };
    const { formLoading ,formProps, saveButtonProps, onFinish, mutationResult, queryResult } = useForm<ITrigger>(
        {
            resource: "triggers",
            action: "edit",
            id: params.id,
            redirect: "show"
        }
    );
    const record = queryResult?.data?.data;

    const { open } = useNotification();

    const { Option } = Select;

    const refreshTableResults = () => {     
        queryResult?.refetch()
    };

    let colors = {
        "us-west-2": "green",
        "us-east-1": "lightgreen",
        "eu-central-1": "blue",
        "eu-west-1": "lightblue"
    }

    return (
        <Edit isLoading={formLoading} saveButtonProps={saveButtonProps} resource="triggers" headerButtons={({ listButtonProps, refreshButtonProps }) => (
            <>
              {listButtonProps && <ListButton {...listButtonProps} meta={{ foo: "bar" }} />}
              <RefreshButton {...refreshButtonProps} disabled={queryResult?.isLoading || queryResult?.isFetching } loading={queryResult?.isLoading || queryResult?.isFetching } onClick={refreshTableResults} />
            </>
          )} 

            
    //   wrapperProps={{
    //     style: {
    //       backgroundColor: "orange",
    //       padding: "16px",
    //     },
    //   }}
    // footerButtonProps={{
    //     style: {
    //         float: "left",
    //         marginLeft: "24px"
    //     },
    // }}
    //   footerButtons={({ saveButtonProps }) => (
        
    //     <>
    //       <SaveButton {...saveButtonProps} onClick={(e:any) => handleOnClickSaveButton("new_version")} loading={isLoadingNewVersion} >Save</SaveButton>
    //     </>
        
    //   )}
      >
            {queryResult?.isLoading || queryResult?.isFetching  ? <Spin size="large" /> : null}
            <Form {...formProps} layout="vertical">
                <Title level={5}>Trigger Environment</Title>
                <TagField value={record?.environment}></TagField>
                <Title level={5}>Trigger Region</Title>
                <TagField color={colors[record?.region as keyof typeof colors ]} value={record?.region}></TagField>
                <Title level={5}>Trigger Org Id</Title>
                <Text>{record?.org_id}</Text>
                <Title level={5}>Trigger Tenant Id</Title>
                <Text>{record?.tenant_id}</Text>
                <Title level={5}>Trigger User Id</Title>
                <Text>{record?.created_by}</Text>
                <Title level={5}>Trigger Id</Title>
                <Text>{record?.id}</Text>
                <Title level={5}>Trigger type</Title>
                <Text>{record?.trigger_type}</Text>
                <Title level={5}>Trigger subtype</Title>
                <Text>{record?.trigger_subtype}</Text>                     
                <Title level={5}>Trigger Lambda arn</Title>
                <Text>{record?.lambda_arn}</Text>                
                <Divider></Divider>
                <Form.Item
                    label="Trigger Name"
                    name="name"  
                    initialValue={record?.name}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Input></Input>
                </Form.Item>                
                <Form.Item
                    label="Trigger Description"
                    name="description"  
                    initialValue={record?.description}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Input></Input>
                </Form.Item>                   
                <Form.Item
                    label="Trigger Status"
                    name="status"  
                    initialValue={record?.status}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select the trigger status">
                        <Option value="active">Active</Option>
                        <Option value="inactive">Inactive</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Trigger Stages"
                    name="trigger_stages"  
                    initialValue={record?.trigger_stages}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Select 
                        mode="multiple"
                        placeholder="Please select the trigger stage">
                        <Option value="pre-process">pre-process</Option>
                        <Option value="process">process</Option>
                        <Option value="post-process">post-process</Option>
                    </Select>
                </Form.Item>
                <Form.List
                    name="trigger_on_chain_id_addresses"  
                    initialValue={record?.trigger_on_chain_id_addresses} 
                    rules={[
                    {
                        validator: async (_, names) => {
                            // if (names.includes("-")) {
                            //     return Promise.reject(new Error('Can not contain "-"'));
                            // }
                        },
                    },
                    ]}
                >
                    {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                        <Form.Item
                            {...(index === 0 ? formItemLayout : formItemLayout)}
                            label={index === 0 ? 'Trigger on chain_id address' : ''}
                            required={true}
                            key={field.key}
                        >
                            <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                                {
                                required: true,
                                whitespace: true,
                                message: "Please Trigger on chain_id address.",
                                },
                                {
                                    validator: async (_, names) => {
                                        if (names.includes("-")) {
                                            return Promise.reject(new Error('Can not contain "-"'));
                                        }
                                        if (!names.includes("_")) {
                                            return Promise.reject(new Error('Should contain "_"'));
                                        }
                                        if (!isAddress(names.split("_")[1])) {
                                            return Promise.reject(new Error('Not a valid address ' + names.split("_")[1]));
                                        }
                                    },
                                },
                            ]}
                            noStyle
                            >
                            <Input placeholder="0x1_0x78Cf1D91C94667a4a6615829e394C9CCe58fEc9E" style={{ width: '90%' }} />
                            </Form.Item>
                            {fields.length > 1 ? (
                            <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                            />
                            ) : null}
                        </Form.Item>
                        ))}
                        <Form.Item>
                        <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{ width: '60%' }}
                            icon={<PlusOutlined />}
                        >
                            Add field trigger_on_chain_id_addresses
                        </Button>
                        <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                    )}
                </Form.List>
            </Form>            
        </Edit>
        
    );
};

