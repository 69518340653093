import { HttpError } from "@refinedev/core";
import axios, {AxiosRequestConfig} from "axios";

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    (config) => {
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const local_storage_prefix = "ADMIN_PORTAL-" + process.env.REACT_APP_ENV + "-" + token?.sub + "-" + token[process.env.REACT_APP_BASE_URL + "/org_id"] + "-" + token[process.env.REACT_APP_BASE_URL + "/tenant_id"]   
        let storage_use_region_alias = JSON.parse(localStorage.getItem(local_storage_prefix + '_use_region_alias') || '"default"')

        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region_config = token[process.env.REACT_APP_BASE_URL + "/region_config"]   
        const region = region_config?.region
        const region_alias = region_config?.region_alias
        const backup_region = region_config?.backup_region
        const backup_region_alias = region_config?.backup_region_alias
        const domain = process.env.REACT_APP_API_BASE_URL 

        let use_region
        let use_region_alias
        let use_backup_region
        let use_backup_region_alias
        
        if(storage_use_region_alias && storage_use_region_alias != "default" && storage_use_region_alias != undefined){
            if(storage_use_region_alias == region_alias){
                use_backup_region = backup_region
                use_backup_region_alias = backup_region_alias
                use_region = region
                use_region_alias = region_alias                
            } else{
                // Switch
                use_backup_region = region
                use_backup_region_alias = storage_use_region_alias
                use_region = backup_region
                use_region_alias = backup_region_alias
            }
        } else{
            // Switch region based on time/minutes. To semi-randomly switch to backup region
            const now = new Date().getUTCMinutes() % 5 // get remainder of 5 minutes interval and check if this is greater than something. First 3 minutes 1 region. Remainder 2 minutes other region
            if(now > 2){ // 3 and 4
                use_backup_region = region_alias 
                use_backup_region_alias = region_alias 
                use_region = backup_region
                use_region_alias = backup_region_alias
                console.log("Using back up region: " + use_region_alias) 
            }
            else{ // 0,1,2
                use_backup_region = backup_region
                use_backup_region_alias = backup_region_alias
                use_region = region
                use_region_alias = region_alias
                console.log("Using main region: " + use_region_alias) 
            }
        }

        const new_api_endpoint = "https://" + use_region_alias + "." + continent.toLowerCase()  + ".api." + process.env.REACT_APP_API_BASE_URL
        
        // console.log(config)
        config.headers.Authorization = `Bearer ${token.__raw}`
        config.headers.Accept = "application/json, text/plain, */*"
        config.headers["Source-Platform"] = "admin-portal"
        config.headers["Source-Region"] = use_region
        config.headers["Destination-Region"] = use_region

        // console.log(config)

        const old_config_url = config.url || ""
        console.log(old_config_url)
        // The get requests should go via the Cloudfront distribution in order to make use of the origin groups and maybe caching
        if (config.url?.includes("/management/permissions/")){
            // Change api url based on the users token metadata like continent
            console.log("Changing the API Endpoint to: " + new_api_endpoint)
            config.url = config.url?.replace('https://api.' + domain, new_api_endpoint)
            console.log("Changed the API Endpoint to: " + config.url)
        }
        console.log(config.url)

        const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
        const qa_environment = qa_config["environment"]
        const blue_config = qa_config["config"]
        if (qa_environment == "blue"){
            console.log("User is a blue user so changing the config url to the blue endpoint")
            if(typeof blue_config !== 'undefined' && blue_config.length > 0 ){
                console.log("There is a blue config. So only applying blue endpoint if in blue config")
                let changed_config = false
                blue_config.forEach((service: string) => {
                    if(config.url?.includes(service) && changed_config == false){
                        console.log("Changing because config is matching " + service)
                        config.url = config.url?.replace('/GET/', "/blue/GET/")
                        config.url = config.url?.replace('/ANY/', "/blue/ANY/")
                        changed_config = true;
                    }
                });
            } 
            else{
                config.url = config.url?.replace('/GET/', "/blue/GET/")
                config.url = config.url?.replace('/ANY/', "/blue/ANY/")
            }
            console.log("New blue config url = " + config.url)
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
  
axiosInstance.interceptors.response.use(
    (response) => {
        // Fix for migration to new api structure
        if (response.data.result){
            response.data = response.data.result
        }

        // Return the error
        if (response.data.ok == false){
            
            const customError: HttpError = {
                message: response?.data?.error?.message ? response?.data?.error?.message : response.data.error.reason,
                statusCode: response.data.statusCode,
            };
    
            return Promise.reject(customError);
        }
        
        return response;
    },
    (error) => {

        const customError: HttpError = {
            ...error,
            message: error.response?.data?.message ? error.response?.data?.message : error.response?.data?.error?.message,
            statusCode: error.response?.status,
        };

        return Promise.reject(customError);
    },
);
