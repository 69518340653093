import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps, useNotification } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    EditButton,
    ShowButton,
    TextField,
    DeleteButton,
    SaveButton,
    ListButton,
    RefreshButton,
} from "@refinedev/antd";

import dayjs from "dayjs";
import { Table, Space, Typography } from "antd";

import { IAuthenticationScope } from "interfaces";
import { useAuth0 } from '@auth0/auth0-react';
const { Title, Text } = Typography;
import axios from 'axios';


export const AuthenticationScopesList: React.FC<IResourceComponentsProps> = () => {
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    const local_storage_prefix = "ADMIN_PORTAL-" + process.env.REACT_APP_ENV + "-" + token?.sub + "-" + token[process.env.REACT_APP_BASE_URL + "/org_id"] + "-" + token[process.env.REACT_APP_BASE_URL + "/tenant_id"]   
    let storage_use_region_alias = JSON.parse(localStorage.getItem(local_storage_prefix + '_use_region_alias') || '"default"')

    const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
    const region_config = token[process.env.REACT_APP_BASE_URL + "/region_config"]   
    const region = region_config?.region
    const region_alias = region_config?.region_alias
    const backup_region = region_config?.backup_region
    const backup_region_alias = region_config?.backup_region_alias
    const domain = process.env.REACT_APP_API_BASE_URL 

    let use_region:string
    let use_region_alias:string
    let use_backup_region:string
    let use_backup_region_alias:string
    
    if(storage_use_region_alias && storage_use_region_alias != "default" && storage_use_region_alias != undefined){
        if(storage_use_region_alias == region_alias){
            use_backup_region = backup_region
            use_backup_region_alias = backup_region_alias
            use_region = region
            use_region_alias = region_alias                
        } else{
            // Switch
            use_backup_region = region
            use_backup_region_alias = storage_use_region_alias
            use_region = backup_region
            use_region_alias = backup_region_alias
        }
    } else{
        // Switch region based on time/minutes. To semi-randomly switch to backup region
        const now = new Date().getUTCMinutes() % 5 // get remainder of 5 minutes interval and check if this is greater than something. First 3 minutes 1 region. Remainder 2 minutes other region
        if(now > 2){ // 3 and 4
            use_backup_region = region_alias 
            use_backup_region_alias = region_alias 
            use_region = backup_region
            use_region_alias = backup_region_alias
            console.log("Using back up region: " + use_region_alias) 
        }
        else{ // 0,1,2
            use_backup_region = backup_region
            use_backup_region_alias = backup_region_alias
            use_region = region
            use_region_alias = region_alias
            console.log("Using main region: " + use_region_alias) 
        }
    }
    
    // console.log(config)
    const requestHeaders = {
        Authorization: `Bearer ${token.__raw}`,
        Accept: "application/json, text/plain, */*",
        "Source-Platform": "admin-portal",
        "Source-Region": use_region,
        "Destination-Region": use_region,
    };

    const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
    const qa_environment = qa_config["environment"]
    const blue_config = qa_config["config"]
    
    const {
        user,
      } = useAuth0();
      
    const { open } = useNotification();

    const { tableProps, searchFormProps, tableQueryResult } = useTable<IAuthenticationScope>({
        resource: "authentication-scopes"
    });

    let [isLoadingUpdatingAuthenticationScopes, setIsLoadingUpdatingAuthenticationScopes] = React.useState<boolean>(false)

    async function updateAuthenticationScopes(e:any) {
        console.log("Update authentication scopes clicked")
        setIsLoadingUpdatingAuthenticationScopes(true)
        let url
        const apiUrl = "https://" + use_region_alias + "." + continent.toLowerCase() + ".api." + domain 
        if (qa_environment == "blue"){
            url = `${apiUrl}/authentication/blue/ANY/${continent.toUpperCase()}/v0/authentication-scopes`
        } 
        else{
            url = `${apiUrl}/authentication/ANY/${continent.toUpperCase()}/v0/authentication-scopes`
        }
        console.log(url)        
        const obj  = {}
  
        try {
            const { data, status } = await axios.put(
                url,
                JSON.stringify(obj), {
                    headers: requestHeaders
                }
            )  
            console.log(data)
            console.log(status)
            let new_message = "success"
            let new_description = "Updated authentication scopes"
        
            open?.({
                type: "success",
                message: new_message,
                description: new_description,
                key: "authentication-scopes",
            });
        } catch (error: any) {
            console.log(error); 
            
            open?.({
                type: "error",
                message: error.response,
                description: error.response,
                key: "authentication-scopes",
            });
        }      

        setIsLoadingUpdatingAuthenticationScopes(false) 
    };

    return (

        <List headerButtons={
            <>
                <SaveButton onClick={(e) => updateAuthenticationScopes(e)} loading={isLoadingUpdatingAuthenticationScopes}>Update Authentication scopes with new values in region: {use_region}</SaveButton>
                <RefreshButton onClick={(e) => tableQueryResult.refetch()} loading={tableQueryResult?.isLoading || tableQueryResult?.isFetching }></RefreshButton>
            </>
          }>
            <Table {...tableProps} rowKey="api_gateway_id-documentation_part_id"
                pagination={{
                    defaultPageSize:500,
                    showSizeChanger:true,
                }}
            >
            <Table.Column<IAuthenticationScope>
                    title="Api gateway id" 
                    dataIndex="api_gateway_id" 
                    render={(_: any, record: { api_gateway_id: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.api_gateway_id
                                }` }
                            />
                        );
                    }}
                />
            <Table.Column<IAuthenticationScope>
                    title="Api gateway name" 
                    dataIndex="api_gateway_name" 
                    render={(_: any, record: { api_gateway_name: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.api_gateway_name
                                }` }
                            />
                        );
                    }}
                />            
            <Table.Column<IAuthenticationScope>
                    title="Api gateway path" 
                    dataIndex="documentation_part_path" 
                    render={(_: any, record: { documentation_part_path: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.documentation_part_path
                                }` }
                            />
                        );
                    }}
                />                     
            <Table.Column<IAuthenticationScope>
                    title="Tags" 
                    dataIndex="documentation_part_properties" 
                    render={(_: any, record: { documentation_part_properties: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.documentation_part_properties?.tags
                                }` }
                            />
                        );
                    }}
                />
            </Table>
        </List>
    );
};

