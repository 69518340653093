import React from "react";
import { IResourceComponentsProps, useNotification } from "@refinedev/core";

import { Edit, ListButton, RefreshButton, SaveButton, useForm } from "@refinedev/antd";

import { Alert, Checkbox, Divider, Form, Input, Select, Space, Spin, Tooltip, Typography } from "antd";
import axios from 'axios';
const { Title, Text } = Typography;
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';

import { IGlobalConfig } from "interfaces";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
export const GlobalConfigEdit: React.FC<IResourceComponentsProps> = () => {
      
    const navigate = useNavigate();
    const params= useParams()
    

    const { formLoading ,formProps, saveButtonProps, onFinish, mutationResult, queryResult } = useForm<IGlobalConfig[]>(
        {
            resource: "app-configs",
            action: "edit",
            id: params.id,
            redirect: false
        }
    );

    const { open } = useNotification();

    const { Option } = Select;

    let [editAppConfigVersion, setEditAppConfigVersion] = React.useState<any>([])
    let [newVersion, setNewVersion] = React.useState<any>(queryResult?.data?.data[0].version)
    let [originalVersionContent, setOriginalVersionContent] = React.useState<any>(null)
    let [isLoadingNewVersion, setIsLoadingNewVersion] = React.useState<boolean>(false)
    let [showCodeBuildNotification, setShowCodeBuildNotification] = React.useState<any>(null)

    const handleOnChangeSelectVersion = (e: any) => {     
        const new_app_config_versions = queryResult?.data?.data ? queryResult?.data?.data : []
        const new_app_config_version = new_app_config_versions.find((element) => element.version == e);
        setNewVersion(new_app_config_version)
        setEditAppConfigVersion(new_app_config_version?.content)
        setOriginalVersionContent(new_app_config_version?.content)
        console.log(new_app_config_version?.content)
    };

    const refreshTableResults = () => {     
        queryResult?.refetch()
    };

    async function handleOnClickSaveButton(action: string) {

        setIsLoadingNewVersion(true)
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
        const domain = process.env.REACT_APP_API_BASE_URL
        const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
        const qa_environment = qa_config["environment"]
        const blue_config = qa_config["config"]

        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "admin-portal",
            "Source-Region": "us-west-2",
            "Destination-Region": "us-west-2",
        };

        const application_id = newVersion?.application_id
        const application_name = newVersion?.application_name
        const application_region = newVersion?.region
        const application_environment = newVersion?.environment
        const configuration_profile_id = newVersion?.configuration_profile_id
        const version = newVersion?.version
        const content = editAppConfigVersion


        const id = application_id 

        const apiUrl = "https://" + continent.toLowerCase() + ".api." + domain 
        console.log("Sending the request")
        let url
        if (qa_environment == "blue"){
            url = `${apiUrl}/internal/tools/app-config-mgmt/blue/ANY/${continent.toUpperCase()}/v0/app-configs/${id}`
        } 
        else{
            url = `${apiUrl}/internal/tools/app-config-mgmt/ANY/${continent.toUpperCase()}/v0/app-configs/${id}`
        }
       

        const obj = {
            "environment_name": application_environment,
            "region": application_region,
            "application_name": application_name,
            "configuration_profile_id": configuration_profile_id,
            "content": content,
            "actions": [action],
            "version": version
        }
        console.log(obj)

        try {
            const { data, status } = await axios.put(
                url,
                JSON.stringify(obj), {
                    headers: requestHeaders
                }
            )
            console.log(data)
            console.log(status)
            let new_message = ""
            let new_description = ""
            if(action == "new_version"){
                if(String(data.result.new_version) == String(version)){
                    new_message =  "No changes"
                    new_description = "Version: " + data.result.new_version
                } else{
                    new_message =  "New version: " + data.result.new_version
                    new_description = "Version: " + data.result.new_version
                    refreshTableResults()
                    setEditAppConfigVersion(originalVersionContent)
                }
            }
            if(action == "deploy"){
                new_message =  "Deployed"
                new_description = "Version: " + version
            }
            if(action == "codecommit"){
                setShowCodeBuildNotification(data)

                new_message =  "Started the codebuild job"
                new_description = "Started the codebuild job"
            }
            if (data.ok){
                open?.({
                    type: "success",
                    message: new_message,
                    description: new_description,
                    key: "app-configs",
                });
            } else{
                open?.({
                    type: "error",
                    message: data.error?.message,
                    description: data.error?.reason,
                    key: "app-config",
                });  
            }
        } catch (error: any) {
            console.log(error.response); 
            setIsLoadingNewVersion(false)
            
            return error.response;
            
          }       


        setIsLoadingNewVersion(false)
    }
    

    return (
        <Edit isLoading={formLoading} saveButtonProps={saveButtonProps} resource="app-configs" headerButtons={({ listButtonProps, refreshButtonProps }) => (
            <>
              {listButtonProps && <ListButton {...listButtonProps} meta={{ foo: "bar" }} />}
              <RefreshButton {...refreshButtonProps} disabled={queryResult?.isLoading || queryResult?.isFetching } loading={queryResult?.isLoading || queryResult?.isFetching } onClick={refreshTableResults} />
            </>
          )} 

            
    //   wrapperProps={{
    //     style: {
    //       backgroundColor: "orange",
    //       padding: "16px",
    //     },
    //   }}
    footerButtonProps={{
        style: {
            float: "left",
            marginLeft: "24px"
        },
    }}
      footerButtons={({ saveButtonProps }) => (
        
        <>
          <SaveButton {...saveButtonProps} onClick={(e:any) => handleOnClickSaveButton("new_version")} loading={isLoadingNewVersion} >Save as new Version in App config</SaveButton>
          <SaveButton {...saveButtonProps} onClick={(e:any) =>handleOnClickSaveButton("deploy")} loading={isLoadingNewVersion} >Deploy Version {newVersion?.version} to {newVersion?.environment} and region {newVersion?.region}</SaveButton>
          <SaveButton {...saveButtonProps} onClick={(e:any) =>handleOnClickSaveButton("codecommit")} loading={isLoadingNewVersion} >Add as current version to CodeCommit</SaveButton>

        </>
        
      )}
      >
            {
                showCodeBuildNotification ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                    message={<span>Global config update in codecommit request can be followed here: <a href={showCodeBuildNotification?.result?.codecommit?.follow_build_here} target="_blank"> link </a> </span> }
                    type="success" 
                    />
                    <pre>
                        
                    </pre>
                </Space> :
                ""
            }  
            {queryResult?.isLoading || queryResult?.isFetching  ? <Spin size="large" /> : null}
            <Form {...formProps} layout="vertical">
                <Title level={5}>Application id</Title>
                <Text>{queryResult?.data?.data[0]?.application_id}</Text>
                <Title level={5}>Environment</Title>
                <Text>{queryResult?.data?.data[0]?.environment}</Text>
                <Title level={5}>Region</Title>
                <Text>{queryResult?.data?.data[0]?.region}</Text>
                <Title level={5}>Configuration Profile Id</Title>
                <Text>{queryResult?.data?.data[0]?.configuration_profile_id}</Text>
                <Title level={5}>Configuration Profile Name</Title>
                <Text>{queryResult?.data?.data[0]?.configuration_profile_name}</Text>

                <Title level={5}>Select the version to edit:</Title>
                    <Tooltip title="Which version do you want to edit">
                    <Select
                        style={{ width: 500 }}
                        // defaultValue={}
                        size='large'
                        onChange={(e) => handleOnChangeSelectVersion(e)}
                        options={queryResult?.data?.data.map((item: any) => ({ "value": String(item.version), "label": String(item.version)} ))}
                    />
                    </Tooltip>
                    <Divider></Divider>
            </Form>
            <AceEditor  
                style={{width: 1000}}
                mode="json"
                theme="monokai"
                wrapEnabled={true}
                value={ editAppConfigVersion ? JSON.stringify(editAppConfigVersion, null, 2) : '{}' } 
                onChange={newValue => setEditAppConfigVersion(JSON.parse(newValue))}
                name="UNIQUE_ID_OF_DIV"
                editorProps={{ $blockScrolling: true }}
                />
          <Divider></Divider>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                    message="Attention"
                    description={
                        <p>
                          App configs are stored as infrastructure as code. These need to be added in app config and deployed, as well as added in CodeCommit. 
                          <br />
                          App config is per environment and per region, while the Infrastructure as code in codecommit is global and across environments
                          <br />
                        </p>
                      }
                    type="info" 
                    />

                </Space>
        </Edit>
        
    );
};

