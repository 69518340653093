import React from "react";
import { IResourceComponentsProps, useNotification } from "@refinedev/core";

import { Edit, ListButton, RefreshButton, SaveButton, TagField, useForm } from "@refinedev/antd";

import { Alert, Button, Checkbox, Divider, Form, Input, Select, Space, Spin, Tooltip, Typography } from "antd";
import axios from 'axios';
const { Title, Text } = Typography;

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { isAddress } from 'viem'
import AceEditor from 'react-ace';

import { IAbi } from "interfaces";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
export const AbiEdit: React.FC<IResourceComponentsProps> = () => {
      
    const navigate = useNavigate();
    const params= useParams()
    
    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      };
      
      const formItemLayoutWithOutLabel = {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 },
        },
      };
    const { formLoading ,formProps, saveButtonProps, onFinish, mutationResult, queryResult } = useForm<IAbi>(
        {
            resource: "abis",
            action: "edit",
            id: params.id,
            redirect: "show"
        }
    );
    const record = queryResult?.data?.data;

    const { open } = useNotification();

    const { Option } = Select;

    const refreshTableResults = () => {     
        queryResult?.refetch()
    };

    let colors = {
        "us-west-2": "green",
        "us-east-1": "lightgreen",
        "eu-central-1": "blue",
        "eu-west-1": "lightblue"
    }

    let [abi, setAbi] = React.useState<string>()

    return (
        <Edit isLoading={formLoading} saveButtonProps={saveButtonProps} resource="abis" headerButtons={({ listButtonProps, refreshButtonProps }) => (
            <>
              {listButtonProps && <ListButton {...listButtonProps} meta={{ foo: "bar" }} />}
              <RefreshButton {...refreshButtonProps} disabled={queryResult?.isLoading || queryResult?.isFetching } loading={queryResult?.isLoading || queryResult?.isFetching } onClick={refreshTableResults} />
            </>
          )} 

            
    //   wrapperProps={{
    //     style: {
    //       backgroundColor: "orange",
    //       padding: "16px",
    //     },
    //   }}
    // footerButtonProps={{
    //     style: {
    //         float: "left",
    //         marginLeft: "24px"
    //     },
    // }}
    //   footerButtons={({ saveButtonProps }) => (
        
    //     <>
    //       <SaveButton {...saveButtonProps} onClick={(e:any) => handleOnClickSaveButton("new_version")} loading={isLoadingNewVersion} >Save</SaveButton>
    //     </>
        
    //   )}
      >
            {queryResult?.isLoading || queryResult?.isFetching  ? <Spin size="large" /> : null}
            <Form {...formProps} layout="vertical">
                <Title level={5}>Abi Environment</Title>
                <TagField value={record?.environment}></TagField>
                <Title level={5}>Abi Region</Title>
                <TagField color={colors[record?.region as keyof typeof colors ]} value={record?.region}></TagField>
                <Title level={5}>Abi Org Id</Title>
                <Text>{record?.org_id}</Text>
                <Title level={5}>Abi Tenant Id</Title>
                <Text>{record?.tenant_id}</Text>
                <Title level={5}>Abi User Id</Title>
                <Text>{record?.created_by}</Text>
                <Title level={5}>Abi Id</Title>
                <Text>{record?.id}</Text>
                <Title level={5}>Abi Chain id</Title>
                <Text>{record?.chain_id}</Text>
                <Title level={5}>Abi Address</Title>  
                <Text>{record?.address}</Text>                        
                <Title level={5}>Abi type</Title>
                <Text>{record?.abi_type}</Text>                                 
                <Divider></Divider>
                <Form.Item
                    label="Abi Name"
                    name="name"  
                    initialValue={record?.name}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Input></Input>
                </Form.Item>                
                <Form.Item
                    label="Abi Description"
                    name="description"  
                    initialValue={record?.description}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Input></Input>
                </Form.Item>                   
                <Form.Item
                    label="Abi Status"
                    name="status"  
                    initialValue={record?.status}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select the abi status">
                        <Option value="active">Active</Option>
                        <Option value="inactive">Inactive</Option>
                    </Select>                  
                </Form.Item>
                <Form.Item
                    hidden={false}
                    label="Abi"
                    name="abi"   
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                <AceEditor  
                        style={{width: 1000}}
                        mode="json"
                        theme="monokai"
                        wrapEnabled={true}
                        defaultValue={JSON.stringify(record ? JSON.parse(record?.abi) : {},null,2)} 
                        onChange={newValue => {
                            try {
                                setAbi(newValue);
                            } catch (e) {
                                console.log(e)
                            }
                        }}
                        name="abi"
                        editorProps={{ $blockScrolling: true }}
                />
                </Form.Item>                 
            </Form>            
        </Edit>
        
    );
};

