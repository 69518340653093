import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";

import { Edit, useForm } from "@refinedev/antd";

import { Alert, Checkbox, Form, Input, Select, Space, Tooltip } from "antd";

import { ISecret } from "interfaces";
export const SecretEdit: React.FC<IResourceComponentsProps> = () => {
        
    const { formLoading ,formProps, saveButtonProps, onFinish, mutationResult, queryResult } = useForm<ISecret>(
        {
            resource: "secrets",
            action: "edit",
            redirect: false,
        }
    );

    const { Option } = Select;

    return (
        <Edit isLoading={formLoading} saveButtonProps={saveButtonProps} resource="secrets">
            {
                mutationResult?.data?.data?.follow_build_here ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                    message={<span>Edit secret request can be followed here: <a href={mutationResult?.data?.data?.follow_build_here} target="_blank"> link </a> </span> }
                    type="success" 
                    />
                    <pre>
                        
                    </pre>
                </Space> :
                ""
            }  

            <Form {...formProps} layout="vertical" >
                <Form.Item
                    label="Environment"
                    name="environment"  
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Project"
                    name="project"  
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Category"
                    name="category"  
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Region"
                    name="region"  
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"  
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Last Changed date"
                    name="last_changed_date"  
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>  
                <Form.Item
                    label="Path"
                    name="path"  
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>      
                <Form.Item
                    label="Created at"
                    name="created_date"  
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>   
                <Form.Item
                    label="Old Encrypted Value"
                    name="old_value"                    
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input disabled={true} placeholder="*************************************************************"/>
                </Form.Item >         
                <Form.Item
                    label="New Encrypted Value"
                    name="value"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="AQICAHiAgdI1XqR/4urv84wNZiCRLSC8txaOPzMNJfuyAvCNPgF/j7LddKYgyqCkG2gj5QL/AAAAfjB8BgkqhkiG9w0BBwa"/>
                </Form.Item>      
            </Form>
            <Space>
                <Alert
                    message="Encrypt the value with the following command: "
                    description="curl -X POST -d 'My super secret that rocks!' https://us.api.authe.io/internal/secrets/ANY/US/v0/encrypt"
                    type="info"
                    />
            </Space>  
        </Edit>
    );
};

