import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    EditButton,
    ShowButton,
    TextField,
    DeleteButton,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space } from "antd";

import { IRole } from "interfaces";


export const ExternalRolesList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, sorter, tableQueryResult } = useTable<IRole>({
        resource: "roles",
        meta: { 
            "org_id": "ALL",
            "tenant_id": "ALL",
        },
    });

    const datetime = dayjs.unix(1653134400)


    return (
        <List>
            <Table {...tableProps} rowKey="id" dataSource={tableQueryResult?.data? tableQueryResult.data.data.filter(item => item.type == "external")
 : []}>
                <Table.Column<IRole>
                    sorter={ (a: any, b: any) => a.id - b.id}
                    title="Id" 
                    dataIndex="id" 
                    render={(_: any, record: { id: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.id
                                }` }
                            />
                        );
                    }}
                />
                <Table.Column<IRole>
                    sorter={ (a: any, b: any) => a.role_name - b.role_name}
                    filters={tableQueryResult?.data?.data.filter(item => item.type == "internal").map((
                        { role_name }) => ({ "text": role_name, "value": role_name})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { role_name: string; }) => record.role_name.startsWith(value)}
                    title="Name" 
                    dataIndex="role_name" 
                    render={(_: any, record: { role_name: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.role_name
                                }` }
                            />
                        );
                    }}
                />    
                <Table.Column<IRole>
                    sorter={ (a: any, b: any) => a.type - b.type}
                    filters={tableQueryResult?.data?.data.filter(item => item.type == "internal").map((
                        { type }) => ({ "text": type, "value": type})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { type: string; }) => record.type.startsWith(value)}
                    title="Type" 
                    dataIndex="type" 
                    render={(_: any, record: { type: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.type
                                }` }
                            />
                        );
                    }}
                />      
                <Table.Column<IRole>
                    sorter={ (a: any, b: any) => a.scopes - b.scopes}
                    filters={tableQueryResult?.data?.data.map(( { scopes }) => (scopes)).flat(1).map(( scope ) => ({ "text": scope, "value": scope})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { scopes: any; }) => record.scopes.includes(value)}
                    title="Scopes" 
                    dataIndex="scopes" 
                    render={(_: any, record: { scopes: Array<string>; }) => {
                        return (
                            record.scopes.map((scope: any) => <TagField id={scope} key={scope} value={scope}  style={{ marginTop: 5, marginBottom: 5 }}></TagField>)
                        );
                    }}
                />      
                {/* <Table.Column<IRole>
                    title="Created At" 
                    dataIndex="created_at" 
                    render={(_: any, record: { created_at: string; }) => {
                        return (
                            <DateField value={dayjs.unix(Number(record?.created_at) || 1)} format="LLL"></DateField>
                        );
                    }}
                />  */}
                <Table.Column<IRole>
                    title="Actions"
                    dataIndex="id"
                    render={(_: any, record: { id: string; }) => (
                        <Space>
                            <ShowButton
                                size="small"
                                recordItemId= {record.id}
                            />
                            <EditButton
                                size="small"
                                recordItemId= {record.id}
                            />                            
                        </Space>
                    )}
                />        
            </Table>
        </List>
    );
};

