import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useShow, IResourceComponentsProps, useOne } from "@refinedev/core";

import { TagField, Show, EditButton, ShowButton, DateField } from "@refinedev/antd";

import { Button, Checkbox, Form, Space, Table, Tag, Typography } from "antd";

import { IRole } from "interfaces";

const { Title, Text } = Typography;
import axios from 'axios';
import dayjs from "dayjs";


export const InternalRoleShow: React.FC<IResourceComponentsProps> = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const { queryResult } = useShow<IRole>();
    const { data, isLoading } = queryResult;
    const record = data?.data;

    let [roleAssignedToUsers, setRoleAssignedToUsers] = React.useState([])

    async function fetchRoleAssignedToUser() {
      console.log('fetching role assigned to users');
      setIsLoadingRoleAssignedToUsers(true)
    
      const token = JSON.parse(localStorage.getItem('token') || '{}');
      const continent = token[process.env.REACT_APP_BASE_URL + "/continent"]
      const domain = process.env.REACT_APP_API_BASE_URL
  
      const url = "https://" + continent.toLowerCase() + ".api." + domain + "/management/permissions/GET/" + continent.toUpperCase() + "/v0/roles/" + searchParams.get("id") + "/users"
    //   const url = "https://us.api.sandbox.authe.io/management/permissions/GET/US/v0/roles/sandbox-Admin-Portal-Admin/users"
      const requestHeaders = {
          Authorization: `Bearer ${token.__raw}`,
          Accept: "application/json, text/plain, */*",
          "Source-Platform": "admin-portal",
          "Source-Region": "us-west-2",
          "Destination-Region": "us-west-2",
      };
  
      return fetch(url, {headers: requestHeaders})
        .then((response) => 
          response.json() 
        )
        .then((body) => {
            console.log(body.result)
          setRoleAssignedToUsers(body.result);
          setIsLoadingRoleAssignedToUsers(false)
        }).catch(function (error) {
            console.log(error)
            setIsLoadingRoleAssignedToUsers(false)
        })
    }
  
    let [isLoadingRoleAssignedToUsers, setIsLoadingRoleAssignedToUsers] = React.useState(false)

    const { data: categoryData, isLoading: categoryIsLoading } =
        useOne<IRole>({
            resource: "roles",
            id: record?.id || "",
            queryOptions: {
                enabled: !!record,
            },
            meta: {"id": record?.id}
        });

    useEffect(() => {
        fetchRoleAssignedToUser()
    }, []);

    return (
        <Show isLoading={isLoading}>
            <Title level={5}>Id</Title>
            <Text>{record?.id}</Text>
            <Title level={5}>Name</Title>
            <Text>{record?.name}</Text>
            <Title level={5}>Description</Title>
            <Text>{record?.description}</Text>
           <Title level={5}>Status</Title>
            <Text>{record?.status}</Text>
            <Title level={5}>Type</Title>
            <Text>{record?.type}</Text>
            <Title level={5}>Application</Title>
            <Text>{record?.application}</Text>
            <Title level={5}>Combined Permissions currently attached</Title>
            <Text> 
              <Tag  color={"geekblue"} >
                    {
                        record?.all_possible_permissions.map((scope: any)=>{
                          return <Form>
                          <Form.Item label="" name={scope} >
                              <Checkbox defaultChecked={record?.scopes}>{scope.name? scope.name : scope}</Checkbox>
                          </Form.Item>
                        </Form>
                        })
                    }
                </Tag>
            </Text>       
            <Title level={5}>Role assigned to users:</Title> 
            <Table loading={isLoadingRoleAssignedToUsers}  rowKey="id" dataSource={roleAssignedToUsers}>
                  <Table.Column dataIndex="org_id" title="Org Id" />
                  <Table.Column dataIndex="tenant_id" title="Tenant Id" />
                  <Table.Column dataIndex="name" title="Name" />
                  <Table.Column dataIndex="email" title="Email" />
                  <Table.Column dataIndex="created_at" title="Created At"                     
                    render={(_: any, record: { created_at: string; }) => {
                        return (
                            <DateField value={dayjs.unix(Number(record?.created_at) || 1)} format="LLL"></DateField>
                        );
                    }}/> 
                  <Table.Column
                    title="Actions"
                    dataIndex="id"
                    render={(_, record:any) => (
                        <Space>
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.user_id}
                                resource="external-users"
                            />
                        </Space>
                    )}
                />
              </Table> 
        </Show>
    );
};
