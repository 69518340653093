import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";

import { Create, Edit, useForm } from "@refinedev/antd";

import { Alert, Checkbox, Form, Input, Select, Space, Tooltip } from "antd";

import { IRepository } from "interfaces";
export const AwsAccountRequestCreate: React.FC<IResourceComponentsProps> = () => {
        
    const { formLoading ,formProps, saveButtonProps, onFinish, mutationResult } = useForm<IRepository>(
        {
            resource: "aws-account-creation",
            action: "create",
        }
    );

    const { Option } = Select;

    return (
        <Create title="Create new AWS Account" isLoading={formLoading} saveButtonProps={saveButtonProps} resource="aws-account-creation">
            {
                mutationResult?.data?.data.follow_build_here ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                    message={<span>New aws account request can be followed here: <a href={mutationResult?.data?.data?.follow_build_here} target="_blank"> link </a> </span> }
                    type="success" 
                    />
                    <pre>
                        
                    </pre>
                </Space> :
                ""
            }  

            <Form {...formProps} layout="vertical" >
                <Form.Item
                    label="Organizational unit"
                    name="organizational_unit"  
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select an Organizational Unit">
                        <Option value="Sandbox (ou-mcix-63333b14)">Workloads Sandbox <b>(ou-mcix-63333b14)</b> </Option>
                        <Option value="Production (ou-mcix-aur9mscx)">Workloads Prd <b>(ou-mcix-aur9mscx)</b> </Option>
                        <Option value="Sandbox (ou-mcix-5tjcysao)">Infrastructure Sandbox <b>(ou-mcix-5tjcysao)</b></Option>
                        <Option value="Production (ou-mcix-65yn6pod)">Infrastructure Prd <b>(ou-mcix-65yn6pod)</b></Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Account Name"
                    name="account_name"                    
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                    if(value.indexOf(' ') >= 0){
                                        return Promise.reject(
                                            new Error("Can not have spaces in the name")
                                        );
                                    }
                                    if(value.indexOf('_') >= 0){
                                        return Promise.reject(
                                            new Error("Can not have underscores '_' in the name")
                                        );
                                    }      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                    <Input placeholder="Shared-Services-Sandbox" />
                </Form.Item>
                <Form.Item
                    label="Account Email"
                    name="account_email"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="admin+shared+services+sandbox@authe.io"/>
                </Form.Item>
                <Form.Item
                    label="Sso User Email"
                    name="sso_user_email"    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                   <Input placeholder="admin+shared+services+sandbox@authe.io" />
                </Form.Item>
                <Form.Item
                    label="Sso user first name"
                    name="sso_user_first_name"    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Sso user last name"
                    name="sso_user_last_name"    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Account customizations name"
                    name="account_customizations_name"  
                    initialValue={"SANDBOX"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select an account customizations name">
                        <Option value="SANDBOX">Sandbox</Option>
                        <Option value="PRODUCTION">Prd</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Environment"
                    name="environment"  
                    initialValue={"SANDBOX"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select an environment">
                        <Option value="SANDBOX">Sandbox</Option>
                        <Option value="PRODUCTION">Prd</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Dry run?"
                    name="dry_run"    
                    initialValue={true}                
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Tooltip title="Dry run">
                      <Checkbox defaultChecked={false}></Checkbox> 
                    </Tooltip>
                </Form.Item>

            </Form>
        </Create>
    );
};
