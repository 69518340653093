import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    EditButton,
    ShowButton,
    TextField,
    DeleteButton,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space, Typography, Alert, Divider } from "antd";

import { IAwsAccountRequest } from "interfaces";
import { useAuth0 } from '@auth0/auth0-react';
const { Title, Text } = Typography;


export const AwsAccountRequestList: React.FC<IResourceComponentsProps> = () => {

    const {
        user,
      } = useAuth0();
      
      const { tableProps, tableQueryResult } = useTable<IAwsAccountRequest>({
        resource: "aws-account-creation"
    });

    return (

        <List title="Aws account management">
            <Title level={5}>Id</Title>
            <Text></Text>    
            {
                tableQueryResult?.data?.summary.summary.time_needed_in_seconds ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                    message={"Time taken to load the results: " + tableQueryResult?.data?.summary.summary.time_needed_in_seconds + " seconds"}
                    type="success"

                    />
                </Space> :
                ""
            }      
            <Divider />  
            <Table {...tableProps} rowKey="name"
            pagination={{
                defaultPageSize:500,
                showSizeChanger:true,
            }}
            >
            <Table.Column<IAwsAccountRequest>
                    title="Account Name" 
                    dataIndex="control_tower_parameters" 
                    render={(_: any, record: { control_tower_parameters: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.control_tower_parameters.AccountName
                                }` }
                            />
                        );
                    }}
                />
             <Table.Column<IAwsAccountRequest>
                    title="Account Email" 
                    dataIndex="control_tower_parameters" 
                    render={(_: any, record: { control_tower_parameters: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.control_tower_parameters.AccountEmail
                                }` }
                            />
                        );
                    }}
                />       
             <Table.Column<IAwsAccountRequest>
                    title="Account Customizations Name" 
                    dataIndex="account_customizations_name" 
                    render={(_: any, record: { account_customizations_name: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.account_customizations_name
                                }` }
                            />
                        );
                    }}
                />    
             <Table.Column<IAwsAccountRequest>
                    title="Account Tags" 
                    dataIndex="account_tags" 
                    render={(_: any, record: { account_tags: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.account_tags
                                }` }
                            />
                        );
                    }}
                />  
            </Table>
        </List>
    );
};

