import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    EditButton,
    ShowButton,
    TextField,
    DeleteButton,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space, Typography, Tooltip, Divider, Button } from "antd";

import { ISecret, IUser } from "interfaces";
import { useAuth0 } from '@auth0/auth0-react';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
const { Title, Text } = Typography;

export const SecretsList: React.FC<IResourceComponentsProps> = () => {
    const CheckboxGroup = Checkbox.Group;
    const [searchAddress, setSearchAddress] = useState<string>("0x1f9090aaE28b8a3dCeaDf281B0F12828e676c326");
    const [finalSearchAddress, setFinalSearchAddress] = useState<string>("0x1f9090aaE28b8a3dCeaDf281B0F12828e676c326");
    const [isInvalidSearchAddress, setIsInvalidSearchAddress] = useState<boolean>(false);

    const all_possible_regions = [
        {
            "label": "us-west-2",
            "value": "us-west-2"
        },
        {
            "label": "us-east-1",
            "value": "us-east-1"
        },
        {
            "label": "eu-central-1",
            "value": "eu-central-1"
        },
        {
            "label": "eu-west-1",
            "value": "eu-west-1"
        },
    ]

    const plainOptions = all_possible_regions
    const defaultCheckedList = ["us-west-2", "us-east-1"] // all_possible_regions.map(a => a.value);

    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList);
    const [searchCheckedList, setSearchCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList);

    const checkAll = plainOptions.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;
  
    const onChange = (list: CheckboxValueType[]) => {
      setCheckedList(list);
    };
  
    const onCheckAllChange = (e: CheckboxChangeEvent) => {
      setCheckedList(e.target.checked ? plainOptions.map(a => a.value) : []);
    };
    
    const {
        user,
      } = useAuth0();
      
      const { tableProps, tableQueryResult } = useTable<ISecret>({
        resource: "secrets",
        meta: { 
            "regions": searchCheckedList,
        },
    });

    const refreshTableResults = () => {     
        setSearchCheckedList(checkedList)   
        tableQueryResult.refetch()
    };

    return (

        <List >
            <Title level={5}>Search regions for secrets:</Title>
            <Divider />
            <Space.Compact size="large">
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                Check all
            </Checkbox>
                <Text>
                {
                   <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
                }    
                </Text> 
            </Space.Compact>
            <Divider />
            <Space.Compact style={{ width: '100%' }}>
            <Button type="primary" onClick={refreshTableResults} disabled={tableQueryResult?.isFetching }>Submit</Button>
            </Space.Compact>
            <Divider />
            <Table {...tableProps} rowKey="arn" dataSource={tableQueryResult?.data?.data }
                        pagination={{
                            defaultPageSize:500,
                            showSizeChanger:true,
                        }}
            >
                <Table.Column<ISecret>
                    sorter={ (a: any, b: any) => a.arn - b.arn}
                    title="Id" 
                    dataIndex="arn" 
                    render={(_: any, record: { arn: any; }) => {
                        return (
                            <Tooltip title={record?.arn}>
                            <TagField
                                value={`${
                                    record?.arn.substring(0, 2)
                                }` }
                            />
                            </Tooltip>
                        );
                    }}
                />
                <Table.Column<ISecret>
                    sorter={ (a: any, b: any) => a.name - b.name}
                    filters={tableQueryResult?.data?.data.map((
                        { name }) => ({ "text": name, "value": name})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { name: string; }) => record.name.startsWith(value)}
                    title="Name" 
                    dataIndex="name" 
                    render={(_: any, record: { name: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.name
                                }` }
                            />
                        );
                    }}
                />    
                <Table.Column<ISecret>
                    sorter={ (a: any, b: any) => a.region - b.region}
                    filters={tableQueryResult?.data?.data.map((
                        { region }) => ({ "text": region, "value": region})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { region: string; }) => record.region.startsWith(value)}
                    title="Region" 
                    dataIndex="region" 
                    render={(_: any, record: { region: string; }) => {
                        let colors = {
                            "us-west-2": "green",
                            "us-east-1": "lightgreen",
                            "eu-central-1": "blue",
                            "eu-west-1": "lightblue"
                        }
                        return (
                            <TagField
                                color={colors[record.region as keyof typeof colors ]}
                                value={`${
                                    record?.region
                                }` }
                            />
                        );
                    }}
                />      
                <Table.Column<ISecret>
                    sorter={ (a: any, b: any) => a.created_date - b.created_date}
                    filters={tableQueryResult?.data?.data.map((
                        { created_date }) => ({ "text": created_date, "value": created_date})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { created_date: string; }) => record.created_date.startsWith(value)}
                    title="Creation date" 
                    dataIndex="created_date" 
                    render={(_: any, record: { created_date: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.created_date
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<ISecret>
                    sorter={ (a: any, b: any) => a.last_rotation_date - b.last_rotation_date}
                    filters={tableQueryResult?.data?.data.map((
                        { last_rotation_date }) => ({ "text": last_rotation_date, "value": last_rotation_date})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { last_rotation_date: string; }) => record.last_rotation_date.startsWith(value)}
                    title="Last Rotation date" 
                    dataIndex="last_rotation_date" 
                    render={(_: any, record: { last_rotation_date: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.last_rotation_date
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<ISecret>
                    title="Actions"
                    dataIndex="arn"
                    render={(_: any, record: { arn: string; }) => (
                        <Space>
                            <EditButton
                                size="small"
                                recordItemId= {record.arn}
                            />
                        </Space>
                    )}
                />        
            </Table>
        </List>
    );
};

