import React from "react";
import { IResourceComponentsProps, useNotification } from "@refinedev/core";

import { Create, CreateButton, Edit, SaveButton, useForm } from "@refinedev/antd";

import { Alert, Checkbox, Divider, Flex, Form, Input, Select, Space, Tooltip } from "antd";

import { IWorkItem } from "interfaces";
import { isAddress } from 'viem'
import axios from 'axios';

import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';

export const WorkItemCreate: React.FC<IResourceComponentsProps> = () => {
        
    const { formLoading ,formProps, saveButtonProps, onFinish, mutationResult } = useForm<IWorkItem>(
        {
            resource: "work-items",
            action: "create",
        }
    );

    const { Option } = Select;

    const { open } = useNotification();

    let [showInvalidWorkItemJson, setShowInvalidWorkItemJson] = React.useState<boolean>(false)
    let [editWorkItem, setEditWorkItem] = React.useState<any>(null)
    let [isLoadingNewWorkItem, setIsLoadingWorkItem] = React.useState<boolean>(false)
    let [isLoadingNewMoralisTransaction, setIsLoadingNewMoralisTransaction] = React.useState<boolean>(false)

    
    
    let [selectedBlueGreenEnvironment, setSelectedBlueGreenEnvironment] = React.useState<string>("green")
    let [selectedEnvironment, setSelectedEnvironment] = React.useState<string>("sandbox")
    let [selectedContinent, setSelectedContinent] = React.useState<string>("us")
    let [selectedStage, setSelectedStage] = React.useState<string>("pre_process")

    let [requestNewMoralisTransactionObject, setRequestNewMoralisTransactionObject] = React.useState<any>(null)

    
    async function handleOnClickSaveButton(action: string) {

        setIsLoadingWorkItem(true)
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
        const domain = process.env.REACT_APP_API_BASE_URL
        const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
        const qa_environment = qa_config["environment"]
        const blue_config = qa_config["config"]

        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "admin-portal",
            "Source-Region": "us-west-2",
            "Destination-Region": "us-west-2",
        };


        const apiUrl = "https://" + selectedContinent.toLowerCase() + ".api." + domain 
        console.log("Sending the request")
        let url
        if (qa_environment == "blue"){
            url = `${apiUrl}/internal/tools/process-transactions-mgmt-debug-tool/blue/ANY/${selectedContinent.toUpperCase()}/v0/work-items`
        }  
        else{
            url = `${apiUrl}/internal/tools/process-transactions-mgmt-debug-tool/ANY/${selectedContinent.toUpperCase()}/v0/work-items`
        }
        console.log(url)

        if(editWorkItem == undefined){
            console.log("Nothing to update")
            open?.({
                type: "success",
                message: "Nothing to update",
                key: "work-items",
            });
        } 
        else{
            
            try {
                
                let table_name = ""
                if(selectedStage == "pre_process"){
                    table_name = `${selectedEnvironment}-global-${selectedContinent.toLowerCase()}-process-transactions-mgmt-pre-processed-transactions-lock-table`
                }
                else if(selectedStage == "process"){
                    table_name = `${selectedEnvironment}-global-${selectedContinent.toLowerCase()}-process-transactions-mgmt-processed-transactions-lock-table`  
                }
                else if(selectedStage == "post_process"){
                    table_name = `${selectedEnvironment}-global-${selectedContinent.toLowerCase()}-process-transactions-mgmt-post-processed-transactions-lock-table`  
                }
                else{
                    console.log("Could not determine the table_name")
                }
         
                var editWorkItems = []
                if (!Array.isArray(editWorkItem)){
                    editWorkItems = [editWorkItem]
                } else{
                    editWorkItems = editWorkItem
                }
                

                for (var editWorkItemElement of editWorkItems) {

                    editWorkItemElement["table"] = table_name 
                    editWorkItemElement["blue_green"] = selectedBlueGreenEnvironment 
                    editWorkItemElement["is_blue"] =  selectedBlueGreenEnvironment == "blue" ? true : false

                    console.log(editWorkItemElement)
                    const { data, status } = await axios.post(
                        url,
                        JSON.stringify(editWorkItemElement), {
                            headers: requestHeaders
                        }
                    )
                    console.log(data)
                    console.log(status)
                    let new_message = "success"
                    let new_description = "Updated work item"
                        
                    if (data.ok){
                        open?.({
                            type: "success",
                            message: new_message,
                            description: new_description,
                            key: "work-items",
                        });
                    } else{
                        if(data.error.reason.includes("ConditionalCheckFailedException")){
                            open?.({
                                type: "error",
                                message: "A work item with the same key already exists " + editWorkItemElement.hash,
                                description: data.error?.reason,
                                key: "work-items",
                            });  
                        }
                        else{
                            open?.({
                                type: "error",
                                message: "There was an error",
                                description: data.error?.message,
                                key: "work-items",
                            });  
                        }
        
                    }
                }
            } catch (error: any) {
                console.log(error)
                console.log(error.response); 
                setIsLoadingWorkItem(false)
                
                return error.response;
                
            }       
        }

        setIsLoadingWorkItem(false)
    }
    
    async function handleOnClickNewMoralisTransaction(action: string) {

        setIsLoadingNewMoralisTransaction(true)
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
        const domain = process.env.REACT_APP_API_BASE_URL
        const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
        const qa_environment = qa_config["environment"]
        const blue_config = qa_config["config"]

        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "admin-portal",
            "Source-Region": "us-west-2",
            "Destination-Region": "us-west-2",
        };


        const apiUrl = "https://" + selectedContinent.toLowerCase() + ".api." + domain 
        console.log("Sending the request")
        let url
        if (qa_environment == "blue"){
            url = `${apiUrl}/internal/tools/moralis-mgmt-debug-tool/blue/ANY/${selectedContinent.toUpperCase()}/v0/send-message-to-moralis-queue`
        }  
        else{
            url = `${apiUrl}/internal/tools/moralis-mgmt-debug-tool/ANY/${selectedContinent.toUpperCase()}/v0/send-message-to-moralis-queue`
        }
        console.log(url)

        if(editWorkItem == undefined){
            console.log("Nothing to request")
            open?.({
                type: "success",
                message: "Nothing to request",
                key: "new-moralis-transaction",
            });
        } 
        else{
            try {
                let queue_name = "-moralis-mgmt-request-moralis-transaction-queue"
                if (selectedBlueGreenEnvironment == "blue"){
                    queue_name = queue_name + "-" + selectedBlueGreenEnvironment
                }

                var editWorkItems = []
                if (!Array.isArray(editWorkItem)){
                    editWorkItems = [editWorkItem]
                } else{
                    editWorkItems = editWorkItem
                }

                for (var editWorkItemElement of editWorkItems) {
                    let new_obj = {
                        "queue_name": queue_name,
                        "region": editWorkItemElement.region,
                        "message": {
                            "hash": [
                                editWorkItemElement.hash
                            ],
                            "chain": editWorkItemElement.chain,
                            "org_id": editWorkItemElement.org_id,
                            "tenant_id": editWorkItemElement.tenant_id,
                            "user_id": editWorkItemElement.user_id,
                            "continent": selectedContinent,
                            "region": editWorkItemElement.region,
                            "addresses": [
                                editWorkItemElement.address,
                            ],
                            "type": "default",
                            "chains": [
                                editWorkItemElement.chain
                            ],
                            "create_work_item": false
                        },
                        "environment": selectedEnvironment

                    }
                    console.log(new_obj)
                    setRequestNewMoralisTransactionObject(new_obj)

                    const { data, status } = await axios.post(
                        url,
                        JSON.stringify(new_obj), {
                            headers: requestHeaders
                        }
                    )
                    console.log(data)
                    console.log(status)
                    let new_message = "success: " + data.result?.message_id
                    let new_description = "Request new Moralis transaction for hash: " + editWorkItemElement.hash 
                        
                    if (data.ok){
                        open?.({
                            type: "success",
                            message: new_message,
                            description: new_description,
                            key: "work-items",
                        });
                    } else{
                        open?.({
                            type: "error",
                            message: "There was an error",
                            description: data.error?.reason ? data.error?.reason : data.error?.message,
                            key: "new-moralis-transaction",
                        });  
        
                    }
                }
            } catch (error: any) {
                console.log("dffdfdjk")
                setIsLoadingNewMoralisTransaction(false)
                open?.({
                    type: "error",
                    message: "There was an error",
                    description: error.response,
                    key: "new-moralis-transaction",
                });         
                return error.response;
                
            }     
        }

        setIsLoadingNewMoralisTransaction(false)
    }

    const addASimpleTemplate = () => {     
        let obj = {
            "action": "get_transactions",
            "address": "0xeb96be3a74c13b24bdb55dab85437232aa74d1bd",
            "block_number": "4429949",
            "block_timestamp": "2023-10-05T13:11:00.000Z",
            "chain": "sepolia",
            "chain_id": "0xaa36a7",
            "chain_address_batch_has_more_items_to_come": false,
            "continent": "us",
            "create_in_lock_table_timestamp": 1699875167040,
            "custom_moralis_mgmt_org_config": {},
            "custom_moralis_mgmt_tenant_config": {},
            "custom_post_process_transactions_org_config": {},
            "custom_post_process_transactions_tenant_config": {},
            "custom_process_transactions_org_config": {},
            "custom_process_transactions_tenant_config": {},
            "enable_process_order": false,
            "env": "sandbox",
            "force_update": false,
            "full_check_item_already_exists_in_db": true,
            "hash": "0xd58d42fd9c0b891a71a77c8dbbcc65ae300cd68cb6c7386f74fc75332ef2caef",
            "include_config": {},
            "initial_lock_creator": "prd-pdx-moralis-mgmt-retrieve-and-preprocess-transactions",
            "is_automatic_process": false,
            "is_custom_runner": false,
            "is_part_of_batch": false,
            "item_hash_already_exists_in_dispatched_table": true,
            "last_updated_action_reason": "finish_pre_process",
            "last_updated_by": "sandbox-pdx-process-transactions-mgmt-fin-pre-proc-transactions",
            "last_updated_by_registered_worker_slot": "0",
            "last_updated_by_worker_lock_key": "sandbox-global-init-pre-proc-transactions-default",
            "last_updated_timestamp": 1699875600617,
            "lazy_check_item_already_exists_in_db": false,
            "lazy_check_remove_newer_block_numbers": false,
            "lazy_check_remove_older_block_numbers": true,
            "lock_key": "env-org_id-tenant_id-block_timestamp-address-chain-block_number-hash-user_id-message_id-default",
            "lock_value": "sandbox-org_c52a347d69344ef5ab9039a4d030a55b-ten_adf2003502144980b9afd1bca35e4252-2023-10-05T13:11:00.000Z-0xeb96be3a74c13b24bdb55dab85437232aa74d1bd-0xaa36a7-4429949-0xd58d42fd9c0b891a71a77c8dbbcc65ae300cd68cb6c7386f74fc75332ef2caef-google-oauth2|109517966719689351983-de61826a-c81f-43ea-abef-102e7be7b4df-default",
            "message_id": "de61826a-c81f-43ea-abef-102e7be7b4df",
            "message_type": "default",
            "moralis_from_block": true,
            "moralis_from_date": false,
            "moralis_to_block": false,
            "moralis_to_date": false,
            "org_id": "org_c52a347d69344ef5ab9039a4d030a55b",
            "org_tier_level": "1",
            "org_type": "BUSINESS",
            "original_caller_address": "",
            "process_order": "newest_first",
            "region": "us-west-2",
            "retries": 0,
            "scan_index_forward": false,
            "sqs_message_send_timestamp": 1699875155689,
            "status": "ready_to_be_processed",
            "tenant_id": "ten_adf2003502144980b9afd1bca35e4252",
            "tenant_tier_level": "1",
            "tenant_type": "BUSINESS",
            "tracer_config": {},
            "ttl": "1699961567040",
            "user_id": "google-oauth2|109517966719689351983"
        }

        setEditWorkItem(obj)
    };

    const addMultipleWorkItemsTemplate = () => {     
        let obj = [
            {
            "action": "get_transactions",
            "address": "0xeb96be3a74c13b24bdb55dab85437232aa74d1bd",
            "block_number": "4429949",
            "block_timestamp": "2023-10-05T13:11:00.000Z",
            "chain": "sepolia",
            "chain_id": "0xaa36a7",
            "chain_address_batch_has_more_items_to_come": false,
            "continent": "us",
            "create_in_lock_table_timestamp": 1699875167040,
            "custom_moralis_mgmt_org_config": {},
            "custom_moralis_mgmt_tenant_config": {},
            "custom_post_process_transactions_org_config": {},
            "custom_post_process_transactions_tenant_config": {},
            "custom_process_transactions_org_config": {},
            "custom_process_transactions_tenant_config": {},
            "enable_process_order": false,
            "env": "sandbox",
            "force_update": false,
            "full_check_item_already_exists_in_db": true,
            "hash": "0xd58d42fd9c0b891a71a77c8dbbcc65ae300cd68cb6c7386f74fc75332ef2caef",
            "include_config": {},
            "initial_lock_creator": "prd-pdx-moralis-mgmt-retrieve-and-preprocess-transactions",
            "is_automatic_process": false,
            "is_custom_runner": false,
            "is_part_of_batch": false,
            "item_hash_already_exists_in_dispatched_table": true,
            "last_updated_action_reason": "finish_pre_process",
            "last_updated_by": "sandbox-pdx-process-transactions-mgmt-fin-pre-proc-transactions",
            "last_updated_by_registered_worker_slot": "0",
            "last_updated_by_worker_lock_key": "sandbox-global-init-pre-proc-transactions-default",
            "last_updated_timestamp": 1699875600617,
            "lazy_check_item_already_exists_in_db": false,
            "lazy_check_remove_newer_block_numbers": false,
            "lazy_check_remove_older_block_numbers": true,
            "lock_key": "env-org_id-tenant_id-block_timestamp-address-chain-block_number-hash-user_id-message_id-default",
            "lock_value": "sandbox-org_c52a347d69344ef5ab9039a4d030a55b-ten_adf2003502144980b9afd1bca35e4252-2023-10-05T13:11:00.000Z-0xeb96be3a74c13b24bdb55dab85437232aa74d1bd-0xaa36a7-4429949-0xd58d42fd9c0b891a71a77c8dbbcc65ae300cd68cb6c7386f74fc75332ef2caef-google-oauth2|109517966719689351983-de61826a-c81f-43ea-abef-102e7be7b4df-default",
            "message_id": "de61826a-c81f-43ea-abef-102e7be7b4df",
            "message_type": "default",
            "moralis_from_block": true,
            "moralis_from_date": false,
            "moralis_to_block": false,
            "moralis_to_date": false,
            "org_id": "org_c52a347d69344ef5ab9039a4d030a55b",
            "org_tier_level": "1",
            "org_type": "BUSINESS",
            "original_caller_address": "",
            "process_order": "newest_first",
            "region": "us-west-2",
            "retries": 0,
            "scan_index_forward": false,
            "sqs_message_send_timestamp": 1699875155689,
            "status": "ready_to_be_processed",
            "tenant_id": "ten_adf2003502144980b9afd1bca35e4252",
            "tenant_tier_level": "1",
            "tenant_type": "BUSINESS",
            "tracer_config": {},
            "ttl": "1699961567040",
            "user_id": "google-oauth2|109517966719689351983",
        },
        {
            "action": "get_transactions",
            "address": "0x1f9090aaE28b8a3dCeaDf281B0F12828e676c326",
            "block_number": "4457749",
            "block_timestamp": "2023-10-09T17:13:36.000Z",
            "chain": "sepolia",
            "chain_id": "0xaa36a7",
            "chain_address_batch_has_more_items_to_come": false,
            "continent": "us",
            "create_in_lock_table_timestamp": 1699875167040,
            "custom_moralis_mgmt_org_config": {},
            "custom_moralis_mgmt_tenant_config": {},
            "custom_post_process_transactions_org_config": {},
            "custom_post_process_transactions_tenant_config": {},
            "custom_process_transactions_org_config": {},
            "custom_process_transactions_tenant_config": {},
            "enable_process_order": false,
            "env": "sandbox",
            "force_update": false,
            "full_check_item_already_exists_in_db": true,
            "hash": "0x0c13787402d906f331809d564738ff9aea5990bc39b9982f0aa8d8d7017eef2b",
            "include_config": {},
            "initial_lock_creator": "prd-pdx-moralis-mgmt-retrieve-and-preprocess-transactions",
            "is_automatic_process": false,
            "is_custom_runner": false,
            "is_part_of_batch": false,
            "item_hash_already_exists_in_dispatched_table": true,
            "last_updated_action_reason": "finish_pre_process",
            "last_updated_by": "sandbox-pdx-process-transactions-mgmt-fin-pre-proc-transactions",
            "last_updated_by_registered_worker_slot": "0",
            "last_updated_by_worker_lock_key": "sandbox-global-init-pre-proc-transactions-default",
            "last_updated_timestamp": 1699875600617,
            "lazy_check_item_already_exists_in_db": false,
            "lazy_check_remove_newer_block_numbers": false,
            "lazy_check_remove_older_block_numbers": true,
            "lock_key": "env-org_id-tenant_id-block_timestamp-address-chain-block_number-hash-user_id-message_id-default",
            "lock_value": "sandbox-org_c52a347d69344ef5ab9039a4d030a55b-ten_adf2003502144980b9afd1bca35e4252-2023-10-09T17:13:36.000Z-0x1f9090aaE28b8a3dCeaDf281B0F12828e676c326-0xaa36a7-4457749-0x0c13787402d906f331809d564738ff9aea5990bc39b9982f0aa8d8d7017eef2b-google-oauth2|109517966719689351983-de61826a-c81f-43ea-abef-2324354-default",
            "message_id": "de61826a-c81f-43ea-abef-2324354",
            "message_type": "default",
            "moralis_from_block": true,
            "moralis_from_date": false,
            "moralis_to_block": false,
            "moralis_to_date": false,
            "org_id": "org_c52a347d69344ef5ab9039a4d030a55b",
            "org_tier_level": "1",
            "org_type": "BUSINESS",
            "original_caller_address": "",
            "process_order": "newest_first",
            "region": "us-west-2",
            "retries": 0,
            "scan_index_forward": false,
            "sqs_message_send_timestamp": 1699875155689,
            "status": "ready_to_be_processed",
            "tenant_id": "ten_adf2003502144980b9afd1bca35e4252",
            "tenant_tier_level": "1",
            "tenant_type": "BUSINESS",
            "tracer_config": {},
            "ttl": "1699961567040",
            "user_id": "google-oauth2|109517966719689351983"
        }
    ]

        setEditWorkItem(obj)
    };

    const addChainLinkCreateSubscriptionTemplate = () => {     
        let obj = {
            "action": "get_transactions",
            "address": "0x3098b6665589959711a48a6bae5b7f2908f6a3be",
            "block_number": "18228247",
            "block_timestamp": "2023-09-27T16:20:35.000Z",
            "chain": "eth",
            "chain_id": "0x1",
            "chain_address_batch_has_more_items_to_come": false,
            "continent": "us",
            "create_in_lock_table_timestamp": 1699875167040,
            "custom_moralis_mgmt_org_config": {},
            "custom_moralis_mgmt_tenant_config": {},
            "custom_post_process_transactions_org_config": {},
            "custom_post_process_transactions_tenant_config": {},
            "custom_process_transactions_org_config": {},
            "custom_process_transactions_tenant_config": {},
            "enable_process_order": false,
            "env": "sandbox",
            "force_update": false,
            "full_check_item_already_exists_in_db": true,
            "hash": "0xffcc3f3b087d7fa26065c762260cb1708c38aa83179c8acd1f39db4142e98d4c",
            "include_config": {},
            "initial_lock_creator": "prd-pdx-moralis-mgmt-retrieve-and-preprocess-transactions",
            "is_automatic_process": false,
            "is_custom_runner": false,
            "is_part_of_batch": false,
            "item_hash_already_exists_in_dispatched_table": true,
            "last_updated_action_reason": "finish_pre_process",
            "last_updated_by": "sandbox-pdx-process-transactions-mgmt-fin-pre-proc-transactions",
            "last_updated_by_registered_worker_slot": "0",
            "last_updated_by_worker_lock_key": "sandbox-global-init-pre-proc-transactions-default",
            "last_updated_timestamp": 1699875600617,
            "lazy_check_item_already_exists_in_db": false,
            "lazy_check_remove_newer_block_numbers": false,
            "lazy_check_remove_older_block_numbers": true,
            "lock_key": "env-org_id-tenant_id-block_timestamp-address-chain-block_number-hash-user_id-message_id-default",
            "lock_value": "sandbox-org_c52a347d69344ef5ab9039a4d030a55b-ten_adf2003502144980b9afd1bca35e4252-2023-09-27T16:20:35.000Z-0x3098b6665589959711a48a6bae5b7f2908f6a3be-0x1-18228247-0xffcc3f3b087d7fa26065c762260cb1708c38aa83179c8acd1f39db4142e98d4c-google-oauth2|109517966719689351983-de61826a-c81f-43ea-abef-102e7be7b4df-default",
            "message_id": "de61826a-c81f-43ea-abef-102e7be7b4df",
            "message_type": "default",
            "moralis_from_block": true,
            "moralis_from_date": false,
            "moralis_to_block": false,
            "moralis_to_date": false,
            "org_id": "org_c52a347d69344ef5ab9039a4d030a55b",
            "org_tier_level": "1",
            "org_type": "BUSINESS",
            "original_caller_address": "",
            "process_order": "newest_first",
            "region": "us-west-2",
            "retries": 0,
            "scan_index_forward": false,
            "sqs_message_send_timestamp": 1699875155689,
            "status": "ready_to_be_processed",
            "tenant_id": "ten_adf2003502144980b9afd1bca35e4252",
            "tenant_tier_level": "1",
            "tenant_type": "BUSINESS",
            "tracer_config": {},
            "ttl": "1699961567040",
            "user_id": "google-oauth2|109517966719689351983"
        }

        setEditWorkItem(obj)
    };


    const addExampleImportTransactionTemplate = () => {     
        let obj = {
            "lock_key": "env-org_id-tenant_id-block_timestamp-address-chain-block_number-hash-user_id-message_id-default",
            "lock_value": "sandbox-org_1234-ten_1234-2023-09-27T18:35:11.000Z--0x1-18228915-0x57b1ab0ce2084d87f6f03a7911871ecbf179020564e3dff486ea48f4da27f6f3-user_1234-3557dcbd-6943-4bca-b766-0cb3f66fc36b-default",
            "action": "get_transaction",
            "address": "",
            "aws:rep:deleting": false,
            "aws:rep:updateregion": "us-west-2",
            "aws:rep:updatetime": 1705489845.305001,
            "block_number": "18228915",
            "block_timestamp": "2023-09-27T18:35:11.000Z",
            "chain": "0x1",
            "chain_id": "0x1",
            "continent": "us",
            "create_in_lock_table_timestamp": 1705489843960,
            "custom_moralis_mgmt_org_config": {},
            "custom_moralis_mgmt_tenant_config": {},
            "custom_post_process_transactions_org_config": {},
            "custom_post_process_transactions_tenant_config": {},
            "custom_process_transactions_org_config": {},
            "custom_process_transactions_tenant_config": {},
            "env": "sandbox",
            "force_update": false,
            "hash": "0x57b1ab0ce2084d87f6f03a7911871ecbf179020564e3dff486ea48f4da27f6f3",
            "include_config": {},
            "initial_lock_creator": "prd-pdx-moralis-mgmt-retrieve-and-preprocess-transaction",
            "is_automatic_process": false,
            "is_custom_runner": false,
            "last_updated_action_reason": null,
            "last_updated_by": null,
            "last_updated_by_registered_worker_slot": null,
            "last_updated_by_worker_lock_key": null,
            "last_updated_timestamp": null,
            "message_id": "3557dcbd-6943-4bca-b766-0cb3f66fc36b",
            "org_id": "org_1234",
            "org_tier_level": 1,
            "org_type": "BUSINESS",
            "original_caller_address": "",
            "region": "us-west-2",
            "retries": 0,
            "sqs_message_send_timestamp": 1705489838567,
            "status": "ready_to_be_processed",
            "tenant_id": "ten_1234",
            "tenant_tier_level": 1,
            "tenant_type": "BUSINESS",
            "tracer_config": {},
            "ttl": "1705576243960",
            "type": "default",
            "user_id": "user_1234"
           }
    

        setEditWorkItem(obj)
    };


    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps} resource="work-items"     footerButtonProps={{
            style: {
                float: "left",
                marginLeft: "24px"
            },
        }}
          footerButtons={({ saveButtonProps }) => (
            
            <>
              <SaveButton {...saveButtonProps} onClick={(e:any) => handleOnClickSaveButton("new_work_item")} loading={isLoadingNewWorkItem} >Create new work item</SaveButton>
              <SaveButton {...saveButtonProps} onClick={(e:any) => handleOnClickNewMoralisTransaction("new_moralis_transaction")} loading={isLoadingNewMoralisTransaction} >Add Corresponding Moralis Transaction</SaveButton>
            
            </>
            
          )}
          >
            <Flex gap="small" wrap="wrap">
                <CreateButton type="link" onClick={addASimpleTemplate}>Add simple template</CreateButton>
                <CreateButton type="link" onClick={addMultipleWorkItemsTemplate}>Add multiple work items template</CreateButton>
                <CreateButton type="link" onClick={addChainLinkCreateSubscriptionTemplate}>Add Chainlink Subscription work items template</CreateButton>
                <CreateButton type="link" onClick={addExampleImportTransactionTemplate}>Add Import Transaction work items template</CreateButton>
                <CreateButton type="link">Add another template</CreateButton>
            </Flex>
            <Divider></Divider>
            <Form {...formProps} layout="vertical" >
            <Form.Item
                    label="Blue/Green"
                    name="blue_green"  
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Tooltip title="Sends to the Latest/Unstable application version (Blue) or to the Live/stable version (Green) ">
                      <Select 
                        onSelect={setSelectedBlueGreenEnvironment}
                        placeholder="Please select blue/green environment"
                        defaultValue={selectedBlueGreenEnvironment}
                       >
                        <Option value="green">Green</Option>
                        <Option value="blue">Blue</Option>
                    </Select>
                    </Tooltip>
                </Form.Item>
                <Form.Item
                    label="Environment"
                    name="environment"  
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                      <Select 
                        onSelect={setSelectedEnvironment}
                        placeholder="Please select an environment"
                        defaultValue={selectedEnvironment}
                       >
                        <Option value="sandbox">Sandbox</Option>
                        <Option value="prd">Production</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Continent"
                    name="continent"  
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select a continent"
                        defaultValue={selectedContinent}
                        onSelect={setSelectedContinent}
                       >
                        <Option value="us">US</Option>
                        <Option value="eu">EU</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Stage"
                    name="stage"  
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select a stage"
                        defaultValue={selectedStage}
                        onSelect={setSelectedStage}
                       >
                        <Option value="pre_process">Pre Process</Option>
                        <Option value="process">Process</Option>
                        <Option value="post_process">Post Process</Option>
                    </Select>
                </Form.Item>
            </Form>
            <Divider></Divider>
            {
                showInvalidWorkItemJson ?
                <Space direction="vertical" style={{ width: '100%' }}>
                <Alert
                    message={<span>Invalid JSON </span> }
                    type="error" 
                    />
                    <pre>
                        
                    </pre>
                </Space> 
                :
                ""
            }
                <AceEditor  
                style={{width: 1000}}
                mode="json"
                theme="monokai"
                wrapEnabled={true}
                value={ editWorkItem ? JSON.stringify(editWorkItem, null, 2) : JSON.stringify({}, null, 2) } 
                onChange={newValue => {
                    try {
                        JSON.parse(newValue);
                        setEditWorkItem(JSON.parse(newValue))
                        setShowInvalidWorkItemJson(false)
                    } catch (e) {
                        setShowInvalidWorkItemJson(true)
                    }
                }}
                name="org_config"
                editorProps={{ $blockScrolling: true }}
                />
            <Divider></Divider>
            {
                    requestNewMoralisTransactionObject ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                        message="It can take a few seconds before the message has been processed"
                        description={<pre>{JSON.stringify(requestNewMoralisTransactionObject, null,2)} </pre> } 
                        type="info" 
                        />
                    </Space> 
                    :
                    ""
                }
        </Create>
    );
};
