import { Refine,    
    AuthBindings,
    Authenticated, } from "@refinedev/core";
import {
    notificationProvider,
    RefineThemes,
    ErrorComponent,
    ThemedLayoutV2,
    ThemedSiderV2,
    ThemedTitleV2
} from "@refinedev/antd";
import dataProvider from "../src/utility";
import routerProvider, {
    NavigateToResource,
    CatchAllNavigate,
} from "@refinedev/react-router-v6";
import { BrowserRouter, Routes, Route, Outlet, useMatch  } from "react-router-dom";
import { newModel, MemoryAdapter, newEnforcer } from "casbin.js";

import {
    SendOutlined,
    SettingOutlined
} from "@ant-design/icons";

import Login from "../src/pages/login/login";
import {Home} from "./pages/home";
import {InternalUsersList, InternalUserShow, InternalUserEdit } from "./pages/internal-users";
import {InternalRolesList, InternalRoleShow, InternalRoleEdit, InternalRoleCreate} from "./pages/internal-roles";
import {ExternalUsersList, ExternalUserShow} from "./pages/external-users";
import {ExternalRolesList, ExternalRoleShow, ExternalRoleEdit} from "./pages/external-roles";
import {ProcessTransactionsJobsList} from "./pages/process-transactions-jobs";
import {WalletStatsList} from "./pages/wallet-stats";
import {RepositoryMgmtList, RepositoryMgmtShow, RepositoryMgmtCreate} from "./pages/repository-mgmt";
import {SecretsMgmtList} from "./pages/secrets-mgmt";
import {SecretsList, SecretsShow, SecretCreate, SecretEdit} from "./pages/secrets";
import {SSMParametersList, SSMParameterShow, SSMParameterCreate, SSMParameterEdit} from "./pages/ssm-parameters";
import {GlobalConfigList, GlobalConfigShow, GlobalConfigEdit} from "./pages/global-configs";
import {ClientConfigList, ClientConfigEdit, ClientConfigShow} from "./pages/client-configs";
import { SettingsShow } from "./pages/settings";
import {TransactionsActivityLogsList, TransactionsActivityLogsShow} from "./pages/transactions-activity-logs";
import {IntegrationsList, IntegrationShow, IntegrationCreate, IntegrationEdit} from "./pages/integrations"; 
import {TriggersList, TriggerShow, TriggerCreate, TriggerEdit} from "./pages/triggers"; 
import {AbisList, AbiShow, AbiCreate, AbiEdit} from "./pages/abis"; 
import {StreamsList, StreamShow, StreamCreate, StreamEdit} from "./pages/streams"; 

import {AuthenticationScopesList} from "./pages/authentication-scopes";

import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import { ConfigProvider, Button } from "antd";
import "@refinedev/antd/dist/reset.css";

import Redirect from "./pages/redirect/redirect";
import { Header } from "../src/components/header/index";

import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { model } from "accessControl";
import { WorkItemsList, WorkItemShow, WorkItemEdit, WorkItemCreate } from "pages/work-items";
import { AwsAccountRequestCreate, AwsAccountRequestList } from "pages/aws-account-request";

let API_URL = process.env.REACT_APP_API_URL || ""
console.log(process.env.REACT_APP_BUILD_TIMESTAMP)
console.log(process.env.REACT_APP_ENV)
console.log(process.env.REACT_APP_API_URL)
console.log("Admin-portal-api-Page")

try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: process.env.REACT_APP_AWS_RUM_GUEST_ROLE_ARN,
      identityPoolId: process.env.REACT_APP_AWS_RUM_IDENTITY_POOL_ID,
      endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
      telemetries: ["performance","errors","http"],
      allowCookies: true,
      enableXRay: false
    };
  
    const APPLICATION_ID: string = process.env.REACT_APP_AWS_RUM_APPLICATION_ID || "";
    const APPLICATION_VERSION: string = '1.0.0';
    const APPLICATION_REGION: string = 'us-west-2';
  
    const awsRum: AwsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
const App: React.FC = () => {
    const { isLoading, user, logout, getIdTokenClaims } = useAuth0();

    if (isLoading) {
        return <span>loading...</span>;
    }
    

    const authProvider: AuthBindings = {
        login: async () => {
            return {
                success: true,
            };
        },
        logout: async () => {
            logout();
            return {
                success: true,
            };
        },
        onError: async (error) => {
            console.error(error);
            return { error };
        },
        check: async () => {
            try {
                const token = await getIdTokenClaims();
                if (token) {
                    console.log("Admin-portal-api-Page User is authenticated")
                    console.log(token)
                    localStorage.setItem('token', JSON.stringify(token));
                    axios.defaults.headers.common = {
                        Authorization: `Bearer ${token.__raw}`,
                    };

                    // hard reset to home
                    // if (window.location.pathname != "/"){
                    //     window.location.href = process.env.REACT_APP_ENV == "dev" ? process.env.REACT_APP_API_BASE_URL: "https://" + process.env.REACT_APP_API_BASE_URL;
                    // }

                    // Sanity check for env and token
                    if(process.env.REACT_APP_ENV == "dev" && !token["http://localhost:3000/org_id"] ){
                        console.log("Env is dev. Need token for DEV")
                        logout();
                    }

                    return {
                        authenticated: true,
                        redirectTo: "/",
                    };

                } else {
                    console.log("Admin-portal-api-Page User is not authenticated")
                    return {
                        authenticated: false,
                        error: {
                            message: "Check failed",
                            name: "Token not found",
                        },
                        redirectTo: "/login/index.html",
                        logout: true,
                    };
                }
            } catch (error: any) {
                console.log("Admin-portal-api-Page User is not authenticated")
                return {
                    authenticated: false,
                    error: new Error(error),
                    redirectTo: "/login/index.html",
                    logout: true,
                };
            }
        },
        getPermissions: async () => null,
        getIdentity: async () => {            
            if (user) {
                console.log(user)
                return {
                    ...user,
                    avatar: user.picture,
                };
            }
            return null;
        },
    };

    return (
        <BrowserRouter>
            <ConfigProvider theme={RefineThemes.Red}>
            <Refine
                    accessControlProvider={{
                        can: async ({ resource, action, params}) => {
                            var permissions =  ``

                            const role_permissions = user?.[process.env.REACT_APP_BASE_URL + '/role_permissions']
                            for (let role_permission of role_permissions) {
                                for (let scope of role_permission.scopes) {
                                    permissions = permissions + "p, " + role_permission.name + ", " + scope.split(":")[0] + ", (" + scope.split(":")[1] + ")" + "\n"
                                }
                            }

                            console.log(permissions)
                            const enforcer = await newEnforcer(model, new MemoryAdapter(permissions));
                            
                            const roles = user?.[process.env.REACT_APP_BASE_URL + '/role_permissions']
                            console.log(resource)
                            var can = false
                            for (let role of roles) {
                                const result:any = await enforcer.enforce(
                                    role.name,
                                    resource,
                                    action,
                                );

                                if(result){
                                    const reason = null
                                    can = result
                                }

                                // can = true // for testing purposes
                            }

                            const reason = can? null : params?.reason
                            return { can, reason: reason , };
                        },
                    }}
                    authProvider={authProvider}
                    dataProvider={dataProvider(API_URL)}
                    routerProvider={routerProvider}
                    resources={[
                        // {
                        //     name: "login",
                        //     list: "/login"
                        // },
                        {
                            name: "internal-user-management",
                            list: "/internal-user-management",
                            show: "/internal-user-management/show/:id",
                            create: "/internal-user-management/create",
                            edit: "/internal-user-management/edit/:id",
                            meta: {
                                canDelete: true,
                                icon: <SettingOutlined />,
                                label: "Internal User Management",
                            },
                        },
                        {
                            name: "internal-users",
                            meta: { 
                                parent: "internal-user-management", 
                                label: "Users",
                            },
                            list: "/internal-users",
                            show: "/internal-users/show/:id",
                            create: "/internal-users/create",
                            edit: "/internal-users/edit/:id",
                        },
                        {
                            name: "internal-roles",
                            meta: { 
                                parent: "internal-user-management", 
                                label: "Roles",
                            },
                            list: "/internal-roles",
                            show: "/internal-roles/show/:id",
                            create: "/internal-roles/create",
                            edit: "/internal-roles/edit/:id",
                        },
                        {
                            name: "external-user-management",
                            list: "/external-user-management",
                            show: "/external-user-management/show/:id",
                            create: "/external-user-management/create",
                            edit: "/external-user-management/edit/:id",
                            meta: {
                                canDelete: true,
                                icon: <SettingOutlined />,
                                label: "External User Management",
                            },
                        },
                        {
                            name: "external-users",
                            meta: { 
                                resource: "users",
                                parent: "external-user-management", 
                                label: "Users",
                            },
                            list: "/external-users",
                            show: "/external-users/show/:id",
                            edit: "/external-users/edit/:id",
                        },
                        {
                            name: "external-roles",
                            meta: { 
                                resource: "roles",
                                parent: "external-user-management", 
                                label: "Roles",
                            },
                            list: "/external-roles",
                            show: "/external-roles/show/:id",
                            edit: "/external-roles/edit/:id",
                        },
                        {
                            name: "jobs-management",
                            list: "/job-management",
                            show: "/job-management/show/:id",
                            create: "/job-management/create",
                            edit: "/job-management/edit/:id",
                            meta: {
                                icon: <SettingOutlined />,
                                label: "Jobs management",
                            },
                        },
                        {
                            name: "process-transactions-jobs",
                            meta: { 
                                parent: "jobs-management", 
                                label: "Process transactions jobs",
                            },
                            list: "/process-transactions-jobs",
                        },
                        {
                            name: "work-items",
                            meta: { 
                                parent: "process-transactions-jobs", 
                                label: "Work Items",
                            },
                            list: "/work-items",
                            show: "/work-items/show/:id",
                            edit: "/work-items/edit/:id",
                            create: "/work-items/create",
                        },
                        {
                            name: "transactions-activity-logs",
                            meta: { 
                                parent: "process-transactions-jobs", 
                                label: "Activity Logs",
                            },
                            list: "/transactions-activity-logs",
                            show: "/transactions-activity-logs/show/:id",
                        },
                        {
                            name: "wallet-stats",
                            list: "/wallet-stats",
                            meta: {
                                icon: <SettingOutlined />,
                                label: "Wallet Stats",
                            },
                        },
                        {
                            name: "status",
                            list: "/status",
                            show: "/status/show/:id",
                            create: "/status/create",
                            edit: "/status/edit/:id",
                            meta: {
                                icon: <SettingOutlined />,
                                label: "Health Status",
                            },
                        },
                        {
                            name: "customer-management",
                            list: "/customer-management",
                            show: "/customer-management/show/:id",
                            create: "/customer-management/create",
                            edit: "/customer-management/edit/:id",
                            meta: {
                                icon: <SettingOutlined />,
                            },
                        },
                        {
                            name: "companies",
                            meta: { 
                                parent: "customer management", 
                                label: "Companies",
                            },
                            list: "/companies",
                        },
                        {
                            name: "trials",
                            meta: { 
                                parent: "customer management", 
                                label: "Trials",
                            },
                            list: "/trials",
                        },
                        {
                            name: "connect-as",
                            meta: { 
                                parent: "customer management", 
                                label: "Connect As",
                            },
                            list: "/connect-as",
                        },
                        {
                            name: "integration-management",
                            list: "/integration-management",
                            show: "/integration-management/show/:id",
                            create: "/integration-management/create",
                            edit: "/integration-management/edit/:id",
                            meta: {
                                icon: <SettingOutlined />,
                            },
                        },                      
                        {
                            name: "repositories",
                            list: "/repositories",
                            show: "/repositories/show/:id",
                            create: "/repositories/create",
                            meta: {
                                icon: <SettingOutlined />,
                                label: "Repository Management"
                            },
                        },
                        {
                            name: "aws-account-management",
                            list: "/aws-account-management",
                            create: "/aws-account-management/create",
                            meta: {
                                icon: <SettingOutlined />,
                                label: "AWS Account Management"
                            },
                        },
                        {
                            name: "secrets-management",
                            list: "/secrets-management",
                            show: "/secrets-management/show/:id",
                            create: "/secrets-management/create",
                            edit: "/secrets-management/edit/:id",
                            meta: {
                                icon: <SettingOutlined />,
                            },
                        },
                        {
                            name: "secrets",
                            meta: { 
                                resource: "secrets",
                                parent: "secrets-management", 
                                label: "Secrets",
                            },
                            list: "/secrets",
                            show: "/secrets/show/:id",
                            create: "/secrets/create/:id",
                            edit: "/secrets/edit/:id",
                        },
                        {
                            name: "ssm-parameters",
                            meta: { 
                                resource: "ssm-parameters",
                                parent: "secrets-management", 
                                label: "SSM Parameters",
                            },
                            list: "/ssm-parameters",
                            show: "/ssm-parameters/show/:id",
                            create: "/ssm-parameters/create/:id",
                            edit: "/ssm-parameters/edit/:id",
                        },
                        {
                            name: "config-management",
                            list: "/config-management",
                            meta: {
                                icon: <SettingOutlined />,
                            },
                        },
                        {
                            name: "global-configs",
                            meta: { 
                                resource: "global-configs",
                                parent: "config-management", 
                                label: "Global Config",
                            },
                            list: "/global-configs",
                            show: "/global-configs/show/:id",
                            edit: "/global-configs/edit/:id",
                        },
                        {
                            name: "client-configs",
                            meta: { 
                                resource: "client-configs",
                                parent: "config-management", 
                                label: "Client Configs",
                            },
                            list: "/client-configs",
                            show: "/client-configs/show/:id",
                            edit: "/client-configs/edit/:id",
                        },
                        {
                            name: "release-management",
                            list: "/release-management",
                            show: "/release-management/show/:id",
                            create: "/release-management/create",
                            edit: "/release-management/edit/:id",
                            meta: {
                                icon: <SettingOutlined />,
                            },
                        },
                        {
                            name: "integrations",
                            list: "/integrations",
                            show: "/integrations/show/:id",
                            create: "/integrations/create",
                            edit: "/integrations/edit/:id",
                            meta: {
                                icon: <SettingOutlined />,
                            },
                        },
                        {
                            name: "triggers",
                            list: "/triggers",
                            show: "/triggers/show/:id",
                            create: "/triggers/create",
                            edit: "/triggers/edit/:id",
                            meta: {
                                icon: <SettingOutlined />,
                                label: "Triggers",
                            },
                        },         
                        {
                            name: "abis",
                            list: "/abis",
                            show: "/abis/show/:id",
                            create: "/abis/create",
                            edit: "/abis/edit/:id",
                            meta: {
                                icon: <SettingOutlined />,
                                label: "Blockchain Abis",
                            },
                        },           
                        {
                            name: "streams",
                            list: "/streams",
                            show: "/streams/show/:id",
                            create: "/streams/create",
                            edit: "/streams/edit/:id",
                            meta: {
                                icon: <SettingOutlined />,
                                label: "Streams",
                            },
                        },                                                           
                        {
                            name: "settings",
                            list: "/settings",
                            show: "/settings/show/:id",
                            meta: {
                                icon: <SettingOutlined />,
                            },
                        },
                        {
                            name: "authentication-scopes",
                            list: "/authentication-scopes",
                            meta: {
                                icon: <SettingOutlined />,
                                label: "Authentication Scopes",
                            },
                        },                        
                    ]}
                    notificationProvider={notificationProvider}
                    options={{
                        syncWithLocation: true,
                        warnWhenUnsavedChanges: true,
                    }}
                >
                <Routes>
                    <Route
                        element={
                            <Authenticated key="login1" fallback={<Outlet />}>
                                <NavigateToResource resource="login" />
                                <ThemedLayoutV2 ></ThemedLayoutV2>
                            </Authenticated>
                        }
                    >
                        <Route
                            path="/login"
                            element={<Login/>}
                        />
                    </Route>
                    <Route
                        element={
                            <Authenticated key="login2" redirectOnFail="/login">
                                <ThemedLayoutV2 Sider={() => <ThemedSiderV2 fixed Title={({ collapsed }) => (
                                    <ThemedTitleV2 collapsed={false} text="Admin Portal"/>
                                        )}/>} initialSiderCollapsed={false} Header={() => <Header sticky />}
                                                Title={({ collapsed }) => (
                                            <ThemedTitleV2 
                                                // collapsed is a boolean value that indicates whether the <Sidebar> is collapsed or not
                                                collapsed={collapsed}
                                                text="Authe"
                                            />
                                        )}
                                        OffLayoutArea={() => (
                                            <Button
                                                type="primary"
                                                size="small"
                                                onClick={() =>
                                                    alert("Off layout are clicked")
                                                }
                                                style={{
                                                    position: "fixed",
                                                    left: "8px",
                                                    bottom: "8px",
                                                    zIndex: 1000,
                                                }}
                                            >
                                                Send us Feedback 👋
                                            </Button>
                                        )}
                                    >
                                    <Outlet />
                                </ThemedLayoutV2>
                            </Authenticated>
                        }
                    >
                        <Route path="/">
                            <Route index element={<Home />} />
                            <Route path="/" element={<Home/>} />
                        </Route>
                        <Route path="/internal-users">
                            <Route index element={<InternalUsersList />} />
                            <Route path="show/:id" element={<InternalUserShow />} />
                            <Route path="edit/:id" element={<InternalUserEdit />} />
                            <Route path="*" element={<InternalUsersList/>} />
                        </Route>
                        <Route path="/internal-roles">
                            <Route index element={<InternalRolesList />} />
                            <Route path="show/:id" element={<InternalRoleShow />} />
                            <Route path="edit/:id" element={<InternalRoleEdit />} />
                            <Route path="create" element={<InternalRoleCreate />} />
                            <Route path="*" element={<InternalRolesList/>} />
                        </Route>
                        <Route path="/external-users">
                            <Route index element={<ExternalUsersList />} />
                            <Route path="show/:id" element={<ExternalUserShow />} /> 
                        </Route>
                        <Route path="/external-roles">
                            <Route index element={<ExternalRolesList />} />
                            <Route path="show/:id" element={<ExternalRoleShow />} /> 
                            <Route path="edit/:id" element={<ExternalRoleEdit />} />                             
                        </Route>
                        <Route path="/process-transactions-jobs">
                            <Route index element={<ProcessTransactionsJobsList />} />
                            {/* <Route path="show/:id" element={<ExternalRoleShow />} />  */}
                        </Route>   
                        <Route path="/work-items">
                            <Route index element={<WorkItemsList />} />
                            <Route path="show/:id" element={<WorkItemShow />} />
                            <Route path="edit/:id" element={<WorkItemEdit />} />
                            <Route path="create" element={<WorkItemCreate />} />
                        </Route>       
                        <Route path="/transactions-activity-logs">
                            <Route index element={<TransactionsActivityLogsList />} />
                            <Route path="show/:id" element={<TransactionsActivityLogsShow />} />
                        </Route>                    
                        <Route path="/wallet-stats">
                            <Route index element={<WalletStatsList />} />
                            <Route path="wallet-stats/index.html" element={<WalletStatsList />} />
                            <Route path="*" element={<WalletStatsList/>} />
                        </Route>
                        
                        <Route path="/status">
                            <Route index element={<InternalRolesList />} />
                            <Route path="status/index.html" element={<InternalRolesList />} />
                            <Route path="*" element={<InternalRolesList/>} />
                        </Route>
                        <Route path="/repositories">
                            <Route index element={<RepositoryMgmtList />} />
                            <Route path="create" element={<RepositoryMgmtCreate />} />
                            <Route path="status/index.html" element={<RepositoryMgmtList />} />
                            <Route path="*" element={<RepositoryMgmtList/>} />
                        </Route>
                        <Route path="/aws-account-management">
                            <Route index element={<AwsAccountRequestList />} />
                            <Route path="create" element={<AwsAccountRequestCreate />} />
                            <Route path="*" element={<AwsAccountRequestList/>} />
                        </Route>
                        <Route path="/secrets-management">
                            <Route index element={<SecretsMgmtList />} />
                            <Route path="secrets-management/index.html" element={<SecretsMgmtList />} />
                            <Route path="*" element={<SecretsMgmtList/>} />
                        </Route>
                        <Route path="/secrets">
                            <Route index element={<SecretsList />} />
                            <Route path="show/:id" element={<SecretsShow />} />
                            <Route path="edit/:id" element={<SecretEdit />} />
                            <Route path="create/:id" element={<SecretCreate />} />
                            <Route path="secrets/index.html" element={<SecretsList />} />
                            <Route path="*" element={<SecretsList/>} />
                        </Route>
                        <Route path="/ssm-parameters">
                            <Route index element={<SSMParametersList />} />
                            <Route path="show/:id" element={<SSMParameterShow />} />
                            <Route path="edit/:id" element={<SSMParameterEdit />} />
                            <Route path="create/:id" element={<SSMParameterCreate />} />
                            <Route path="ssm-parameters/index.html" element={<SSMParametersList />} />
                            <Route path="*" element={<SSMParametersList/>} />
                        </Route>
                        <Route path="/global-configs">
                            <Route index element={<GlobalConfigList />} />
                            <Route path="show/:id" element={<GlobalConfigShow />} />
                            <Route path="edit/:id" element={<GlobalConfigEdit />} />
                            <Route path="global-configs/index.html" element={<GlobalConfigList />} />
                            <Route path="*" element={<GlobalConfigList/>} />
                        </Route>
                        <Route path="/client-configs">
                            <Route index element={<ClientConfigList />} />
                            <Route path="show/:id" element={< ClientConfigShow/>} />
                            <Route path="edit/:id" element={<ClientConfigEdit />} />
                            <Route path="client-configs/index.html" element={<ClientConfigList />} />
                            <Route path="*" element={<ClientConfigList/>} />
                        </Route>
                        <Route path="/integrations">
                            <Route index element={<IntegrationsList />} />
                            <Route path="show/:id" element={< IntegrationShow/>} />
                            <Route path="create" element={<IntegrationCreate />} />
                            <Route path="edit/:id" element={<IntegrationEdit />} />
                            <Route path="integrations/index.html" element={<IntegrationsList />} />
                            <Route path="*" element={<IntegrationsList/>} />
                        </Route>
                        <Route path="/triggers">
                            <Route index element={<TriggersList />} />
                            <Route path="show/:id" element={< TriggerShow/>} />
                            <Route path="create" element={<TriggerCreate />} />
                            <Route path="edit/:id" element={<TriggerEdit />} />
                            <Route path="triggers/index.html" element={<TriggersList />} />
                            <Route path="*" element={<TriggersList/>} />
                        </Route>         
                        <Route path="/abis">
                            <Route index element={<AbisList />} />
                            <Route path="show/:id" element={< AbiShow/>} />
                            <Route path="create" element={<AbiCreate />} />
                            <Route path="edit/:id" element={<AbiEdit />} />
                            <Route path="abis/index.html" element={<AbisList />} />
                            <Route path="*" element={<AbisList/>} />
                        </Route>   
                        <Route path="/streams">
                            <Route index element={<StreamsList />} />
                            <Route path="show/:id" element={< StreamShow/>} />
                            <Route path="create" element={<StreamCreate />} />
                            <Route path="edit/:id" element={<StreamEdit />} />
                            <Route path="streams/index.html" element={<StreamsList />} />
                            <Route path="*" element={<StreamsList/>} />
                        </Route>                                                                
                        <Route path="/authentication-scopes">
                            <Route index element={<AuthenticationScopesList />} />
                            <Route path="authentication-scopes/index.html" element={<AuthenticationScopesList />} />
                            <Route path="*" element={<AuthenticationScopesList/>} />
                        </Route>                        
                        <Route path="/settings">
                            <Route index element={<SettingsShow />} />
                        </Route>
                    </Route>
                    <Route path="*" element={<ErrorComponent />} />
                </Routes>
                </Refine>
            </ConfigProvider>
        </BrowserRouter>
    );
};

export default App;
