import React from "react";
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";

import App from "./App";

const container = document.getElementById("root");
// eslint-disable-next-line
const root = createRoot(container!);

const domain = process.env.REACT_APP_AUTH0_DOMAIN || ""
const client_id = process.env.REACT_APP_AUTH0_CLIENT_ID || ""
const audience = process.env.REACT_APP_API_URL || ""
const base_url = process.env.REACT_APP_ENV == "dev" ? process.env.REACT_APP_BASE_URL : "https://admin-portal." + process.env.REACT_APP_API_BASE_URL

root.render(
    <React.StrictMode>
            <Auth0Provider
        domain={domain}
        clientId={client_id}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        authorizationParams={{
            redirect_uri: "admin-portal." + domain, //window.location.origin,
            audience: audience,
            tenant_id: localStorage.getItem('tenant_id') ? localStorage.getItem('tenant_id') : "root_tenant"
        }}  
      >
        <App />
        </Auth0Provider>
    </React.StrictMode>
    ,
);
