import React from "react";
import { IResourceComponentsProps, useNotification } from "@refinedev/core";

import { Edit, ListButton, RefreshButton, SaveButton, TagField, useForm } from "@refinedev/antd";

import { Alert, Checkbox, Divider, Form, Input, Select, Space, Spin, Tooltip, Typography } from "antd";
import axios from 'axios';
const { Title, Text } = Typography;
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';


import { IWorkItem } from "interfaces";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
export const WorkItemEdit: React.FC<IResourceComponentsProps> = () => {
      
    const navigate = useNavigate();
    const params= useParams()
    

    const { formLoading ,formProps, saveButtonProps, onFinish, mutationResult, queryResult } = useForm<IWorkItem>(
        {
            resource: "work-items",
            action: "edit",
            id: params.id,
            redirect: false
        }
    );
    const record = queryResult?.data?.data;

    const { open } = useNotification();

    const { Option } = Select;

    
    let [showInvalidWorkItemJson, setShowInvalidWorkItemJson] = React.useState<boolean>(false)
   
    let [editWorkItem, setEditWorkItem] = React.useState<IWorkItem | null>(null)
    
    let [isLoadingNewWorkItem, setIsLoadingWorkItem] = React.useState<boolean>(false)


    const refreshTableResults = () => {     
        queryResult?.refetch()
        setEditWorkItem(null)
    };

    async function handleOnClickSaveButton(action: string) {

        setIsLoadingWorkItem(true)
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
        const domain = process.env.REACT_APP_API_BASE_URL
        const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
        const qa_environment = qa_config["environment"]
        const blue_config = qa_config["config"]

        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "admin-portal",
            "Source-Region": "us-west-2",
            "Destination-Region": "us-west-2",
        };

        if(action != "new_version" && record){
            editWorkItem = record
            editWorkItem.status = action
            setEditWorkItem(editWorkItem)
        }

        const apiUrl = "https://" + continent.toLowerCase() + ".api." + domain 
        console.log("Sending the request")
        let url
        if (qa_environment == "blue"){
            url = `${apiUrl}/internal/tools/process-transactions-mgmt-debug-tool/blue/ANY/${continent.toUpperCase()}/v0/work-items/${params.id}`
        }  
        else{
            url = `${apiUrl}/internal/tools/process-transactions-mgmt-debug-tool/ANY/${continent.toUpperCase()}/v0/work-items/${params.id}`
        }
        console.log(url)

        if(editWorkItem == undefined){
            console.log("Nothing to update")
            open?.({
                type: "success",
                message: "Nothing to update",
                key: "work-items",
            });
        } 
        else{
            try {
                console.log(editWorkItem)
                const { data, status } = await axios.put(
                    url,
                    JSON.stringify(editWorkItem), {
                        headers: requestHeaders
                    }
                )
                console.log(data)
                console.log(status)
                let new_message = "success"
                let new_description = "Updated work item"
                    
                if (data.ok){
                    open?.({
                        type: "success",
                        message: new_message,
                        description: new_description,
                        key: "work-items",
                    });
                } else{
                    open?.({
                        type: "error",
                        message: "There was an error",
                        description: data.error?.message,
                        key: "work-items",
                    });        
                }
            } catch (error: any) {
                console.log(error.response); 
                setIsLoadingWorkItem(false)
                
                return error.response;
                
            }       
        }

        setIsLoadingWorkItem(false)
    }
    
    let colors = {
        "us-west-2": "green",
        "us-east-1": "lightgreen",
        "eu-central-1": "blue",
        "eu-west-1": "lightblue"
    }

    return (
        <Edit isLoading={formLoading} saveButtonProps={saveButtonProps} resource="work-items" headerButtons={({ listButtonProps, refreshButtonProps }) => (
            <>
              {listButtonProps && <ListButton {...listButtonProps} meta={{ foo: "bar" }} />}
              <RefreshButton {...refreshButtonProps} disabled={queryResult?.isLoading || queryResult?.isFetching } loading={queryResult?.isLoading || queryResult?.isFetching } onClick={refreshTableResults} />
            </>
          )} 

            
    //   wrapperProps={{
    //     style: {
    //       backgroundColor: "orange",
    //       padding: "16px",
    //     },
    //   }}
    footerButtonProps={{
        style: {
            float: "left",
            marginLeft: "24px"
        },
    }}
      footerButtons={({ saveButtonProps }) => (
        
        <>
          <SaveButton {...saveButtonProps} onClick={(e:any) => handleOnClickSaveButton("new_version")} loading={isLoadingNewWorkItem} >Save</SaveButton>
          <SaveButton {...saveButtonProps} type="dashed" onClick={(e:any) => handleOnClickSaveButton("to_be_deleted")} loading={isLoadingNewWorkItem} >Set To Delete Status</SaveButton>
          <SaveButton {...saveButtonProps} type="dashed" onClick={(e:any) => handleOnClickSaveButton("ready_to_be_processed")} loading={isLoadingNewWorkItem} >Set To process Status</SaveButton>

        </>
        
      )}
      >
            {queryResult?.isLoading || queryResult?.isFetching  ? <Spin size="large" /> : null}
            <Form {...formProps} layout="vertical">
                <Title level={5}>Work Item</Title>
                <Text>{record?.lock_value}</Text>
                <Title level={5}>Environment</Title>
                <Text>{record?.environment}</Text>
                <Title level={5}>BlueGreen</Title>
                <TagField color={record?.is_blue ? "blue" : "green"} value={record?.is_blue ? "blue" : "green"}></TagField>
                <Title level={5}>Region</Title>
                <TagField color={colors[record?.region as keyof typeof colors ]} value={record?.region} ></TagField>
                <Title level={5}>Table</Title>
                <Text>{record?.table.split('process-transactions-mgmt-')}</Text>
                <Divider></Divider>

            </Form>

            <Divider></Divider>

            {
                showInvalidWorkItemJson ?
                <Space direction="vertical" style={{ width: '100%' }}>
                <Alert
                    message={<span>Invalid JSON </span> }
                    type="error" 
                    />
                    <pre>
                        
                    </pre>
                </Space> 
                :
                ""
            }
                <AceEditor  
                style={{width: 1000}}
                mode="json"
                theme="monokai"
                wrapEnabled={true}
                value={ editWorkItem ? JSON.stringify(editWorkItem, null, 2) : JSON.stringify(record, null, 2) } 
                onChange={newValue => {
                    try {
                        JSON.parse(newValue);
                        setEditWorkItem(JSON.parse(newValue))
                        setShowInvalidWorkItemJson(false)
                    } catch (e) {
                        setShowInvalidWorkItemJson(true)
                    }
                }}
                name="org_config"
                editorProps={{ $blockScrolling: true }}
                />
            <Divider></Divider>


                <Divider></Divider>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                    message="Attention"
                    description={
                        <p>
                          Work items are stored in Dynamodb.
                          <br />
                          Work items are per environment and per continent.
                          <br />
                        </p>
                      }
                    type="info" 
                    />

                </Space>
        </Edit>
        
    );
};

