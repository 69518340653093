import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { Alert, Avatar, Button, Divider, List, Select, Skeleton, Space, Tag, Tooltip, Typography } from 'antd';
import { DeleteButton, EditButton, Show, TagField } from '@refinedev/antd';
import { useAuth0 } from '@auth0/auth0-react';
import { ITrigger } from 'interfaces';

const { Title, Text } = Typography;

import InfiniteScroll from 'react-infinite-scroll-component';

export const TriggerShow: React.FC<IResourceComponentsProps> = () => {

    const {
        user,
      } = useAuth0();

      const { queryResult } = useShow<ITrigger>();
      const { data, isLoading } = queryResult;
      const record = data?.data;
      const { Option } = Select;

    let colors = {
        "us-west-2": "green",
        "us-east-1": "lightgreen",
        "eu-central-1": "blue",
        "eu-west-1": "lightblue"
    }

    const loadMoreData = () => {

    }

    return (
        <Show isLoading={queryResult?.isLoading || queryResult?.isFetching }>
            <Title level={5}>Trigger Environment</Title>
            <TagField value={record?.environment}></TagField>
            <Title level={5}>Trigger Continent</Title>
            <TagField color={colors[record?.continent as keyof typeof colors ]} value={record?.continent}></TagField>
            <Title level={5}>Trigger Org Id</Title>
            <Text>{record?.org_id}</Text>
            <Title level={5}>Trigger Tenant Id</Title>
            <Text>{record?.tenant_id}</Text>
            <Title level={5}>Trigger User Id</Title>
            <Text>{record?.created_by}</Text>
            <Title level={5}>Trigger Id</Title>
            <Text>{record?.id}</Text>
            <Title level={5}>Trigger Name</Title>
            <Text>{record?.name}</Text>
            <Title level={5}>Trigger Description</Title>
            <Text>{record?.description}</Text>            
            <Title level={5}>Trigger Status</Title>
            <Text>{record?.status}</Text>
            <Title level={5}>Trigger type</Title>
            <Text>{record?.trigger_type}</Text>
            <Title level={5}>Trigger subtype</Title>
            <Text>{record?.trigger_subtype}</Text>            
            <Title level={5}>Trigger Lambda arn</Title>
            <Text>{record?.lambda_arn}</Text>               
            <Title level={5}>Trigger Stages</Title>
            <Text></Text>
            <Select
                mode="multiple"
                style={{ width: '100%' }}
                disabled={true}
                value={record?.trigger_stages}
            />
            <Divider></Divider>
            <Title level={5}>Triggered on</Title>
            <Text>
            <List
                itemLayout="horizontal"
                dataSource={record?.trigger_on_chain_id_addresses}
                renderItem={(item: string, index) => (
                <List.Item>
                    <List.Item.Meta
                        avatar={<Avatar src={`https://cdn.${process.env.REACT_APP_API_BASE_URL}/public/GET/cdn/blockchain/logos/svg/${item.split("_")[0]}.svg`} />}

                        // avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                        title={item.split("_")[0]}
                        description={item.split("_")[1]}
                    />
                </List.Item>
                )}
            />
            </Text>     
        </Show>
    );
};

