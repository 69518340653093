import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { Alert, Divider, List, Space, Tag, Typography } from 'antd';
import { Show, TagField } from '@refinedev/antd';
import { useAuth0 } from '@auth0/auth0-react';
import { IClientConfig } from 'interfaces';

const { Title, Text } = Typography;

import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';

export const ClientConfigShow: React.FC<IResourceComponentsProps> = () => {

    const {
        user,
      } = useAuth0();

      const { queryResult } = useShow<IClientConfig>();
      const { data, isLoading } = queryResult;
      const record = data?.data;
            
    let colors = {
        "us-west-2": "green",
        "us-east-1": "lightgreen",
        "eu-central-1": "blue",
        "eu-west-1": "lightblue"
    }


    return (
        <Show isLoading={queryResult?.isLoading || queryResult?.isFetching }>
            <Title level={5}>Config Environment</Title>
            <TagField value={record?.org_config.environment}></TagField>
            <Title level={5}>Config Region</Title>
            <TagField color={colors[record?.org_config.region as keyof typeof colors ]} value={record?.org_config.region}></TagField>
            <Title level={5}>Org Id</Title>
            <Text>{record?.org_config.org_id}</Text>
            <Title level={5}>Tenant Id</Title>
            <Text>{record?.org_config.tenant_id}</Text>
            <Title level={5}>User Id</Title>
            <Text>{record?.org_config.user_id}</Text>
            <Divider></Divider>

            <Title level={5}>Org Config</Title>
            {
                record?.org_config?.value ? 
                <AceEditor  
                style={{width: 1000}}
                mode="json"
                theme="monokai"
                wrapEnabled={true}
                value={ record ? JSON.stringify(record?.org_config?.value, null, 2) : '{}' } 
                readOnly={true}
                name="UNIQUE_ID_OF_DIV"
                editorProps={{ $blockScrolling: true }}
                />
                :
                "No Org config"
            }
            <Divider></Divider>
            <Title level={5}>Tenant Config</Title>
            {
                record?.tenant_config?.value ? 
                <AceEditor  
                style={{width: 1000}}
                mode="json"
                theme="monokai"
                wrapEnabled={true}
                value={ record ? JSON.stringify(record?.tenant_config?.value, null, 2) : '{}' } 
                readOnly={true}
                name="UNIQUE_ID_OF_DIV"
                editorProps={{ $blockScrolling: true }}
                />
                :
                "No Tenant config"
            }

            <Divider></Divider>
            <Title level={5}>User Config</Title>
            {
                record?.user_config?.value ? 
                <AceEditor  
                style={{width: 1000}}
                mode="json"
                theme="monokai"
                wrapEnabled={true}
                value={ record ? JSON.stringify(record?.user_config?.value, null, 2) : '{}' } 
                readOnly={true}
                name="UNIQUE_ID_OF_DIV"
                editorProps={{ $blockScrolling: true }}
                />
                :
                "No User config"
            }
        </Show>
    );
};

