import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    EditButton,
    ShowButton,
    TextField,
    DeleteButton,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space, Typography, Tooltip, Divider, Button, Alert } from "antd";

import { IGlobalConfig } from "interfaces";
import { useAuth0 } from '@auth0/auth0-react';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
const { Title, Text } = Typography;

export const GlobalConfigList: React.FC<IResourceComponentsProps> = () => {
    const CheckboxGroup = Checkbox.Group;

      
      const { tableProps, tableQueryResult } = useTable<IGlobalConfig>({
        resource: "app-configs",
    });



    return (

        <List >
            {
                tableQueryResult?.data?.summary.summary.time_needed_in_seconds ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                    message={"Time taken to load the results: " + tableQueryResult?.data?.summary.summary.time_needed_in_seconds + " seconds"}
                    type="success"

                    />
                </Space> :
                ""
            }      
            <Divider />  

            <Table {...tableProps} rowKey="arn" dataSource={tableQueryResult?.data?.data }
                        pagination={{
                            defaultPageSize:500,
                            showSizeChanger:true,
                        }}
            >
                <Table.Column<IGlobalConfig>
                    sorter={ (a: any, b: any) => a.id - b.id}
                    title="Id" 
                    dataIndex="id" 
                    render={(_: any, record: { id: any; }) => {
                        return (
                            <Tooltip title={record?.id}>
                            <TagField
                                value={`${
                                    record?.id //.substring(0,2)
                                }` }
                            />
                            </Tooltip>
                        );
                    }}
                />
                <Table.Column<IGlobalConfig>
                    sorter={ (a: any, b: any) => a.application_name - b.application_name}
                    title="App Config Application name" 
                    dataIndex="application_name" 
                    render={(_: any, record: { application_name: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.application_name
                                }` }
                            />
                        );
                    }}
                />
                <Table.Column<IGlobalConfig>
                    sorter={ (a: any, b: any) => a.application_description - b.application_description}
                    title="App Config Application Description" 
                    dataIndex="application_description" 
                    render={(_: any, record: { application_description: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.application_description
                                }` }
                            />
                        );
                    }}
                />
                <Table.Column<IGlobalConfig>
                    sorter={ (a: any, b: any) => a.environment - b.environment}
                    title="Environment" 
                    dataIndex="environment" 
                    render={(_: any, record: { environment: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.environment
                                }` }
                            />
                        );
                    }}
                />
                <Table.Column<IGlobalConfig>
                    sorter={ (a: any, b: any) => a.region - b.region}
                    title="Region" 
                    dataIndex="region" 
                    render={(_: any, record: { region: any; }) => {
                        let colors = {
                            "us-west-2": "green",
                            "us-east-1": "lightgreen",
                            "eu-central-1": "blue",
                            "eu-west-1": "lightblue"
                        }
                        return (
                            <TagField
                                color={colors[record.region as keyof typeof colors ]}
                                value={`${
                                    record?.region
                                }` }
                            />
                        );
                    }}
                />
            <Table.Column<IGlobalConfig>
                    sorter={ (a: any, b: any) => a.deployment.DeploymentNumber - b.deployment.DeploymentNumber}
                    title="Deployment Number" 
                    dataIndex="deployment" 
                    render={(_: any, record: { deployment: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.deployment.DeploymentNumber
                                }` }
                            />
                        );
                    }}
                />
            <Table.Column<IGlobalConfig>
                    sorter={ (a: any, b: any) => a.deployment.ConfigurationVersion - b.deployment.ConfigurationVersion}
                    title="Deployment ConfigurationVersion" 
                    dataIndex="deployment" 
                    render={(_: any, record: { deployment: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.deployment.ConfigurationVersion
                                }` }
                            />
                        );
                    }}
                />
            <Table.Column<IGlobalConfig>
                    sorter={ (a: any, b: any) => a.is_latest_deployment - b.is_latest_deployment}
                    title="Is Latest Deployment " 
                    dataIndex="is_latest_deployment" 
                    render={(_: any, record: { is_latest_deployment: any; }) => {
                        return (
                            <TagField
                                color={record?.is_latest_deployment == true ? "green" : ""}
                                value={`${
                                    record?.is_latest_deployment
                                }` }
                            />
                        );
                    }}
                />                
            <Table.Column<IGlobalConfig>
                    sorter={ (a: any, b: any) => a.deployment.StartedAt - b.deployment.StartedAt}
                    title="Deployment StartedAt" 
                    dataIndex="deployment" 
                    render={(_: any, record: { deployment: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.deployment.StartedAt
                                }` }
                            />
                        );
                    }}
                />
            <Table.Column<IGlobalConfig>
                    sorter={ (a: any, b: any) => a.deployment.CompletedAt - b.deployment.CompletedAt}
                    title="Deployment CompletedAt" 
                    dataIndex="deployment" 
                    render={(_: any, record: { deployment: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.deployment.CompletedAt
                                }` }
                            />
                        );
                    }}
                />
                <Table.Column<IGlobalConfig>
                    title="Actions"
                    dataIndex="id"
                    render={(_: any, record: { id: any; }) => (
                        <Space>
                            {/* <ShowButton
                                size="small"
                                recordItemId= {record.id}
                            /> */}
                            <EditButton
                                size="small"
                                recordItemId= {record.id}
                            />
                        </Space>
                    )}
                />  
            </Table>
        </List>
    );
};

