import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { Alert, Divider, List, Space, Tag, Typography } from 'antd';
import { Show, TagField } from '@refinedev/antd';
import { useAuth0 } from '@auth0/auth0-react';
import { IWorkItem } from 'interfaces';

const { Title, Text } = Typography;

import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';


export const WorkItemShow: React.FC<IResourceComponentsProps> = () => {

    const {
        user,
      } = useAuth0();

      const { queryResult } = useShow<IWorkItem>();
      const { data, isLoading } = queryResult;
      const record = data?.data;
            
    let colors = {
        "us-west-2": "green",
        "us-east-1": "lightgreen",
        "eu-central-1": "blue",
        "eu-west-1": "lightblue"
    }


    return (
        <Show isLoading={queryResult?.isLoading || queryResult?.isFetching }>
            <Title level={5}>Work Item</Title>
            <Text>{record?.lock_value}</Text>
            <Title level={5}>Environment</Title>
            <Text>{record?.env}</Text>
            <Title level={5}>Region</Title>
            <Text>
            <TagField color={colors[record?.region as keyof typeof colors ]} value={record?.region} ></TagField>
            </Text>
            <Title level={5}>Table</Title>
            <Text>{record?.table.split('process-transactions-mgmt-')}</Text>
            <Divider></Divider>
            <AceEditor  
            style={{width: 1000}}
            mode="json"
            theme="monokai"
            wrapEnabled={true}
            value={ record ? JSON.stringify(record, null, 2) : '{}' } 
            readOnly={true}
            name="UNIQUE_ID_OF_DIV"
            editorProps={{ $blockScrolling: true }}
            />

          
        </Show>
    );
};

