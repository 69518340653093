import React, { useMemo, useRef, useState } from "react";
import { IResourceComponentsProps } from "@refinedev/core";

import { Edit, useForm } from "@refinedev/antd";

import { Checkbox, Form, Input, Select, SelectProps, Spin, Tag, Tooltip, Typography } from "antd";
const { Text, Title } = Typography;

import { IUser } from "interfaces";
import { useAuth0 } from "@auth0/auth0-react";

import debounce from 'lodash/debounce';


export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

export const InternalUserEdit: React.FC<IResourceComponentsProps> = () => {
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    const { formProps, saveButtonProps, queryResult } = useForm<IUser>();

    const postData = queryResult?.data?.data;

    const { Option } = Select;
    
    function DebounceSelect<
        ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
        >({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectProps<ValueType>) {
        const [fetching, setFetching] = useState(false);
        const [options, setOptions] = useState<ValueType[]>([]);
        const fetchRef = useRef(0);

        const debounceFetcher = useMemo(() => {
            const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                // for fetch callback order
                return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
            };

            return debounce(loadOptions, debounceTimeout);
        }, [fetchOptions, debounceTimeout]);

        return (
            <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
            />
        );
    }
    
    // Usage of DebounceSelect
    interface RoleValue {
        label: string;
        value: string;
    }

    async function fetchUserList(username: string) {
        console.log('fetching role', username);
      
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"]
        const domain = process.env.REACT_APP_API_BASE_URL
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"]
    
        const url = "https://" + continent.toLowerCase() + ".api." + domain + "/management/permissions/GET/" + continent.toUpperCase() + "/v0/organizations/" + org_id + "/roles"

        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "admin-portal",
            "Source-Region": "us-west-2",
            "Destination-Region": "us-west-2",
        };

        return fetch(url, {headers: requestHeaders})
          .then((response) => 
            response.json() 
          )
          .then((body) =>
            body.result.map(
              (role: { role_name: string; id : string }) => ({
                label: role.role_name,
                value: role.id,
              }),
            ),
          );
      }
    
    const [value, setValue] = useState<RoleValue[]>([]);

    return (
        <Edit saveButtonProps={{ ...saveButtonProps }}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Id"
                    name="id"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Name"
                    name="name"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Org Id"
                    name="org_id"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Tenant Id"
                    name="tenant_id"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Status"
                    name="status"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select a status">
                        <Option value="Enabled">Enabled</Option>
                        <Option value="Disabled">Disabled</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Current Role(s) in all application"
                    name="roles"   
                    initialValue={postData?.roles}                      
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    {
                        <Tooltip title="Which role(s) you have in all applications">
                                    <Text> 
                                    <Text   >
                                          {
                                                postData?.roles.map((row: any)=>{
                                                  return <Tag color={"geekblue"} key={row}>{row}</Tag>
                                              })
                                          }
                                      </Text>
                                  </Text>
                        </Tooltip>
                    }
                </Form.Item>        

                <Form.Item
                    label="Available Roles"
                    name="roles"   
                    initialValue={postData?.roles}                      
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                <DebounceSelect
                    mode="multiple"
                    value={value}
                    placeholder="Select Roles"
                    fetchOptions={fetchUserList}
                    onChange={(newValue) => {
                        setValue(newValue as RoleValue[]);
                    }}
                    style={{ width: '100%' }}
                    />
                </Form.Item>     
            </Form>
        </Edit>
    );
};
