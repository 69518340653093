import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps } from "@refinedev/core";
import { Alert, List, Space, Typography } from 'antd';
import { Show } from '@refinedev/antd';
import { useAuth0 } from '@auth0/auth0-react';

const { Title, Text } = Typography;


export const SSMParameterShow: React.FC<IResourceComponentsProps> = () => {

    const {
        user,
      } = useAuth0();

    return (
        <Show isLoading={false}>
            {
                user?.[process.env.REACT_APP_BASE_URL + '/role_permissions']?.length == 0 ? 
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <Alert
                        message="Error No User Roles found"
                        description="Error: No User Roles found"
                        type="error"
                        />
                    </Space> :
                    ""
            }
            <Title level={5}>Transaction processing jobs</Title>
            <Text>...</Text>
        </Show>
    );
};

