import React, { useEffect, useState } from 'react';
import { HttpError, IResourceComponentsProps, useNotification, useOne, useShow } from "@refinedev/core";
import { Alert, Button, Divider, List, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { Show, ShowButton, TagField, useTable } from '@refinedev/antd';
import { useAuth0 } from '@auth0/auth0-react';
import { ITransactionsActivityLog, ITransactionsActivityLogIncludeItem, IWalletStat } from 'interfaces';
import axios from 'axios';

const { Title, Text } = Typography;
import { CaretRightOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

export const TransactionsActivityLogsShow: React.FC<IResourceComponentsProps> = () => {

    const {
        user,
      } = useAuth0();
    const { open } = useNotification();

    const { queryResult } = useShow<ITransactionsActivityLog>();
    const { data, isLoading } = queryResult;
    const record = data?.data;
       
    const params= useParams()
    const [id, setId] = useState(params.id);

    const { data: queryResultWalletStats, isLoading: isLoadingWalletStats, isError } = useOne<IWalletStat, HttpError>({
        resource: "wallet-stats",
        id,
        meta: { 
            "by_activity_log": true,
        },
      });

    const recordWalletStats = queryResultWalletStats?.data;

    let colors = {
        "us-west-2": "green",
        "us-east-1": "lightgreen",
        "eu-central-1": "blue",
        "eu-west-1": "lightblue"
    }

    let [isLoadingRedriveIncludeItem, setIsLoadingRedriveIncludeItem] = React.useState<boolean>(false)
    let [redriveIncludeItemRequest, setRedriveIncludeItemRequest] = React.useState<any>(null)
    let [redriveIncludeItemRequestWalletType, setRedriveIncludeItemRequestWalletType] = React.useState<any>(null)
    let [redriveIncludeItemRequestTransactionType, setRedriveIncludeItemRequestTransactionType] = React.useState<any>(null)
    let [redriveIncludeItemRequestTransactionsType, setRedriveIncludeItemRequestTransactionsType] = React.useState<any>(null)

    async function redriveIncludeItem(include_config: any, blue_green: string, type: string) {
        console.log(include_config)
        setRedriveIncludeItemRequest(null)
        setRedriveIncludeItemRequestWalletType(null)
        setRedriveIncludeItemRequestTransactionType(null)
        setRedriveIncludeItemRequestTransactionsType(null)

        setIsLoadingRedriveIncludeItem(true)
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
        const domain = process.env.REACT_APP_API_BASE_URL
        const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
        const qa_environment = qa_config["environment"]
        const blue_config = qa_config["config"]

        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "admin-portal",
            "Source-Region": "us-west-2",
            "Destination-Region": "us-west-2",
        };

        
        const apiUrl = "https://" + continent.toLowerCase() + ".api." + domain 
        console.log("Sending the request")
        let url
        if (qa_environment == "blue"){
            url = `${apiUrl}/internal/tools/moralis-mgmt-debug-tool/blue/ANY/${continent.toUpperCase()}/v0/send-message-to-moralis-queue`
        }  
        else{
            url = `${apiUrl}/internal/tools/moralis-mgmt-debug-tool/ANY/${continent.toUpperCase()}/v0/send-message-to-moralis-queue`
        }
        console.log(url)


        try {
            let queue_name = ""
            if (include_config.include_type == "TRANSACTION"){
                if(include_config?.request?.hash){
                    queue_name = "-moralis-mgmt-request-moralis-transaction-queue"
                } else{
                    queue_name = "-moralis-mgmt-request-moralis-transactions-queue"
                }   
            }
            else if(include_config.include_type == "WALLET"){
                queue_name = "-moralis-mgmt-request-moralis-wallet-stats-queue"
            }
            else{
                open?.({
                    type: "error",
                    message: "Could not determine queue name",
                    description: "Could not determine queue name",
                    key: "redrive-include-item",
                });   
                throw new Error("Could not determine queue name");
            }
             
            if (blue_green == "blue"){
                queue_name = queue_name + "-blue" 
            }

            let new_obj = {
                "queue_name": queue_name,
                "region": record?.region,
                "environment": record?.environment,
                "message": {
                    "org_id": record?.org_id,
                    "tenant_id": record?.tenant_id,
                    "user_id": record?.user_id,
                    "continent": record?.org_id,
                    "region": record?.region,
                    "addresses": include_config?.request?.addresses,
                    "chains": include_config?.request?.chains,
                    "type": type,
                    "include_config": include_config
                }
            }

            // if includes_type == "hashes": 
            //         sqs_message['hash'] = [include]
            // if include_chains: 
            //         sqs_message['chains'] = [include_chain]
            // if include_from_block: 
            //         sqs_message['from_block'] = include_from_block
            // if include_to_block: 
            //         sqs_message['to_block'] = include_to_block
            // if include_from_date: 
            //         sqs_message['from_date'] = include_from_date
            // if include_to_date: 
            //         sqs_message['to_date'] = include_to_date
            // if include_process_order: 
            //         sqs_message['process_order'] = include_process_order

            console.log(new_obj)
            setRedriveIncludeItemRequest(new_obj)

            const { data, status } = await axios.post(
                url,
                JSON.stringify(new_obj), {
                    headers: requestHeaders
                }
            )
            console.log(data)
            console.log(status)
            let new_message = "success: " + data.result
            let new_description = "Redrive Request"
                
            if (data.ok){
                open?.({
                    type: "success",
                    message: new_message,
                    description: new_description,
                    key: "redrive-include-item",
                });

                if (include_config.include_type == "TRANSACTION"){
                    if(include_config?.request?.hash){
                        setRedriveIncludeItemRequestTransactionType(data.result)
                    } else{
                        setRedriveIncludeItemRequestTransactionsType(data.result)
                    }
                }
                else if(include_config.include_type == "WALLET"){
                    setRedriveIncludeItemRequestWalletType(data.result)
                } 
                else{
                    setRedriveIncludeItemRequestWalletType(data.result)
                }

            } else{
                open?.({
                    type: "error",
                    message: "There was an error",
                    description: data.error?.message,
                    key: "redrive-include-item",
                });  
    
            }
        } catch (error: any) {
            console.log(error.response); 
            setIsLoadingRedriveIncludeItem(false)
            
            return error.response;
            
        }       

        setIsLoadingRedriveIncludeItem(false)
    }

    const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setRedriveIncludeItemRequest(null)
      };

      
    return (
        <Show isLoading={queryResult?.isLoading || queryResult?.isFetching }>
            <Title level={5}>Activity log</Title>
            <Text>{record?.lock_value}</Text>
            <Title level={5}>Environment</Title>
            <Text>{record?.environment}</Text>
            <Title level={5}>Region</Title>
            <TagField color={colors[record?.region as keyof typeof colors ]} value={record?.region} ></TagField>
            <Title level={5}>Stages </Title>
            {         
                    record?.stages?.map(stage=>{
                        let colors = {
                            "pre_process": "lightgreen",
                            "process": "green",
                            "post_process": "darkgreen",
                        }
                    return <Tag color={colors[stage as keyof typeof colors ]} key={stage}>{stage}</Tag>
                })
            }
            <Title level={5}>Org id </Title>
            <Text>{record?.org_id}</Text>
            <Title level={5}>Tenant id</Title>
            <Text>{record?.tenant_id}</Text>
            <Title level={5}>User id</Title>
            <Text>{record?.user_id}</Text>
            <Title level={5}>Include Ids</Title>
            {         
                    record?.include_ids?.map((include_id: any)=>{
                    return <Tag key={include_id.error}>{include_id}</Tag>
                })
            }   
            <Title level={5}>Pre Process Stepfunction</Title>
            {<span>Find the stepfunction execution here: <a href={`https://${record?.region}.console.aws.amazon.com/states/home?region=${record?.region}#/v2/executions/details/` + record?.pre_process?.aws_step_functions_execution_id} target="_blank"> {record?.pre_process?.aws_step_functions_execution_id} </a> </span> }
            <br></br>
            {<span>Has started the next stepfunction: <a href={`https://${record?.region}.console.aws.amazon.com/states/home?region=${record?.region}#/v2/executions/details/` + record?.pre_process?.started_stepfunction_arn} target="_blank"> {record?.pre_process?.started_stepfunction_arn} </a> </span> }
            <Text></Text>
            <Title level={5}>Process Stepfunction</Title>
            {<span>Find the stepfunction execution here: <a href={`https://${record?.region}.console.aws.amazon.com/states/home?region=${record?.region}#/v2/executions/details/` + record?.process?.aws_step_functions_execution_id} target="_blank"> {record?.process?.aws_step_functions_execution_id} </a> </span> }
            <Divider></Divider>
            <Title level={1}>Pre Process information</Title>
            <Title level={5}>Warnings</Title>
            {         
                    record?.pre_process?.warnings?.map((warning: any)=>{
                    return <Tag color={"red"} key={warning.error}>{warning.error}</Tag>
                })
            }            

            <Title level={5}>Looking for number of pre process items to be included in the origional_task_result-incl_xxx item</Title>
            <Text>{record?.pre_process?.include_items?.find((item: { main_include_item: boolean; }) => item?.main_include_item == false )?.include_config.waiting_for_total_items_to_be_included}</Text>
            <Title level={5}>Looking for number of pre process items to be included in this subitem</Title>
            <Text>{record?.pre_process?.expected_number_of_include_items_for_sub_include_item}</Text>

            <Title level={5}>Include items</Title>
            <Divider></Divider>

            {
                redriveIncludeItemRequest ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                        message={<pre>{JSON.stringify(redriveIncludeItemRequest, null, 2)}</pre> }
                        description="Message send to queue"
                        type="success"
                        closable
                        onClose={onClose}
                        />
                    <Divider></Divider>
                </Space> 
                :
                ""
            }  
            {
                redriveIncludeItemRequestWalletType ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                        message={<span>Follow the message here: <a href={"https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#logsV2:logs-insights$3FqueryDetail$3D~(end~0~start~-3600~timeType~'RELATIVE~unit~'seconds~editorString~'fields*20*40timestamp*2c*20*40message*2c*20*40logStream*2c*20*40log*0a*7c*20filter*20*40message*20like*20*2f*22" + redriveIncludeItemRequestWalletType + "*22*2f*0a*7c*20sort*20*40timestamp*20desc*0a*7c*20limit*2020~queryId~'c3e2abf843d55b2-bae84a9e-438a33b-4cc1fc1c-49335c57d168ded8aea6f79~source~(~'*2faws*2flambda*2fprd-pdx-moralis-mgmt-get-moralis-wallet-stats))$26tab$3Dlogs"} target="_blank"> link </a> </span> }
                        type="success"
                        closable
                        onClose={onClose}
                        />
                    <Divider></Divider>
                </Space> 
                :
                ""
            }   
            {
                redriveIncludeItemRequestTransactionType ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                        message={<span>Follow the message here: <a href={"https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#logsV2:logs-insights$3FqueryDetail$3D~(end~0~start~-3600~timeType~'RELATIVE~unit~'seconds~editorString~'fields*20*40timestamp*2c*20*40message*2c*20*40logStream*2c*20*40log*0a*7c*20filter*20*40message*20like*20*2f*22" + redriveIncludeItemRequestTransactionType + "*22*2f*0a*7c*20sort*20*40timestamp*20desc*0a*7c*20limit*2020~queryId~'c3e2abf843d55b2-bae84a9e-438a33b-4cc1fc1c-49335c57d168ded8aea6f79~source~(~'*2faws*2flambda*2fprd-pdx-moralis-mgmt-retrieve-and-preprocess-transaction))$26tab$3Dlogs"} target="_blank"> link </a> </span> }
                        type="success"
                        closable
                        onClose={onClose}
                        />
                    <Divider></Divider>
                </Space> 
                :
                ""
            } 
            {
                redriveIncludeItemRequestTransactionsType ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                        message={<span>Follow the message here: <a href={"https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#logsV2:logs-insights$3FqueryDetail$3D~(end~0~start~-3600~timeType~'RELATIVE~unit~'seconds~editorString~'fields*20*40timestamp*2c*20*40message*2c*20*40logStream*2c*20*40log*0a*7c*20filter*20*40message*20like*20*2f*22" + redriveIncludeItemRequestTransactionsType + "*22*2f*0a*7c*20sort*20*40timestamp*20desc*0a*7c*20limit*2020~queryId~'c3e2abf843d55b2-bae84a9e-438a33b-4cc1fc1c-49335c57d168ded8aea6f79~source~(~'*2faws*2flambda*2fprd-pdx-moralis-mgmt-retrieve-and-preprocess-transactions))$26tab$3Dlogs"} target="_blank"> link </a> </span> }
                        type="success"
                        closable
                        onClose={onClose}
                        />
                    <Divider></Divider>
                </Space> 
                :
                ""
            }
            <Table dataSource={record?.pre_process?.include_items.filter((item: { main_include_item: boolean; }) => item?.main_include_item == false )} rowKey="lock_value"
            pagination={{
                defaultPageSize:100,
                showSizeChanger:true,
            }}
            > 
            <Table.Column<ITransactionsActivityLog>
                    title="lock_value" 
                    dataIndex="lock_value" 
                    render={(_: any, record: { lock_value: any; }) => {
                        return (
                            <Tooltip title={record?.lock_value}>
                            <TagField
                                value={`${
                                    record?.lock_value?.substring(0, 2)
                                }` }
                            />
                            </Tooltip>
                        );
                    }}
                />
            <Table.Column<ITransactionsActivityLogIncludeItem>
                    title="result" 
                    dataIndex="s3_result_location" 
                    render={(_: any, record: {
                        result: any; s3_result_location: string; 
}) => {
                        return (
                            record?.s3_result_location ?
                            <Tooltip title={record?.s3_result_location}>
                            <TagField
                                value={`${
                                record?.s3_result_location?.substring(0, 2) 
                                }` }
                            />
                            </Tooltip>
                            : <pre>{JSON.stringify(record?.result, null, 2)}</pre>
                        );
                    }}
                />
            <Table.Column<ITransactionsActivityLogIncludeItem>
                title="include_type" 
                dataIndex="include_type" 
                render={(_: any, record: { include_config: any; }) => {
                    return (
                        <TagField
                            value={`${
                                record?.include_config?.include_type
                            }` }
                        />
                    );
                }}
            />
            <Table.Column<ITransactionsActivityLogIncludeItem>
                title="Request" 
                dataIndex="include_type" 
                render={(_: any, record: { include_config: any; }) => {
                    return (
                        <pre>{JSON.stringify(record?.include_config?.request, null, 2)}</pre>
                    );
                }}
            />
            <Table.Column<ITransactionsActivityLogIncludeItem>
                title="Error" 
                dataIndex="error" 
                render={(_: any, record: { include_config: any; }) => {
                    return (
                        <pre>{JSON.stringify(record?.include_config?.error, null, 2)}</pre>
                    );
                }}
            />
            <Table.Column<ITransactionsActivityLogIncludeItem>
                    title="Actions"
                    dataIndex="include_type"
                    render={(_: any, record: { include_config: any; }) => {
                        return (
                        <Space>
                            <Button
                                icon={<CaretRightOutlined />}
                                size="small"
                                style={{ borderColor: "green" }}
                                onClick={(e) => redriveIncludeItem(record?.include_config, "green", "include")}
                            >Redrive the item (green queue)
                            </Button>
                            <Button
                                icon={<CaretRightOutlined />}
                                size="small"
                                style={{ borderColor: "blue" }}
                                onClick={(e) => redriveIncludeItem(record?.include_config, "blue", "include")}
                            >Redrive the item (blue queue)
                            </Button>
                        </Space>
                        );
                    }}

                />   
            </Table>

            <Divider></Divider>
            <Title level={1}>Wallet Stats</Title>
            {/* <Title level={5}>Id</Title>
            <Text>{recordWalletStats?.id}</Text> */}
            <Title level={5}>address</Title>
            <Text>{recordWalletStats?.address}</Text>
            <Title level={5}>Origional caller address</Title>
            <Text>{recordWalletStats?.original_caller_address}</Text>
            <Title level={5}>Use address to search assignments</Title>
            <Text>{recordWalletStats?.use_address_to_search_assignments}</Text>
            <Title level={5}>Number of transactions in DB</Title>
            <Text>{recordWalletStats?.number_of_transactions}</Text>
            <Title level={5}>Number of tags on transaction</Title>
            <Text>{recordWalletStats?.number_of_tags_on_transaction}</Text>
            <Title level={5}>Number of from_labels on transaction</Title>
            <Text>{recordWalletStats?.number_of_from_address_labels_on_transaction}</Text>
            <Title level={5}>Number of to_labels on transaction</Title>
            <Text>{recordWalletStats?.number_of_to_address_labels_on_transaction}</Text>
            <Title level={5}>Number of groups on transaction</Title>
            <Text>{recordWalletStats?.number_of_groups_on_transaction}</Text>
            <Title level={5}>User has number of addresses</Title>
            <Text>{recordWalletStats?.user_has_number_of_addresses}</Text>
            <Title level={5}>Total static assignments expected</Title>
            <Text>{6 * recordWalletStats?.total_sum_of_transactions! * recordWalletStats?.shared_with_users?.length}</Text>    
            <Title level={5}>Total static assignments found</Title>
            <Text>{recordWalletStats?.total_number_of_user_assignments! + recordWalletStats?.total_number_of_address_assignments! + recordWalletStats?.total_number_of_from_address_assignments! + recordWalletStats?.total_number_of_to_address_assignments! + recordWalletStats?.total_number_of_chain_assignments! + recordWalletStats?.total_number_of_receipt_status_assignments! + recordWalletStats?.total_number_of_decoded_call_assignments!}</Text>            
            <Title level={5}>Total include assignments expected</Title>
            <Text>{ recordWalletStats?.include_transactions? 1 * recordWalletStats?.shared_with_users?.length : 0}</Text>    
            <Title level={5}>Total include assignments found</Title>
            <Text>{recordWalletStats?.total_number_of_include_id_assignments!}</Text>            
            <Title level={5}>Total dynamic assignments expected without include</Title>
            <Text>{ (recordWalletStats?.address_default_tags?.length! + recordWalletStats?.address_default_labels?.length! + recordWalletStats?.group_names?.length!) * recordWalletStats?.shared_with_users?.length}</Text>    
            <Title level={5}>Total dynamic assignments found without include</Title>
            <Text>{recordWalletStats?.total_number_of_tag_assignments_without_include!  + recordWalletStats?.total_number_of_label_assignments_without_include! + recordWalletStats?.total_number_of_group_assignments_without_include! }</Text>                           
            <Title level={5}>Address shared with</Title>
            <Text>{recordWalletStats?.address_shared_with}</Text>
            <Title level={5}>Group Names</Title>
            <Text>{recordWalletStats?.group_names}</Text>
            <Title level={5}>Address default tags</Title>
            <Text>
                {recordWalletStats?.address_default_tags?.map((tag: any) => <TagField id={tag} key={tag} value={tag}  color={"geekblue"} style={{ marginTop: 5, marginBottom: 5 }}></TagField>)}
            </Text>
            <Title level={5}>Address default labels</Title>
            <Text>
                {recordWalletStats?.address_default_labels?.map((label: any) => <TagField id={label} key={label} value={label}  color={"geekblue"} style={{ marginTop: 5, marginBottom: 5 }}></TagField>)}
            </Text>
            <Title level={5}>Dump</Title>
            <pre>
                {JSON.stringify(recordWalletStats, null, 2)}
            </pre>
        </Show>
    );
};

