import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    EditButton,
    ShowButton,
    TextField,
    DeleteButton,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space, Typography } from "antd";

import { IUser } from "interfaces";
import { useAuth0 } from '@auth0/auth0-react';
const { Title, Text } = Typography;


export const ProcessTransactionsJobsList: React.FC<IResourceComponentsProps> = () => {

    const {
        user,
      } = useAuth0();
      
      const { tableProps } = useTable<IUser>({
        resource: "users"
    });

    return (

        <List >
            <Title level={5}>Id</Title>
            <Text></Text>    
            <Table {...tableProps} rowKey="id">


            </Table>
        </List>
    );
};

