import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";

import { Create, Edit, useForm } from "@refinedev/antd";

import { Checkbox, Form, Input, Select } from "antd";

import { IRole } from "interfaces";
export const InternalRoleCreate: React.FC<IResourceComponentsProps> = () => {
        
    const { formLoading ,formProps, saveButtonProps, onFinish } = useForm<IRole>(
        {
            resource: "roles",
            action: "create",
        }
    );

    const { Option } = Select;

    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps} resource="roles">
            <Form {...formProps} layout="vertical" >
                <Form.Item
                    label="Type"
                    name="type"  
                    initialValue="internal"                  
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Application"
                    name="application"  
                    initialValue="admin-portal"                  
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select 
                        placeholder="Please select an application">
                        <Option value="admin-portal">Admin-Portal</Option>
                        <Option value="analytics-portal">Analytics-Portal</Option>
                        <Option value="selfservice-portal">Selfservice-Portal</Option>
                        <Option value="qa-portal">QA-Portal</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Name"
                    name="name"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Status"
                    name="status"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select a status">
                        <Option value="Enabled">Enabled</Option>
                        <Option value="Disabled">Disabled</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Scopes To be added later"
                    name="scopes"   
                    initialValue={[]}                      
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                </Form.Item>
            </Form>
        </Create>
    );
};
