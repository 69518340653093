import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    EditButton,
    ShowButton,
    TextField,
    DeleteButton,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space } from "antd";

import { IUser } from "interfaces";


export const ExternalUsersList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, sorter, tableQueryResult } = useTable<IUser>({
        resource: "users",
        meta: { 
            "org_id": "ALL",
            "tenant_id": "ALL",
        },
    });

    const datetime = dayjs.unix(1653134400)


    return (
        <List>
            <Table {...tableProps} rowKey="id"
                pagination={{
                    showSizeChanger:true,
                }}
            >
                <Table.Column<IUser>
                    sorter={ (a: any, b: any) => a.id - b.id}
                    title="Id" 
                    dataIndex="id" 
                    render={(_: any, record: { id: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.id
                                }` }
                            />
                        );
                    }}
                />
                <Table.Column<IUser>
                      sorter={ (a: any, b: any) => a.name - b.name}
                      filters={tableQueryResult?.data?.data.map((
                        { name }) => ({ "text": name, "value": name})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                      filterMode='tree'
                      filterSearch={true}
                      onFilter={(value: any, record: { name: string; }) => record.name.startsWith(value)}
                      dataIndex="name" 
                      title="Name"  
                      render={(_: any, record: { name: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.name
                                }` }
                            />
                        );
                    }}
                      />   
                <Table.Column<IUser>
                    sorter={ (a: any, b: any) => a.email - b.email}
                    filters={tableQueryResult?.data?.data.map((
                        { email }) => ({ "text": email, "value": email})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { email: string; }) => record.email.startsWith(value)}
                    title="Email" 
                    dataIndex="email" 
                    render={(_: any, record: { email: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.email
                                }` }
                            />
                        );
                    }}
                />   
                <Table.Column<IUser>
                    sorter={ (a: any, b: any) => a.status - b.status}
                    filters={tableQueryResult?.data?.data.map((
                    { status }) => ({ "text": status, "value": status})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { status: string; }) => record.status.startsWith(value)}
                    title="Status" 
                    dataIndex="status" 
                    render={(_: any, record: { status: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.status
                                }` }
                            />
                        );
                    }}
                />     
                <Table.Column<IUser>
                    sorter={ (a: any, b: any) => a.roles - b.roles}
                    filters={tableQueryResult?.data?.data.map(( { roles }) => (roles)).flat(1).map(( role ) => ({ "text": role, "value": role})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { roles: any; }) => record.roles.includes(value)}
                    title="Roles" 
                    dataIndex="roles" 
                    render={(_: any, record: { roles: Array<string>; }) => {
                        return (
                            record.roles.map((role: any) => <TagField id={role} key={role} value={role}  style={{ marginTop: 5, marginBottom: 5 }}></TagField>)
                        );
                    }}
                />      
                <Table.Column<IUser>
                    sorter={ (a: any, b: any) => a.created_at - b.created_at}
                    title="Created At" 
                    dataIndex="created_at" 
                    render={(_: any, record: { created_at: string; }) => {
                        return (
                            <DateField value={dayjs.unix(Number(record?.created_at) || 1)} format="LLL"></DateField>
                        );
                    }}
                />    
                <Table.Column<IUser>
                    title="Actions"
                    dataIndex="id"
                    render={(_: any, record: { id: any; }) => (
                        <Space>
                            <ShowButton
                                size="small"
                                recordItemId= {record.id}
                            />
                        </Space>
                    )}
                />        
            </Table>
        </List>
    );
};

