import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    EditButton,
    ShowButton,
    TextField,
    DeleteButton,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space, Typography, Select, Button, Divider, Input, Alert } from "antd";
import { SearchOutlined } from '@ant-design/icons';

import { ITrigger, IUser } from "interfaces";
import { useAuth0 } from '@auth0/auth0-react';
const { Title, Text } = Typography;


export const TriggersList: React.FC<IResourceComponentsProps> = () => {

    const {
        user,
      } = useAuth0();
      
    const [searchOrgId, setSearchOrgId] = useState<string>(""); 
    const [finalSearchOrgId, setFinalSearchOrgId] = useState<string>(""); 
    const [searchTenantId, setSearchTenantId] = useState<string>(""); 
    const [finalSearchTenantId, setFinalSearchTenantId] = useState<string>(""); 
    const [searchUserId, setSearchUserId] = useState<string>("google-oauth2|109517966719689351983");
    const [finalSearchUserId, setFinalSearchUserId] = useState<string>("google-oauth2|109517966719689351983"); 

    const [region, setRegion] = useState<string>("us-west-2");
    const [environment, setEnvironment] = useState<string>("sandbox");
    
    const { tableProps, tableQueryResult } = useTable<ITrigger>({
        resource: "triggers",
        meta: { 
            "environment": environment,
            "region": region,
            "org_id": finalSearchOrgId,
            "tenant_id": finalSearchTenantId,
            "user_id": finalSearchUserId,
        },
    });

    const { Option } = Select;

    const refreshTableResults = () => {     
        setFinalSearchOrgId(searchOrgId)
        setFinalSearchTenantId(searchTenantId)
        setFinalSearchUserId(searchUserId)
        tableQueryResult.refetch()
       
    };

    return (

        <List >
            <Space.Compact>
            <Title level={5}>Select the environment:</Title>
                <Select
                    disabled={true}
                    defaultValue={environment}
                    style={{ width: 120, marginLeft:10, marginRight:10 }}
                    onChange={setEnvironment}
                    options={[
                        { value: 'sandbox', label: 'sandbox' },
                        { value: 'prd', label: 'prd' },
                    ]}
                />
            <Title level={5}>Select the region:</Title>
                <Select
                    defaultValue={region}
                    style={{ width: 120, marginLeft:10, marginRight:10 }}
                    onChange={setRegion}
                    options={[
                        { value: 'us-west-2', label: 'us-west-2' },
                        { value: 'us-east-1', label: 'us-east-1' },
                        { value: 'eu-central-1', label: 'eu-central-1' },
                        { value: 'eu-west-1', label: 'eu-west-1'},
                    ]}
                />

            </Space.Compact>
            <Divider></Divider>
            <Space.Compact>
                <Title level={5}>Search Org Id: </Title>
                <Input style={{width:500, marginLeft:10}} addonBefore={<SearchOutlined />} placeholder="Enter the search for org id" value={searchOrgId} onChange={(e) => setSearchOrgId(e.target.value)}/>
                <Title level={5} style={{marginLeft:10}}>Search Tenant Id: </Title>
                <Input style={{width:500, marginLeft:10}} addonBefore={<SearchOutlined />} placeholder="Enter the search for tenant id" value={searchTenantId} onChange={(e) => setSearchTenantId(e.target.value)}/>
                {/* <Title level={5} style={{marginLeft:10}}>Search User Id: </Title> */}
                {/* <Input style={{width:500, marginLeft:10}} addonBefore={<SearchOutlined />} placeholder="Enter the search for tenant id" value={searchUserId} onChange={(e) => setSearchTenantId(e.target.value)}/> */}
            </Space.Compact>
            <Divider></Divider>
            <Space.Compact style={{ width: '100%' }}>
            <Button type="primary" onClick={refreshTableResults} disabled={tableQueryResult?.isFetching }>Submit</Button>
            </Space.Compact>
            <Divider />
            {
                tableQueryResult?.data?.summary.summary.time_needed_in_seconds ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                    message={"Time taken to load the results: " + tableQueryResult?.data?.summary.summary.time_needed_in_seconds + " seconds"}
                    type="success"

                    />
                </Space> :
                ""
            }   
            <Divider />            
            <Text></Text>    
            <Table {...tableProps} rowKey="id">
            <Table.Column<ITrigger>
                    sorter={ (a: any, b: any) => a.id - b.id}
                    title="Id" 
                    dataIndex="id" 
                    render={(_: any, record: { id: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.id
                                }` }
                            />
                        );
                    }}
                />
            <Table.Column<ITrigger>
                    sorter={ (a: any, b: any) => a.org_id - b.org_id}
                    title="Org Id" 
                    dataIndex="org_id" 
                    render={(_: any, record: { org_id: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.org_id
                                }` }
                            />
                        );
                    }}
                />
            <Table.Column<ITrigger>
                    sorter={ (a: any, b: any) => a.tenant_id - b.tenant_id}
                    title="Tenant Id" 
                    dataIndex="tenant_id" 
                    render={(_: any, record: { tenant_id: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.tenant_id
                                }` }
                            />
                        );
                    }}
                />
            <Table.Column<ITrigger>
                    sorter={ (a: any, b: any) => a.name - b.name}
                    title="Name" 
                    dataIndex="name" 
                    render={(_: any, record: { name: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.name
                                }` }
                            />
                        );
                    }}
                />
            <Table.Column<ITrigger>
                    sorter={ (a: any, b: any) => a.status - b.status}
                    title="Status" 
                    dataIndex="status" 
                    render={(_: any, record: { status: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.status
                                }` }
                            />
                        );
                    }}
                />
                <Table.Column<ITrigger>
                    title="Actions"
                    dataIndex="id"
                    render={(_: any, record: {
                        region: string;
                        environment: string; id: any; 
}) => (
                        <Space>
                            <ShowButton
                                size="small"
                                recordItemId= {record.environment + "_" + record.region +  "_"  + record.id}
                            />
                            <EditButton
                                size="small"
                                recordItemId= {record.environment + "_" + record.region +  "_"  + record.id}
                            />
                            <DeleteButton
                                size="small"
                                recordItemId= {record.environment + "_" + record.region +  "_"  + record.id}
                            />
                        </Space>
                    )}
                />  
            </Table>
        </List>
    );
};

