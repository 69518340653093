import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { Alert, Avatar, Button, Divider, List, Select, Skeleton, Space, Tag, Tooltip, Typography } from 'antd';
import { DeleteButton, EditButton, Show, TagField } from '@refinedev/antd';
import { useAuth0 } from '@auth0/auth0-react';
import { IAbi } from 'interfaces';
import AceEditor from 'react-ace';

const { Title, Text } = Typography;

import InfiniteScroll from 'react-infinite-scroll-component';

export const AbiShow: React.FC<IResourceComponentsProps> = () => {

    const {
        user,
      } = useAuth0();

      const { queryResult } = useShow<IAbi>();
      const { data, isLoading } = queryResult;
      const record = data?.data;
      const { Option } = Select;

    let colors = {
        "us-west-2": "green",
        "us-east-1": "lightgreen",
        "eu-central-1": "blue",
        "eu-west-1": "lightblue"
    }

    const loadMoreData = () => {

    }

    return (
        <Show isLoading={queryResult?.isLoading || queryResult?.isFetching }>
            <Title level={5}>Abi Environment</Title>
            <TagField value={record?.environment}></TagField>
            <Title level={5}>Abi Continent</Title>
            <TagField color={colors[record?.continent as keyof typeof colors ]} value={record?.continent}></TagField>
            <Title level={5}>Abi Org Id</Title>
            <Text>{record?.org_id}</Text>
            <Title level={5}>Abi Tenant Id</Title>
            <Text>{record?.tenant_id}</Text>
            <Title level={5}>Abi User Id</Title>
            <Text>{record?.created_by}</Text>
            <Title level={5}>Abi Id</Title>
            <Text>{record?.id}</Text>
            <Title level={5}>Abi Chain id</Title>
            <Text>{record?.chain_id}</Text>
            <Title level={5}>Abi Address</Title>
            <Text>{record?.address}</Text>                        
            <Title level={5}>Abi Name</Title>
            <Text>{record?.name}</Text>
            <Title level={5}>Abi Description</Title>
            <Text>{record?.description}</Text>            
            <Title level={5}>Abi Status</Title>
            <Text>{record?.status}</Text>
            <Title level={5}>Abi type</Title>
            <Text>{record?.abi_type}</Text>
            <Divider></Divider>
            <Title level={5}>Abi</Title>
            {
                record?.abi ? 
                <AceEditor  
                style={{width: 1000}}
                mode="json"
                theme="monokai"
                wrapEnabled={true}
                value={ record ? JSON.stringify(JSON.parse(record?.abi),null,2) : '{}' } 
                readOnly={true}
                name="UNIQUE_ID_OF_DIV"
                editorProps={{ $blockScrolling: true, $searchBox: true }}
                highlightActiveLine={true}
                />
                :
                "No abi found"
            }
            <Divider></Divider>            
        </Show>
    );
};

