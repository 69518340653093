import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useShow, IResourceComponentsProps, useOne } from "@refinedev/core";

import { TagField, Show, EditButton, ShowButton, DateField } from "@refinedev/antd";

import { Avatar, Button, Checkbox, Col, Form, Row, Space, Table, Tag, Tooltip, Typography } from "antd";

import { IUser } from "interfaces";

const { Title, Text } = Typography;
import dayjs from "dayjs";
import { useAuth0 } from "@auth0/auth0-react";
import { json } from "stream/consumers";


export const InternalUserShow: React.FC<IResourceComponentsProps> = () => {
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const { queryResult } = useShow<IUser>();
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const { data: categoryData, isLoading: categoryIsLoading } =
        useOne<IUser>({
            resource: "users",
            id: record?.id || "",
            queryOptions: {
                enabled: !!record,
            },
            meta: {"id": record?.id}
        });



    return (
        <Show isLoading={isLoading}>
            <Row>
                <Col
                span={22}>
                <Title level={5}>Id</Title>
                </Col>
                <Space
                    style={{
                        float: "left",
                        marginRight: "10px",
                        paddingLeft: "0px"
                    }}
                >
                <Avatar size="large" src={<img src={record?.picture} alt="avatar" />} />
              </Space>
            </Row>
            <Text>{record?.id}</Text>
            
            <Title level={5}>Org id</Title>
            <Text>{record?.org_id}</Text>
            <Title level={5}>Org continent</Title>
            <Text>{record?.app_metadata?.org_continent}</Text>
            <Title level={5}>Root Tenant Id</Title>
            <Text>{record?.app_metadata?.root_tenant_id}</Text>
            <Title level={5}>Root Tenant Continent</Title>
            <Text>{record?.app_metadata?.tenants.find((tenant: { is_root_tenant: boolean; }) => tenant.is_root_tenant === true).tenant_continent}</Text>
            <Title level={5}>Name</Title>
            <Text>{record?.name}</Text>
            <Title level={5}>Email</Title>
            <Text>{record?.email}</Text>
            <Title level={5}>Status</Title>
            <Text>{record?.status}</Text>
            <Title level={5}>Tenants</Title>
            <Text>
                {record?.app_metadata?.tenants.map((tenant: any) => <pre>{JSON.stringify(tenant, null,2)}</pre>)}
            </Text>

            <Title level={5}>Created At</Title>
            <Text>
                {/* <DateField value={dayjs.unix(Number(record?.created_at) || 1)} format="LLL"></DateField> */}
                <DateField value={record?.created_at} format="LLL"></DateField>
            </Text>      
            <Title level={5}>Updated At</Title>
            <Text>
                {/* <DateField value={dayjs.unix(Number(record?.created_at) || 1)} format="LLL"></DateField> */}
                <DateField value={record?.updated_at} format="LLL"></DateField>
            </Text>   
            <Title level={5}>Updated by service</Title>
            <Text>{record?.updated_by_service}</Text>
            <Title level={5}>All Role(s)</Title>
            <Text>
                {record?.roles.map((role: any) => <TagField id={role} key={role} value={role}  color={"geekblue"} style={{ marginTop: 5, marginBottom: 5 }}></TagField>)}
            </Text>

            

        </Show>
    );
};
