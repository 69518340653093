import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";

import { Create, useForm } from "@refinedev/antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { Alert, Button, Checkbox, Divider, Form, Input, Select, Space, Tooltip } from "antd";

import { IStream } from "interfaces";
import Title from "antd/es/typography/Title";
import { isAddress } from 'viem'
import AceEditor from 'react-ace';

export const StreamCreate: React.FC<IResourceComponentsProps> = () => {
        
    const { formLoading ,formProps, saveButtonProps, onFinish, mutationResult } = useForm<IStream>(
        {
            resource: "streams",
            action: "create",
            redirect: "show",
        }
    );

    const { Option } = Select;
    let [abi, setAbi] = React.useState<string>()


    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      };
      
      const formItemLayoutWithOutLabel = {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 },
        },
      };

    const defaultAbi = `[
    {
        "inputs": [],
        "name": "getCount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "increment",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
]
  `
    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps} resource="streams">
            <Form {...formProps} layout="vertical" >
                <Form.Item
                    label="Environment"
                    name="environment"  
                    initialValue={process.env.REACT_APP_ENV == "dev" ? "sandbox" : process.env.REACT_APP_ENV}                 
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Continent"
                    name="region"  
                    initialValue={"us-west-2"}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select a continent">
                        <Option value="us-west-2">US (us-west-2/us-east-1)</Option>
                        <Option value="eu-central-1">EU (eu-central-1/eu-west-1)</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Stream Status"
                    name="status"  
                    initialValue={"active"}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select the stream status">
                        <Option value="active">Active</Option>
                        <Option value="inactive">Inactive</Option>
                    </Select>
                </Form.Item>                
                <Form.Item
                    label="Type"
                    name="stream_type"  
                    initialValue={"default"}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select a stream type">
                        <Option value="default">Default</Option>
                        <Option value="custom">Custom</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Chain"
                    name="chain_id"                    
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                    return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Select 
                        placeholder="Please select the stream status">
                        <Option value="0x1">Ethereum</Option>
                        <Option value="0xaa36a7">Sepolia</Option>
                        <Option value="0x5">Goerli</Option>
                        <Option value="0x89">Polygon</Option>
                        <Option value="0x13881">Mumbai</Option>
                        <Option value="0x38">Bsc</Option>
                        <Option value="0x61">Bsc_testnet</Option>
                        <Option value="0xa86a">Avalanche</Option>
                        <Option value="0xfa">Fantom</Option>
                        <Option value="0x19">Cronos</Option>
                        <Option value="0x2a15c308d">Palm</Option>
                        <Option value="0xa4b1">Arbitrum</Option>
                        <Option value="0x64">Gnosis</Option>
                        <Option value="0x27d8">Gnosis Chiado</Option>
                        <Option value="0x15b38">Chiliz</Option>
                        <Option value="0x15b32">Chiliz Spicy</Option>
                        <Option value="0x2105">Base</Option>
                        <Option value="0x14a33">Base Goerli</Option>
                    </Select>
                </Form.Item>       
                <Form.Item
                    label="Address"
                    name="address"                    
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!isAddress(value)) {
                                    return Promise.reject(new Error('Not a valid address ' + value));
                                }    
                            },
                        },
                    ]}
                >
                    <Input placeholder="0x8b51538396e5f79b3607720c63e7bc6dee1495b3"/>
                </Form.Item>    
                <Form.Item
                    label="Org id"
                    name="org_id"                    
                    rules={[
                        {
                            required: false,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                    return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input placeholder="Optionally the org id"/>
                </Form.Item>   
                <Form.Item
                    label="Tenant id"
                    name="tenant_id"                    
                    rules={[
                        {
                            required: false,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                    return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input placeholder="Optionally the tenant id"/>
                </Form.Item>                                                    
                <Form.Item
                    label="Name"
                    name="name"                    
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                    return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input placeholder="test name"/>
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="Description here"/>
                </Form.Item>
                <Form.Item
                    hidden={false}
                    label="Optionally an Abi to decode the transactions"
                    name="abi"   
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                <AceEditor  
                        style={{width: 1000}}
                        mode="json"
                        theme="monokai"
                        wrapEnabled={true}
                        // defaultValue={defaultAbi}
                        onChange={newValue => {
                            try {
                                setAbi(newValue);
                            } catch (e) {
                                console.log(e)
                            }
                        }}
                        name="abi"
                        editorProps={{ $blockScrolling: true }}
                        placeholder={defaultAbi}
                />
                </Form.Item>                   
            </Form>

        </Create>
    );
};
