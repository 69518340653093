import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";

import { Create, Edit, useForm } from "@refinedev/antd";

import { Alert, Checkbox, Form, Input, Select, Space, Tooltip } from "antd";

import { IRepository } from "interfaces";
export const RepositoryMgmtCreate: React.FC<IResourceComponentsProps> = () => {
        
    const { formLoading ,formProps, saveButtonProps, onFinish, mutationResult } = useForm<IRepository>(
        {
            resource: "repositories",
            action: "create",
        }
    );

    const { Option } = Select;

    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps} resource="repositories">
            {
                mutationResult?.data?.data.follow_build_here ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                    message={<span>New repo request can be followed here: <a href={mutationResult?.data?.data?.follow_build_here} target="_blank"> link </a> </span> }
                    type="success" 
                    />
                    <pre>
                        
                    </pre>
                </Space> :
                ""
            }  

            <Form {...formProps} layout="vertical" >
                <Form.Item
                    label="Category"
                    name="category"  
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select a category">
                        <Option value="analytics">Analytics</Option>
                        <Option value="core">CORE</Option>
                        <Option value="integrations">Integrations</Option>
                        <Option value="marketing">Marketing</Option>
                        <Option value="security">Security</Option>
                        <Option value="shared">Shared</Option>
                        <Option value="sre">SRE</Option>
                        <Option value="qa">QA</Option>
                        <Option value="tools">Tools</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Name"
                    name="name"                    
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                    if(value.indexOf(' ') >= 0){
                                        return Promise.reject(
                                            new Error("Can not have spaces in the name")
                                        );
                                    }
                                    if(value.indexOf('_') >= 0){
                                        return Promise.reject(
                                            new Error("Can not have underscores '_' in the name")
                                        );
                                    }      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Infrastructure as code provider"
                    name="infra_type"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select an infra structure as code provider">
                        <Option value="terraform">Terraform</Option>
                        <Option value="sam">AWS SAM</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Is infra repo?"
                    name="is_infra"    
                    initialValue={false}           
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Tooltip title="Whether it is a special infra repo">
                      <Checkbox defaultChecked={false}></Checkbox> 
                    </Tooltip>
                </Form.Item>
                <Form.Item
                    label="Is front end repo?"
                    name="is_front_end"    
                    initialValue={false}           
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Tooltip title="Whether it is a front end repo. Can not be combined with is_infra or is_data">
                      <Checkbox defaultChecked={false}></Checkbox> 
                    </Tooltip>
                </Form.Item>
                <Form.Item
                    label="Initialize repo?"
                    name="initialize_repo"    
                    initialValue={true}                
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Tooltip title="Creates the default dir structure">
                      <Checkbox defaultChecked={true}></Checkbox> 
                    </Tooltip>
                </Form.Item>

            </Form>
        </Create>
    );
};
