import { AxiosInstance } from "axios";
import { RequestQueryBuilder, CondOperator } from "@nestjsx/crud-request";
import { DataProvider } from "@refinedev/core";
import {
    handleFilter,
    handlePagination,
    handleSort,
    handleJoin,
    axiosInstance,
} from "./utils";

export const dataProvider = (
    apiUrl: string,
    httpClient: AxiosInstance = axiosInstance,
): Required<DataProvider> => ({
    getList: async ({ resource, pagination, filters, sorters, meta }) => {
        console.log("getList")
        console.log(meta)
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
        const domain = process.env.REACT_APP_API_BASE_URL

        if(resource == "users" || resource == "roles"){

            const { data } = await httpClient.get(`${apiUrl}/management/permissions/GET/US/v0/${resource}`);
            console.log(data)
            return {
                data: data,
                total: 10,
            };
        } 
        if(resource == "moralis-chain-activity"){
            const chains = meta?.chains ? meta?.chains : []
            const address = meta?.address
            var chainsString = ''
            chains.forEach((chain: string) => {
                console.log(chain)
                    chainsString = chainsString + "&chains=" + chain
            });
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/tools/moralis-mgmt-debug-tool/GET/US/v0/chain-activity?address=${address}${chainsString}`
            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        } 
        if(resource == "authentication-scopes"){
            const { data, headers } = await httpClient.get(`${apiUrl}/internal/authentication/GET/US/v0/${resource}`);
            console.log(data)
            // const summary = JSON.parse(headers["x-summary"])

            return {
                data: data,
                total: data.length,
                // summary
            };
        }        
        if(resource == "moralis-wallet-stats"){
            resource = "wallet-stats"
            const chains = meta?.chains ? meta?.chains : []
            const address = meta?.address
            var chainsString = ''
            chains.forEach((chain: string) => {
                console.log(chain)
                    chainsString = chainsString + "&chains=" + chain
            });
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/tools/moralis-mgmt-debug-tool/GET/US/v0/${resource}?address=${address}${chainsString}`
            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        } 
        if(resource == "wallet-stats"){
            let url
            if (meta?.by_activity_log == true){
                const id = meta?.id ? meta?.id : "undefined"
                const encodedId = encodeURIComponent(id as any);
                url = `https://${continent.toLowerCase()}.api.${domain}/internal/tools/process-transactions-mgmt-debug-tool/GET/US/v0/${resource}/${encodedId}`
            } else{
                const environment = meta?.environment
                const region = meta?.region
                const org_id = meta?.org_id
                const tenant_id = meta?.tenant_id
                const user_id = encodeURIComponent(meta?.user_id)
                const address = meta?.address
                const chains = meta?.chains ? meta?.chains : []
                var chainsString = ''
                chains.forEach((chain: string) => {
                    console.log(chain)
                    chainsString = chainsString + "&chains=" + chain
                });
                
                url = `https://${continent.toLowerCase()}.api.${domain}/internal/tools/process-transactions-mgmt-debug-tool/GET/US/v0/${resource}?environment=${environment}&region=${region}&org_id=${org_id}&tenant_id=${tenant_id}&user_id=${user_id}&address=${address}${chainsString}` 
            }


            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        } 
        if(resource == "repositories"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/tools/repository-mgmt/GET/US/v0/${resource}`
            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        } 
        if(resource == "integrations"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/integrations/GET/US/v0/${resource}?environment=${meta?.environment}&region=${meta?.region}&org_id=${meta?.org_id}&tenant_id=${meta?.tenant_id}`
            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        } 
        if(resource == "triggers"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/triggers/GET/US/v0/${resource}?environment=${meta?.environment}&region=${meta?.region}&org_id=${meta?.org_id}&tenant_id=${meta?.tenant_id}`
            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            
            return {
                data: data,
                total: data.length,
                summary

            };
        } 
        if(resource == "abis"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/abis/GET/US/v0/${resource}?environment=${meta?.environment}&region=${meta?.region}&org_id=${meta?.org_id}&tenant_id=${meta?.tenant_id}`
            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            
            return {
                data: data,
                total: data.length,
                summary

            };
        }      
        if(resource == "streams"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/streams/GET/US/v0/${resource}?environment=${meta?.environment}&region=${meta?.region}&org_id=${meta?.org_id}&tenant_id=${meta?.tenant_id}`
            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            
            return {
                data: data,
                total: data.length,
                summary

            };
        }                     
        if(resource == "aws-account-creation"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/tools/aws-account-creation/GET/US/v0/account-requests`
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        } 
        if(resource == "app-configs"){
            console.log("Getting the app configs")
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/tools/app-config-mgmt/GET/US/v0/${resource}`
            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        } 
        if(resource == "secrets" || resource == "ssm-parameters"){
            const regions = meta?.regions ? meta?.regions : []
            var regionsString = ''
            regions.forEach((region: string) => {
                console.log(region)
                regionsString = regionsString + "&regions=" + region
            });
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/secrets/GET/US/v0/${resource}?${regionsString}`
            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        } 
        if(resource == "client-configs"){
            const regions = meta?.regions ? meta?.regions : []
            var regionsString = ''
            regions.forEach((region: string) => {
                console.log(region)
                regionsString = regionsString + "&regions=" + region
            });
            const url = `https://${continent.toLowerCase()}.api.${domain}/management/client-config/GET/US/v0/${resource}?${regionsString}`
            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        } 
        if(resource == "work-items"){
            const regions = meta?.regions ? meta?.regions : []
            var regionsString = ''
            regions.forEach((region: string) => {
                console.log(region)
                regionsString = regionsString + "&regions=" + region
            });
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/tools/process-transactions-mgmt-debug-tool/GET/US/v0/${resource}?${regionsString}`
            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        } 
        if(resource == "transactions-activity-logs"){
            resource = "activity-logs"
            const regions = meta?.regions ? meta?.regions : []
            var regionsString = ''
            regions.forEach((region: string) => {
                console.log(region)
                regionsString = regionsString + "&regions=" + region
            });
            const id = meta?.id ? meta?.id : "undefined"
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/tools/process-transactions-mgmt-debug-tool/GET/US/v0/${resource}?${regionsString}&id=${id}`
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            
            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        } 
        else{
            const { data } = await httpClient.get(`${apiUrl}/${resource}`);

            return {
                data: data,
                total: 10,
            };
        }
    },

    getMany: async ({ resource, ids, meta }) => {
        console.log("getMany")
        const url = `${apiUrl}/${resource}`;

        let query = RequestQueryBuilder.create().setFilter({
            field: "id",
            operator: CondOperator.IN,
            value: ids,
        });

        query = handleJoin(query, meta?.join);

        const { data } = await httpClient.get(`${url}?${query.query()}`);

        return {
            data,
        };
    },

    create: async ({ resource, variables }) => {
        console.log("create")
        
        console.log(resource)
        console.log(variables)
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const domain = process.env.REACT_APP_API_BASE_URL
        
        if(resource == "roles" || resource == "users" || resource == "internal-users" || resource == "internal-roles" || resource == "external-users" || resource == "external-roles"){
            if(resource == "internal-users" || resource == "external-users"){
                resource = "users"
            } else{
                resource = "roles"
            }

            const { data } = await httpClient.post(`${apiUrl}/management/permissions/ANY/US/v0/organizations/${org_id}/${resource}`, variables);
            console.log(data)
            return {
                data: data,
            };
        } 
        if(resource == "repositories"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/tools/repository-mgmt/ANY/US/v0/${resource}`
            
            const { data, headers } = await httpClient.post(url, variables);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                summary
            };
        } 
        if(resource == "aws-account-creation"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/tools/aws-account-creation/ANY/US/v0/request-account`

            const { data, headers } = await httpClient.post(url, variables);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                summary

            };
        } 
        if(resource == "secrets" || resource == "ssm-parameters"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/secrets/ANY/US/v0/${resource}`
            
            const { data, headers } = await httpClient.post(url, variables);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                summary
            };
        } 
        if(resource == "integrations" ){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/integrations/ANY/US/v0/${resource}`
            
            const { data, headers } = await httpClient.post(url, variables);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                summary
            };
        } 
        if(resource == "triggers" ){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/triggers/ANY/US/v0/${resource}`
            
            const { data, headers } = await httpClient.post(url, variables);
            console.log(data)

            return {
                data: data,
            };
        }  
        if(resource == "abis" ){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/abis/ANY/US/v0/${resource}`
            
            const { data, headers } = await httpClient.post(url, variables);
            console.log(data)

            return {
                data: data,
            };
        }         
        if(resource == "streams" ){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/streams/ANY/US/v0/${resource}`
            
            const { data, headers } = await httpClient.post(url, variables);
            console.log(data)

            return {
                data: data,
            };
        }                 
        else{
            const url = `${apiUrl}/${resource}`;
            console.log(url)
            const { data } = await httpClient.post(url, variables);
            // console.log(data)
            return {
                data,
            };
        }
    },

    update: async ({ resource, id, variables }) => {
        console.log("update")
        console.log(resource)
        console.log(variables)
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
        const domain = process.env.REACT_APP_API_BASE_URL
        
        if(resource == "roles" || resource == "users" || resource == "internal-users" || resource == "internal-roles" || resource == "external-users" || resource == "external-roles"){
            if(resource == "internal-users" || resource == "external-users"){
                resource = "users"
            } else{
                resource = "roles"
            }
            const token = JSON.parse(localStorage.getItem('token') || '{}');
            const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
            const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
            const domain = process.env.REACT_APP_API_BASE_URL

            const { data } = await httpClient.put(`${apiUrl}/management/permissions/ANY/US/v0/organizations/${org_id}/${resource}/${id}`, variables);
            console.log(data)
            return {
                data: data,
            };
        } 
        if(resource == "secrets" || resource == "ssm-parameters"){
            const encodedId = encodeURIComponent(id as any);
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/secrets/ANY/US/v0/${resource}/${encodedId}`
            
            const { data, headers } = await httpClient.put(url, variables);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        } 
        if(resource == "triggers"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/triggers/ANY/US/v0/${resource}/${id}`
            
            const { data, headers } = await httpClient.put(url, variables);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        }   
        if(resource == "abis"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/abis/ANY/US/v0/${resource}/${id}`
            
            const { data, headers } = await httpClient.put(url, variables);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        }    
        if(resource == "streams"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/streams/ANY/US/v0/${resource}/${id}`
            
            const { data, headers } = await httpClient.put(url, variables);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        }                      
        if(resource == "app-configs"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/app-config-mgmt/ANY/US/v0/${resource}/${id}`
            
            const { data, headers } = await httpClient.put(url, variables);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        }
        else{
            const url = `${apiUrl}/${resource}/${id}`;

            const { data } = await httpClient.patch(url, variables);

            return {
                data,
            };
        }
    },

    updateMany: async ({ resource, ids, variables }) => {
        console.log("updateMany")
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.patch(
                    `${apiUrl}/${resource}/${id}`,
                    variables,
                );
                return data;
            }),
        );

        return { data: response };
    },

    createMany: async ({ resource, variables }) => {
        console.log("createMany")
        const url = `${apiUrl}/${resource}/bulk`;

        const { data } = await httpClient.post(url, { bulk: variables });

        return {
            data,
        };
    },

    
    getOne: async ({ resource, id, meta }) => {
        console.log("getOne")
        console.log(resource)
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
        const domain = process.env.REACT_APP_API_BASE_URL
        if(resource == "roles" || resource == "users" || resource == "internal-users" || resource == "internal-roles" || resource == "external-users" || resource == "external-roles"){
            if(resource == "internal-users" || resource == "external-users"){
                resource = "users"
            } 
            else if(resource == "internal-roles" || resource == "external-roles"){
                resource = "roles"
            }
            else{
                resource = resource
            }
            const url = `${apiUrl}/management/permissions/GET/US/v0/${resource}/${id}`
            const { data } = await httpClient.get(url);

            console.log(data)
            return {
                data: data,
                total: 1,
            };
        } 
        if(resource == "secrets" || resource == "ssm-parameters"){
            const encodedId = encodeURIComponent(id as any);
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/secrets/GET/US/v0/${resource}/${encodedId}`
            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        } 
        if(resource == "integrations"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/integrations/GET/US/v0/${resource}/${id}`
            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        } 
        if(resource == "triggers"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/triggers/GET/US/v0/${resource}/${id}`
            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        }   
        if(resource == "abis"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/abis/GET/US/v0/${resource}/${id}`
            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        }           
        if(resource == "streams"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/streams/GET/US/v0/${resource}/${id}`
            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        }              
        if(resource == "app-configs"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/tools/app-config-mgmt/GET/US/v0/${resource}/${id}`
            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        } 
        if(resource == "client-configs"){
            const encodedId = encodeURIComponent(id as any);
            const url = `https://${continent.toLowerCase()}.api.${domain}/management/client-config/GET/US/v0/${resource}/${encodedId}`
            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        } 
        if(resource == "work-items"){
            const encodedId = encodeURIComponent(id as any);
            console.log(encodedId)
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/tools/process-transactions-mgmt-debug-tool/GET/US/v0/${resource}/${encodedId}`
            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        } 
        if(resource == "transactions-activity-logs"){
            resource = "activity-logs"
            const encodedId = encodeURIComponent(id as any);
            console.log(encodedId)
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/tools/process-transactions-mgmt-debug-tool/GET/US/v0/${resource}/${encodedId}`
            
            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        } 
        if(resource == "wallet-stats"){
            let url
            if (meta?.by_activity_log == true){
                const encodedId = encodeURIComponent(id as any);
                url = `https://${continent.toLowerCase()}.api.${domain}/internal/tools/process-transactions-mgmt-debug-tool/GET/US/v0/${resource}-by-activity-log/${encodedId}`   
            } else{
                const environment = meta?.environment
                const region = meta?.region
                const org_id = meta?.org_id
                const tenant_id = meta?.tenant_id
                const user_id = encodeURIComponent(meta?.user_id)
                const address = meta?.address
                const chains = meta?.chains ? meta?.chains : []
                var chainsString = ''
                chains.forEach((chain: string) => {
                    console.log(chain)
                    chainsString = chainsString + "&chains=" + chain
                });
                
                url = `https://${continent.toLowerCase()}.api.${domain}/internal/tools/process-transactions-mgmt-debug-tool/GET/US/v0/${resource}?environment=${environment}&region=${region}&org_id=${org_id}&tenant_id=${tenant_id}&user_id=${user_id}&address=${address}${chainsString}` 
            }

            const { data, headers } = await httpClient.get(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                total: data.length,
                summary

            };
        } 
        else{
            const url = `${apiUrl}/${resource}/${id}`;

            const { data } = await httpClient.get(url)
            
            return {
                data,
            };
        }
    },

    deleteOne: async ({ resource, id }) => {
        console.log("deleteOne")
        console.log(resource)
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
        const domain = process.env.REACT_APP_API_BASE_URL
        if(resource == "integrations"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/integrations/ANY/US/v0/${resource}/${id}`
            
            const { data, headers } = await httpClient.delete(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                summary
            };
        } 
        if(resource == "triggers"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/triggers/ANY/US/v0/${resource}/${id}`
            
            const { data, headers } = await httpClient.delete(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                summary
            };
        }     
        if(resource == "abis"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/abis/ANY/US/v0/${resource}/${id}`
            
            const { data, headers } = await httpClient.delete(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                summary
            };
        }           
        if(resource == "streams"){
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/streams/ANY/US/v0/${resource}/${id}`
            
            const { data, headers } = await httpClient.delete(url);
            console.log(data)

            const summary = JSON.parse(headers["x-summary"])
            return {
                data: data,
                summary
            };
        }          
        else {
            const url = `${apiUrl}/${resource}/${id}`;

            const { data } = await httpClient.delete(url);

            return {
                data,
            };
        }
    },

    deleteMany: async ({ resource, ids }) => {
        console.log("deleteMany")
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.delete(
                    `${apiUrl}/${resource}/${id}`,
                );
                return data;
            }),
        );
        return { data: response };
    },

    getApiUrl: () => {
        return apiUrl;
    },

    custom: async ({
        url,
        method,
        meta,
        filters,
        sorters,
        payload,
        query,
        headers,
    }) => {
        let requestQueryBuilder = RequestQueryBuilder.create();

        requestQueryBuilder = handleFilter(requestQueryBuilder, filters);

        requestQueryBuilder = handleJoin(requestQueryBuilder, meta?.join);

        requestQueryBuilder = handleSort(requestQueryBuilder, sorters);

        let requestUrl = `${url}?${requestQueryBuilder.query()}`;

        // if (headers) {
        //     httpClient.defaults.headers = {
        //         ...httpClient.defaults.headers,
        //         ...headers,
        //     };
        // }

        let axiosResponse;
        switch (method) {
            case "put":
            case "post":
            case "patch":
                axiosResponse = await httpClient[method](url, payload);
                break;
            case "delete":
                axiosResponse = await httpClient.delete(url, {
                    data: payload,
                });
                break;
            default:
                axiosResponse = await httpClient.get(requestUrl);
                break;
        }

        const { data } = axiosResponse;

        return Promise.resolve({ data });
    },
});
