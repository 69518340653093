import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    EditButton,
    ShowButton,
    TextField,
    DeleteButton,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space, Typography, Checkbox, Input, Button, Divider, Alert, Tooltip, Select } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { isAddress } from 'viem'

import { IWalletStat, IMoralisWalletStat, IMoralisChainActivity  } from "interfaces";
import { useAuth0 } from '@auth0/auth0-react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
const { Title, Text } = Typography;


export const WalletStatsList: React.FC<IResourceComponentsProps> = () => {
    const CheckboxGroup = Checkbox.Group;
    const [searchAddress, setSearchAddress] = useState<string>("0x7803843fcb650760a864b1cb2ba5daa237f8e1ce");
    const [finalSearchAddress, setFinalSearchAddress] = useState<string>("0x7803843fcb650760a864b1cb2ba5daa237f8e1ce"); // 0x1f9090aaE28b8a3dCeaDf281B0F12828e676c326
    const [isInvalidSearchAddress, setIsInvalidSearchAddress] = useState<boolean>(false);

    const all_possible_chains = [
        {
            "label": "Sepolia",
            "value": "0xaa36a7"
        },
        {
            "label": "Ethereum",
            "value": "0x1"
        },
        {
            "label": "Goerli",
            "value": "0x5"
        },
        {
            "label": "Polygon",
            "value": "0x89"
        },
        {
            "label": "Mumbai",
            "value": "0x13881"
        },
        {
            "label": "Bsc",
            "value": "0x38"
        },
        {
            "label": "Bsc_testnet",
            "value": "0x61"
        },
        {
            "label": "Avalanche",
            "value": "0xa86a"
        },
        {
            "label": "Fantom",
            "value": "0xfa"
        },
        {
            "label": "Cronos",
            "value": "0x19"
        },
        {
            "label": "Palm",
            "value": "0x2a15c308d"
        },
        {
            "label": "Arbitrum",
            "value": "0xa4b1"
        },
        {
            "label": "Gnosis",
            "value": "0x64"
        },
        {
            "label": "Gnosis Chiado",
            "value": "0x27d8"
        },
        {
            "label": "Chiliz",
            "value": "0x15b38"
        },
        {
            "label": "Chiliz Spicy",
            "value": "0x15b32"
        },
        {
            "label": "Base",
            "value": "0x2105"
        },
        {
            "label": "Base Goerli" ,
            "value": "0x14a33"
        }
    ]

    const plainOptions = all_possible_chains
    const defaultCheckedList = ["0x1", "0xaa36a7"] // all_possible_chains.map(a => a.value);

    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList);
    const [searchCheckedList, setSearchCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList);

    const checkAll = plainOptions.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;
  
    const onChange = (list: CheckboxValueType[]) => {
      setCheckedList(list);
    };
  
    const onCheckAllChange = (e: CheckboxChangeEvent) => {
      setCheckedList(e.target.checked ? plainOptions.map(a => a.value) : []);
    };
    

    const {
        user,
    } = useAuth0();
      
    const { tableProps: tablePropsMoralisWalletStats, tableQueryResult: tableQueryResultMoralisWalletStats } = useTable<IMoralisWalletStat>({
        resource: "moralis-wallet-stats",
        meta: { 
            "address": finalSearchAddress,
            "chains": searchCheckedList,
        },
    });

    const [searchOrgId, setSearchOrgId] = useState<string>("org_c52a347d69344ef5ab9039a4d030a55b");
    const [finalSearchOrgId, setFinalSearchOrgId] = useState<string>("org_c52a347d69344ef5ab9039a4d030a55b"); 
    const [searchTenantId, setSearchTenantId] = useState<string>("ten_adf2003502144980b9afd1bca35e4252");
    const [finalSearchTenantId, setFinalSearchTenantId] = useState<string>("ten_adf2003502144980b9afd1bca35e4252"); 
    const [searchUserId, setSearchUserId] = useState<string>("google-oauth2|109517966719689351983");
    const [finalSearchUserId, setFinalSearchUserId] = useState<string>("google-oauth2|109517966719689351983"); 

    const [region, setRegion] = useState<string>("us-west-2");
    const [environment, setEnvironment] = useState<string>("sandbox");

    const { tableProps: tablePropsWalletStats, tableQueryResult: tableQueryResultWalletStats } = useTable<IWalletStat>({
        resource: "wallet-stats",
        meta: { 
            "environment": environment,
            "region": region,
            "org_id": finalSearchOrgId,
            "tenant_id": finalSearchTenantId,
            "user_id": finalSearchUserId,
            "address": finalSearchAddress,
            "chains": searchCheckedList
        },
    });

    const { Option } = Select;

    const { tableProps: tablePropsChainActivity, tableQueryResult: tableQueryResultChainActivity } = useTable<IMoralisChainActivity>({
        resource: "moralis-chain-activity",
        meta: { 
            "address": finalSearchAddress,
            "chains": searchCheckedList,
        },
    });

    
    const refreshTableResults = () => {     
        if(isAddress(searchAddress)){
            setSearchCheckedList(checkedList)   
            setFinalSearchAddress(searchAddress)
            setIsInvalidSearchAddress(false)
            setFinalSearchOrgId(searchOrgId)
            setFinalSearchTenantId(searchTenantId)
            setFinalSearchUserId(searchUserId)
            tableQueryResultMoralisWalletStats.refetch()
            tableQueryResultChainActivity.refetch()
          }
          else{
            setIsInvalidSearchAddress(true)
          }
    };



    return (

        <List >
            <Title level={5}>Search Address:</Title>
            <Input addonBefore={<SearchOutlined />} placeholder="Enter the search for address" value={searchAddress} onChange={(e) => setSearchAddress(e.target.value)}/>
            {
                isInvalidSearchAddress ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                        message="Not a valid address"
                        type="error"

                    />                    
                </Space> :
                ""
            }
            <Divider />
            <Title level={5}>Search chains for wallet stats:</Title>
            <Divider />
            <Space.Compact size="large">
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                Check all
            </Checkbox>
                <Text>
                {
                   <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
                }    
                </Text> 
            </Space.Compact>
            <Divider />
            <Space.Compact>
            <Title level={5}>Select the environment:</Title>
                <Select
                    defaultValue={environment}
                    style={{ width: 120, marginLeft:10, marginRight:10 }}
                    onChange={setEnvironment}
                    options={[
                        { value: 'sandbox', label: 'sandbox' },
                        { value: 'prd', label: 'prd' },
                    ]}
                />
            <Title level={5}>Select the region:</Title>
                <Select
                    defaultValue={region}
                    style={{ width: 120, marginLeft:10, marginRight:10 }}
                    onChange={setRegion}
                    options={[
                        { value: 'us-west-2', label: 'us-west-2' },
                        { value: 'us-east-1', label: 'us-east-1' },
                        { value: 'eu-central-1', label: 'eu-central-1' },
                        { value: 'eu-west-1', label: 'eu-west-1'},
                    ]}
                />

            </Space.Compact>
            <Divider></Divider>
            <Space.Compact>
                <Title level={5}>Search Org Id: </Title>
                <Input style={{width:500, marginLeft:10}} addonBefore={<SearchOutlined />} placeholder="Enter the search for org id" value={searchOrgId} onChange={(e) => setSearchOrgId(e.target.value)}/>
                <Title level={5} style={{marginLeft:10}}>Search Tenant Id: </Title>
                <Input style={{width:500, marginLeft:10}} addonBefore={<SearchOutlined />} placeholder="Enter the search for tenant id" value={searchTenantId} onChange={(e) => setSearchTenantId(e.target.value)}/>
                <Title level={5} style={{marginLeft:10}}>Search User Id: </Title>
                <Input style={{width:500, marginLeft:10}} addonBefore={<SearchOutlined />} placeholder="Enter the search for tenant id" value={searchUserId} onChange={(e) => setSearchTenantId(e.target.value)}/>
            </Space.Compact>
            <Divider></Divider>
            <Space.Compact style={{ width: '100%' }}>
            <Button type="primary" onClick={refreshTableResults} disabled={tableQueryResultMoralisWalletStats?.isFetching }>Submit</Button>
            </Space.Compact>
            <Divider />
            {
                tableQueryResultMoralisWalletStats?.data?.summary.summary.total_number_of_429_errors.length > 0 ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                        message="Encountered '429' (Throttling Limit) errors during the retrieval of the wallet stats"
                        type="warning"

                    />

                    <pre>{JSON.stringify(tableQueryResultMoralisWalletStats?.data?.summary.summary, null,2)}</pre> 
                    

                </Space> :
                ""
            } 
            {
                tableQueryResultMoralisWalletStats?.data?.summary.summary.time_needed_in_seconds ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                    message={"Time taken to load the results: " + tableQueryResultMoralisWalletStats?.data?.summary.summary.time_needed_in_seconds + " seconds"}
                    type="success"

                    />
                </Space> :
                ""
            }      
            <Divider />      
            <Title level={5}>Address has interacted atleast with 1 transaction on the following chains:</Title>
            <Divider />
            <Space.Compact size="large">
                <Text>
                {
                   <CheckboxGroup options={all_possible_chains.filter(item => checkedList.includes(item.value))} value={tableQueryResultMoralisWalletStats?.data?.data.filter(item => item.transactions != "0").map(a => a.chain_id)} onChange={(e) => console.log("")}/>
                }    
                </Text> 
            </Space.Compact>
            <Divider /> 
            <Title level={3}>Wallet stats from Moralis:</Title>
            <Divider />     
            <Table {...tablePropsMoralisWalletStats} dataSource={tableQueryResultMoralisWalletStats?.data?.data} loading={tableQueryResultMoralisWalletStats?.isLoading || tableQueryResultMoralisWalletStats?.isFetching } rowKey="id"
            pagination={{
                defaultPageSize:100,
                showSizeChanger:true,
            }}
            >
            <Table.Column<IMoralisWalletStat>
                    title="Id" 
                    dataIndex="id" 
                    render={(_: any, record: { id: any; }) => {
                        return (
                            <Tooltip title={record?.id}>
                            <TagField
                                value={`${
                                    record?.id.substring(0, 2)
                                }` }
                            />
                            </Tooltip>
                        );
                    }}
                />
            <Table.Column<IMoralisWalletStat>
                    sorter={ (a: any, b: any) => a.chain - b.chain}
                    title="Chain" 
                    dataIndex="chain" 
                    render={(_: any, record: { chain: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.chain
                                }` }
                            />
                        );
                    }}
                />
            
            <Table.Column<IMoralisWalletStat>
                    sorter={ (a: any, b: any) => a.nfts - b.nfts}
                    title="nfts" 
                    dataIndex="nfts" 
            />
            <Table.Column<IMoralisWalletStat>
                    sorter={ (a: any, b: any) => a.collections - b.collections}
                    title="collections" 
                    dataIndex="collections" 
            />
            <Table.Column<IMoralisWalletStat>
                    sorter={ (a: any, b: any) => a.transactions - b.transactions}
                    title="transactions" 
                    dataIndex="transactions" 
            />
            <Table.Column<IMoralisWalletStat>
                    sorter={ (a: any, b: any) => a.nft_transfers - b.nft_transfers}
                    title="nft_transfers" 
                    dataIndex="nft_transfers" 
            />
             <Table.Column<IMoralisWalletStat>
                    sorter={ (a: any, b: any) => a.token_transfers - b.token_transfers}
                    title="token_transfers" 
                    dataIndex="token_transfers" 
            />     
            </Table> 
            <Divider /> 
            <Title level={3}>Wallet stats from DB:</Title>
            <Divider />
            {
                tableQueryResultWalletStats?.data?.summary.summary.time_needed_in_seconds ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                    message={"Time taken to load the results: " + tableQueryResultWalletStats?.data?.summary.summary.time_needed_in_seconds + " seconds"}
                    type="success"

                    />
                </Space> :
                ""
            }      
            <Divider />  

            <Table {...tablePropsWalletStats} dataSource={tableQueryResultWalletStats?.data?.data} loading={tableQueryResultWalletStats?.isLoading || tableQueryResultWalletStats?.isFetching } rowKey="id"
            pagination={{
                defaultPageSize:100,
                showSizeChanger:true,
            }}
            >
            <Table.Column<IWalletStat>
                    title="Id" 
                    dataIndex="id" 
                    render={(_: any, record: { id: any; }) => {
                        return (
                            <Tooltip title={record?.id}>
                            <TagField
                                value={`${
                                    record?.id.substring(0, 2)
                                }` }
                            />
                            </Tooltip>
                        );
                    }}
                />
            <Table.Column<IWalletStat>
                    sorter={ (a: any, b: any) => a.chain - b.chain}
                    title="Chain" 
                    dataIndex="chain" 
                    render={(_: any, record: { chain: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.chain
                                }` }
                            />
                        );
                    }}
                />
            <Table.Column<IWalletStat>
                    sorter={ (a: any, b: any) => a.number_of_dispatched_transactions - b.number_of_dispatched_transactions}
                    title="Dispatched transactions" 
                    dataIndex="number_of_dispatched_transactions" 
                    render={(_: any, record: { number_of_dispatched_transactions: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.number_of_dispatched_transactions
                                }` }
                            />
                        );
                    }}
                />
            <Table.Column<IWalletStat>
                    sorter={ (a: any, b: any) => a.number_of_transactions - b.number_of_transactions}
                    title="Transactions in DB" 
                    dataIndex="number_of_transactions" 
                    render={(_: any, record: { number_of_transactions: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.number_of_transactions
                                }` }
                            />
                        );
                    }}
                />
            <Table.Column<IWalletStat>
                    sorter={ (a: any, b: any) => a.opensearch_number_of_transactions - b.opensearch_number_of_transactions}
                    title="Transactions in Opensearch" 
                    dataIndex="opensearch_number_of_transactions" 
                    render={(_: any, record: { opensearch_number_of_transactions: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.opensearch_number_of_transactions
                                }` }
                            />
                        );
                    }}
                />
            </Table>                
            <Divider />
            <Title level={3}>Chain Activity by wallet via Moralis:</Title>
            <Divider />
            {
                tableQueryResultChainActivity?.data?.summary.summary.total_number_of_429_errors.length > 0 ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                        message="Encountered '429' (Throttling Limit) errors during the retrieval of the wallet stats"
                        type="warning"

                    />

                    <pre>{JSON.stringify(tableQueryResultChainActivity?.data?.summary.summary, null,2)}</pre> 
                    

                </Space> :
                ""
            } 
            {
                tableQueryResultChainActivity?.data?.summary.summary.time_needed_in_seconds ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                    message={"Time taken to load the results: " + tableQueryResultChainActivity?.data?.summary.summary.time_needed_in_seconds + " seconds"}
                    type="success"

                    />
                </Space> :
                ""
            }      
            <Divider />  

            <Table {...tablePropsChainActivity} dataSource={tableQueryResultChainActivity?.data?.data} loading={tableQueryResultChainActivity?.isLoading || tableQueryResultChainActivity?.isFetching } rowKey="id"
            pagination={{
                defaultPageSize:100,
                showSizeChanger:true,
            }}
            >
                <Table.Column<IMoralisChainActivity>
                        title="Id" 
                        dataIndex="id" 
                        render={(_: any, record: { id: any; }) => {
                            return (
                                <Tooltip title={record?.id}>
                                    <TagField
                                        value={`${
                                            record?.id.substring(0, 2)
                                        }` }
                                    />
                                </Tooltip>
                            );
                        }}
                    />
                <Table.Column<IMoralisChainActivity>
                        sorter={ (a: any, b: any) => a.chain - b.chain}
                        title="Chain" 
                        dataIndex="chain" 
                        render={(_: any, record: { chain: any; }) => {
                            return (
                                <TagField
                                    value={`${
                                        record?.chain
                                    }` }
                                />
                            );
                        }}
                    />
                <Table.Column<IMoralisChainActivity>
                    title="First Transactions" 
                    dataIndex="first_transaction" 
                    render={(_: any, record: { first_transaction: any; }) => {
                        return (
                            <pre>
                                    {JSON.stringify(record?.first_transaction, null, 2)}
                            </pre>
                        );
                    }}
                />
                <Table.Column<IMoralisChainActivity>
                    title="Last Transactions" 
                    dataIndex="last_transaction" 
                    render={(_: any, record: { last_transaction: any; }) => {
                        return (
                            <pre>
                                    {JSON.stringify(record?.last_transaction, null, 2)}
                            </pre>
                        );
                    }}
                />
                <Table.Column<IMoralisChainActivity>
                    title="Error" 
                    dataIndex="error" 
                />
            </Table>
        </List>
    );
};

