import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { Alert, Avatar, Button, Divider, List, Select, Skeleton, Space, Tag, Tooltip, Typography } from 'antd';
import { DeleteButton, EditButton, Show, TagField } from '@refinedev/antd';
import { useAuth0 } from '@auth0/auth0-react';
import { IIntegration } from 'interfaces';

const { Title, Text } = Typography;

import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';
import InfiniteScroll from 'react-infinite-scroll-component';

export const IntegrationShow: React.FC<IResourceComponentsProps> = () => {

    const {
        user,
      } = useAuth0();

      const { queryResult } = useShow<IIntegration>();
      const { data, isLoading } = queryResult;
      const record = data?.data;
      const { Option } = Select;

    let colors = {
        "us-west-2": "green",
        "us-east-1": "lightgreen",
        "eu-central-1": "blue",
        "eu-west-1": "lightblue"
    }

    const loadMoreData = () => {

    }

    return (
        <Show isLoading={queryResult?.isLoading || queryResult?.isFetching }>
            <Title level={5}>Integration Environment</Title>
            <TagField value={record?.get_integration_result.environment}></TagField>
            <Title level={5}>Integration Region</Title>
            <TagField color={colors[record?.get_integration_result.region as keyof typeof colors ]} value={record?.get_integration_result.region}></TagField>
            <Title level={5}>Integration Org Id</Title>
            <Text>{record?.get_integration_result.org_id}</Text>
            <Title level={5}>Integration Tenant Id</Title>
            <Text>{record?.get_integration_result.tenant_id}</Text>
            <Title level={5}>Integration User Id</Title>
            <Text>{record?.get_integration_result.created_by}</Text>
            <Title level={5}>Integration Id</Title>
            <Text>{record?.get_integration_result.id}</Text>
            <Title level={5}>Integration Name</Title>
            <Text>{record?.get_integration_result.name}</Text>
            <Title level={5}>Integration Status</Title>
            <Text>{record?.get_integration_result.status}</Text>
            <Title level={5}>Integration Lambda Arn</Title>
            <Text>{record?.get_integration_result.lambda_arn}</Text>            
            <Title level={5}>Integration Stages</Title>
            <Text></Text>
            <Select
                mode="multiple"
                style={{ width: '100%' }}
                disabled={true}
                value={record?.get_integration_result.integration_stages}
            />
            <Divider></Divider>
            <Title level={5}>Triggered on</Title>
            <Text>
            <List
                itemLayout="horizontal"
                dataSource={record?.get_integration_result?.trigger_on}
                renderItem={(item, index) => (
                <List.Item>
                    <List.Item.Meta
                        avatar={<Avatar src={`https://cdn.${process.env.REACT_APP_API_BASE_URL}/public/GET/cdn/blockchain/logos/svg/${record?.get_integration_result?.trigger_on[index]?.chain_id}.svg`} />}

                        // avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                        title={record?.get_integration_result?.trigger_on[index]?.chain}
                        description={record?.get_integration_result?.trigger_on[index]?.address}
                    />
                </List.Item>
                )}
            />
            </Text>
            {
            record?.get_integration_address_labels_result ?
            <>
                <Title level={5}>Allowed to add the following address labels:</Title>
                <InfiniteScroll
                dataLength={10}
                next={loadMoreData}
                hasMore={false}
                loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                scrollableTarget="scrollableDiv"
            >
                <List
                dataSource={record?.get_integration_address_labels_result ? record?.get_integration_address_labels_result : [] }
                renderItem={(item:any, index) => (
                    <List.Item>
                    <List.Item.Meta
                        avatar={<Avatar src={`https://cdn.${process.env.REACT_APP_API_BASE_URL}/public/GET/cdn/blockchain/logos/svg/${item.chains[0].chain_id}.svg`} />}
                        title={<a href="https://ant.design">{item?.address_label_id}</a>}
                        description={<Tooltip title={item.address}>{item.address_name}</Tooltip>}
                    />
                    <Space>
                            <Tag>{item.id}</Tag>

                            {/* <Tooltip title="from">
                            <Button onClick={(e) => showInternalTransaction(item)}
                                size="small"
                            >{item.from}</Button>
                            </Tooltip>
                            <Text>{"->"}</Text>

                            <Tooltip title="to">
                            <Button onClick={(e) => showInternalTransaction(item)}
                                size="small"
                            >{item.to}</Button>
                            </Tooltip> */}

                            <EditButton
                                size="small"
                                hideText={true}
                                // recordItemId={record?.["user_id-block_timestamp-chain_id-hash-type"]}
                                // resource="transactions-share-with"
                                // meta={{"remove_user_email": item.email}}
                            />                            
                            <DeleteButton
                                size="small"
                                hideText={true}
                                // recordItemId={record?.["user_id-block_timestamp-chain_id-hash-type"]}
                                // resource="transactions-share-with"
                                // meta={{"remove_user_email": item.email}}
                            />
                        </Space>
                    </List.Item>
                )}
                />
            </InfiniteScroll> 
        </>:
        ""
         }            
            <Title level={5}>Integration Config (from codeCommit)</Title>
            {
                record?.get_integration_versions_result?.length > 0 ? 
                <AceEditor  
                    style={{width: 1000}}
                    mode="json"
                    theme="monokai"
                    wrapEnabled={true}
                    defaultValue={ record?.get_integration_versions_result[0]?.config_file_contents ? JSON.stringify(JSON.parse(record?.get_integration_versions_result[0]?.config_file_contents), null, 2): '{}' } 
                    readOnly={true}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{ $blockScrolling: true }}
                />
                :
                "No config"
            }
            <Divider></Divider>
            <Title level={5}>Integration Source (from codeCommit)</Title>
            {
                record?.get_integration_versions_result?.length > 0 ? 
                <AceEditor  
                    style={{width: 1000}}
                    mode="json"
                    theme="monokai"
                    wrapEnabled={true}
                    defaultValue={ record?.get_integration_versions_result[0]?.source_file_contents ? record?.get_integration_versions_result[0]?.source_file_contents: '{}' } 
                    readOnly={true}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{ $blockScrolling: true }}
                />
                :
                "No source"
            }
            <Divider></Divider>    
        </Show>
    );
};

